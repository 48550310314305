import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { StaffInput } from '../../types';
import { PageProps } from '../types';
import { useModal } from 'hooks/useModal';
import { sanitizePhoneNumber, validatePhoneNumber } from '../../../utils/misc';
import { useToast } from 'hooks/useToast';
import { API_ERRORS, ERRORS } from 'constants/errors';
import AccountSetupArrayShimmer from 'ui/organism/accountSetupArrayShimmer/accountSetupArrayShimmer';
import Heading from 'ui/atoms/heading/Heading';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { SvgEdit, SvgTrash, SvgPlus } from 'ui';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import Button from 'ui/atoms/button/Button';
import ImportFileLink from '../../ImportFileLink';
import { Modal } from 'ui/templates/modal/Modal';
import { REGEX_PATTERNS } from 'constants/pattern';
import Input from 'ui/molecules/input/Input';
import SelectInput from 'ui/molecules/input/SelectInput';
import { ACCOUNT_SETUP_ERRORS, PERMISSION_LEVELS, STAFF_ROLES, STAFF_SPECIALTIES, YES_OR_NO_SETTINGS } from 'constants/information';
import FormPhone from 'ui/molecules/input/FormPhone';
import { getHelperTextForReactHookFormErrors } from '../../../utils/form';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import ImportStaff from '../../ImportStaff';
import { useSalonCache } from 'hooks/useSalonCache';
import { CreateSalonStaffDocument, CreateStaffHourDocument, DeleteSalonStaffDocument, SalonHour, SalonHoursDocument, SalonStaff, StaffManagementDocument, UpdateSalonStaffDetailsDocument, UpdateSalonStaffDocument, User } from 'core/generated';
import { useSalonHours } from 'api/useSalon';
import { useCreateSalonStaff, useCreateStaffHour, useDeleteStaff, useGetStaffList, useUpdateStaff } from 'api/useStaff';
import { sortOptions } from 'components/utils/utils';

const StaffComponent = ({ onNextPage, onPrevPage }: PageProps) => {
  const user = JSON.parse(localStorage.getItem('userData')) as User
  const business = user.business
  const [selectedStaff, setSelectedStaff] = useState<SalonStaff | null>(null);
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<StaffInput>({
    defaultValues: {
      canServeCustomers: true,
      canLogin: false
    }
  });
  const [countryCode, setCountryCode] = useState(business?.countryCode);
  const [callingCode, setCallingCode] = useState(business?.callingCode);
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const { addToast, toast } = useToast();
  const [staffLoading, setStaffLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)

  const {
    data,
    loading
  } = useSalonHours(salonId)
  const salonHours = useMemo(() => {
    return data?.salonHours || [];
  }, [data])

  const {
    data: staffListData,
    refetch,
  } = useGetStaffList({
    salonId,
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  const {
    createStaffHour
  } = useCreateStaffHour()
  const updateStaffHours = async (salonStaffId: string) => {
    const hours = Array?.isArray(salonHours) && salonHours.map((hour) => {
      return {
        day: hour.day,
        closeTime: hour.closeTime,
        openTime: hour.openTime,
      }
    })
    createStaffHour({
      variables: { input: { salonStaffId, staffHours: hours } }
    }).then(({ data }) => {
      const { createStaffHour } = data
    }).catch((err) => {
      addToast({ message: err.message, variant: 'error' })
    })
  }

  // const getSalonHours = useCallback(() => {
  //   return salonHoursData?.data || {};
  // }, [salonHoursData]);

  // const salonHours: IOpeningHours = useMemo(() => {
  //   return getSalonHours();
  // }, [getSalonHours]);
  // const showInitialLoadingShimmer = staffLoading && !staff;
  // const salonHours = [];
  const showInitialLoadingShimmer = staffLoading && !staff;

  const formatDate = (): string => {
    const date = new Date();
    // Format the date as "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getSalonFormattedHours = () => {
    return Array?.isArray(salonHours) && salonHours
      .filter((hour) => hour?.closeTime && hour?.openTime) // Filter out invalid hours
      .map((hour) => { // Map the valid hours to the desired format
        return {
          day: hour?.day,
          closeTime: hour?.closeTime,
          openTime: hour?.openTime,
          salonId
        };
      });
  };

  const {
    createSalonStaff,
    loading: createStaffLoading
  } = useCreateSalonStaff()

  const {
    updateSalonStaff,
    loading: updateStaffLoading
  } = useUpdateStaff()

  const {
    deleteStaff
  } = useDeleteStaff()
  const addStaffSubmit = async (input: StaffInput) => {
    const inputData = {
      employmentDetails: {
        canLogin: input?.canLogin,
        startDate: formatDate(),
        specialty: input.specialty,
        salonId: getSalonFieldValue('id'),
        present: true,
        ...(input.canLogin && { level: Number(input.level) }),
        canServeCustomers: input.canServeCustomers,
      },
      user: { firstName: input?.firstName, lastName: input?.lastName, email: input?.email || "", countryCode, phone: input?.phone, dateOfBirth: null, profileUrl: '', callingCode },
      salaryInformation: null,
      reminder: null,
      leave: {
        daysEntitled: "0"
      },
      salonId: getSalonFieldValue('id'),
      salonIds: [salonId],
      workingSchedule: {
        workSchedule: getSalonFormattedHours(),
        workScheduleType: 'business'
      },
      ...(staff?.length === 0 ? { onboardingStep: 'created_salon_staff' } : {})
    };

    try {
      setIsLoading(true)
      if (!selectedStaff) {
        createSalonStaff({
          variables: {
            input: { ...inputData, salonId: getSalonFieldValue('id') }
          }
        }).then((res) => {
          setIsLoading(false);
          const {
            data: {
              createSalonStaff: uss
            }
          } = res
          // console.log("RES: ", res)
          if (uss.status === 200) {
            addToast({
              message: 'Staff created successfully',
              variant: 'success'
            })
            closeModal();
            refetch();
            resetFormInput();
          } else {
            addToast({ variant: 'error', message: uss.errors[0].message })
          }
        })
          .catch((err) => {
            addToast({ variant: 'error', message: err.message })
          })
      } else {
        updateSalonStaff({
          variables: { input: { ...inputData, id: selectedStaff?.id, salonId } },
        }).then(({ data }) => {
          setIsLoading(false);

          if (data?.updateSalonStaffDetails?.status === 200) {
            closeModal();
            refetch();
            resetFormInput();
            setSelectedStaff(null)
          } else {
            const message = data?.updateSalonStaffDetails?.errors[0]?.message || data?.updateSalonStaffDetails?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
            addToast({
              variant: 'error',
              message,
            })
          }
        })
      }
    } catch (error) {
      setIsLoading(false)
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.BUSINESS_STAFF_CREATE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }
  }

  const deleteStaffAsync = (staffId: string) => async () => {
    try {
      deleteStaff({
        variables: { input: { salonStaffId: staffId } },
      }).then(({ data }) => {
        setDeleteIsLoading(false);
        if (data?.deleteSalonStaff?.status === 200) {
          refetch();
          addToast({
            variant: 'success',
            message: "Staff deleted successfully",
          })
        } else if (data?.deleteSalonStaff?.errors?.length) {
          const message = data?.deleteSalonStaff?.errors[0]?.message || API_ERRORS.BUSINESS_STAFF_DELETE_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.BUSINESS_STAFF_DELETE_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }
  }

  const editStaff = (staffId: string) => () => {
    const getStaff = staff?.find((eachStaff) => eachStaff?.id === staffId);
    if (!getStaff) return;
    setSelectedStaff(getStaff);
    openModal();
  }

  useEffect(() => {
    if (selectedStaff) {
      setValue('firstName', selectedStaff?.user?.firstName);
      setValue('lastName', selectedStaff?.user?.lastName);
      setValue('email', selectedStaff?.user?.email || "");
      setValue('phone', selectedStaff?.user?.phone);
      setValue('canLogin', selectedStaff?.canLogin);
      setValue('specialty', selectedStaff?.specialty?.toLowerCase() || '');
      setValue('canServeCustomers', selectedStaff?.canServeCustomers || false);
      setValue('level', selectedStaff?.level?.toString() || '');
      setCountryCode(selectedStaff?.user?.countryCode);
      setCallingCode(selectedStaff?.user?.callingCode);
    }
  }, [selectedStaff, setValue]);

  const getStaffView = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }

    if (Array.isArray(staff) && staff.length) {
      return (
        <>
          {staff.map((eachStaff) => (
            <div className='flex items-center justify-between p-2 border-b border-grey-20'>
              <div className='flex flex-col space-y-1'>
                <Heading variant='h2' size='h10' weight='normal' className='text-black'>{eachStaff?.user?.firstName} {eachStaff?.user?.lastName}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>Phone: {eachStaff?.user?.phone} | Email: {eachStaff?.user?.email}</Paragraph>
              </div>
              <div className='flex space-x-6'>
                <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editStaff(eachStaff?.id)} />
                <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deleteStaffAsync(eachStaff?.id)} />
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You have no staff yet.</Paragraph>
      </div>
    )
  }

  const closeStaffModal = () => {
    resetFormInput();
    setSelectedStaff(null);
    closeModal();
  }

  const resetFormInput = () => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('email', '');
    setValue('phone', '');
    setValue('specialty', '');
    setValue('level', '')
    setCountryCode('');
    setCallingCode('');
  }

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('phone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('phone');
    }
  }

  return (
    <>
      <AccountSetupTemplate
        title='Staff'
        subtitle='Add your Staff'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <Button
            variant='primary'
            className=''
            size='md'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Staff
          </Button>
        </div>
        <div className='w-full flex flex-col space-y-4 max-h-[400px] overflow-y-scroll'>
          {getStaffView()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={false}
            onClick={onNextPage}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </AccountSetupTemplate >
      <Modal
        title="Add Staff"
        subTitle='Enter staff information below'
        show={isVisible}
        closeModal={
          selectedStaff ? closeStaffModal : closeModal
        }
      >
        <form onSubmit={handleSubmit(addStaffSubmit)} className="w-full mt-6 space-y-6" autoComplete='off'>
          <div className='w-full flex gap-x-4'>
            <Input name="firstName" label="First name" id="first-name" type="text" placeholder='Enter first name here' control={control} rules={{
              required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED,
              pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
            }} error={errors.firstName} />
            <Input name="lastName" label="Last name" id="last-name" type="text" placeholder='Enter last name here' control={control} rules={{
              required: ACCOUNT_SETUP_ERRORS.LAST_NAME_REQUIRED,
              pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
            }} error={errors.lastName} />
          </div>
          <SelectInput name="specialty" id="specialty" label="Select Specialty" control={control} rules={{
            required: ERRORS.STAFF_SPECIALTY_REQUIRED,
          }} error={errors.specialty} options={[...sortOptions(STAFF_SPECIALTIES)]} placeholder="Select a specialty" />
          <FormPhone
            country={{
              disabled: false,
              onSelect: (code, country: string) => {
                setCallingCode(code);
                setCountryCode(country);
              },
              value: countryCode,
            }}
            phone={{
              name: "phone",
              type: "text",
              placeholder: "9151930463",
              rules: {
                required: ERRORS.PHONE_REQUIRED,
                pattern: REGEX_PATTERNS.NUMBER,
                onChange: (e) => {
                  _sanitizePhoneNumber(e.target.value);
                },
                disabled: false,
              },
              register,
              id: "phone-number",
              label: "Staff Phone Number",
            }}
            helperText={getHelperTextForReactHookFormErrors(
              errors?.phone?.message as string
            )}
          />
          <Input name="email" label="Email" id="email" type="email" placeholder='email@acme.com' control={control} rules={{
            required: watch('canLogin') ? ACCOUNT_SETUP_ERRORS.EMAIL_REQUIRED : false,
            pattern: REGEX_PATTERNS.EMAIL,
          }} error={errors.email} />
          <Controller
            control={control}
            name="canServeCustomers"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="w-full flex flex-col space-y-4">
                  <label className="text-b6 xl:text-b4 text-black dark:text-grey-500 font-normal w-full block subpixel-antialiased">
                    Is this a customer facing staff? (do they provide services to customers)
                  </label>
                  <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                    {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                      ? YES_OR_NO_SETTINGS
                        .map((canServeCustomers: {
                          label: string;
                          value: boolean;
                        }) => {
                          return (
                            <div
                              className="flex"
                              onClick={() => onChange(canServeCustomers?.value)}
                              key={canServeCustomers?.label}
                            >
                              <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                <Checkbox
                                  isChecked={value === canServeCustomers?.value}
                                />
                                <span className="text-grey-900 whitespace-nowrap">
                                  {canServeCustomers?.label}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            }}
          />
          <Controller
            control={control}
            name="canLogin"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="w-full flex space-y-4 my-2">
                  <div
                    className="flex"
                    onClick={() => onChange(!value)}
                  >
                    <div className="flex justify-center items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                      <Checkbox
                        isChecked={value}
                      />
                      <span className="text-grey-900 whitespace-nowrap">
                        Do you want to invite this staff to Splice
                      </span>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          {watch('canLogin') && (
            <SelectInput name="level" id="level" label="Select permission level" control={control} rules={{
              required: watch('canLogin') && ERRORS.PERMISSION_LEVEL_REQUIRED,
            }} error={errors.role} options={[
              ...PERMISSION_LEVELS
            ]} placeholder="Select Permission level" />
          )}
          <div className='space-y-6'>
            <Button
              variant='primary'
              className=''
              disabled={createStaffLoading || updateStaffLoading}
              loading={createStaffLoading || updateStaffLoading}
              size='lg'
              rounded='lg'
            >
              Save
            </Button>
            <Button
              variant='text'
              className='mx-auto text-red-500'
              size='none'
              onClick={
                selectedStaff ? closeStaffModal : closeModal
              }
              disabled={deleteIsLoading}
              loading={deleteIsLoading}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default StaffComponent