import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import { useUserCache } from 'hooks/useUserCache';
import { useImageUploader } from 'hooks/useUploader';
import { API_ERRORS } from 'constants/errors';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { IMAGE_UPLOAD_PATTERN } from 'constants/pattern';
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText';
import FormTextarea from 'ui/molecules/input/FormTextarea';
import { SPLICE_BOOKING_LINK, YES_OR_NO_SETTINGS } from 'constants/information';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import Button from 'ui/atoms/button/Button';
import { FormLabel } from 'ui/atoms/formLabel';
import { UpdateBusinessDocument } from 'core/generated';
import { useUpdateBusiness } from 'api/useAccount';
import { HeaderImageInput } from 'components/uicomponents/accountSetupComponents/types';
import { Anchor, BookingPageSetup, Modal, Paragraph, SvgExternal, SvgGroupLink } from 'ui';
import { getImageUploadContent } from 'components/utils/upload';
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form';
import { copyTextToClipboard, removeSpaceAndSpecialCharacters } from 'components/utils/misc';
import { COLORS } from 'constants/colors';

const SetupBookingLink = ({ isVisible, closeModal }: {
  isVisible: boolean,
  closeModal: () => void,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<HeaderImageInput>({
    defaultValues: {
      acceptsOnlineBookings: true,
    },
  });
  const { getBusinessData, setBusinessData } = useUserCache();
  const _business = getBusinessData();
  const [bookingPageLinkStatus, setBookingPageLinkStatus] = useState(false);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');

  const {
    loading: isLoading,
    updateBusiness
  } = useUpdateBusiness()

  const { imageUrl, errorMessage, handleImageUpload, uploadIsLoading } = useImageUploader(
    {
      width: 3400,
      height: 3400,
    }
  );
  const { addToast, toast } = useToast();

  useEffect(() => {
    if (errorMessage) {
      addToast({
        message: errorMessage,
        variant: "error",
      });
    }
  }, [errorMessage, uploadIsLoading]);

  useEffect(() => {
    if (imageUrl) {
      setValue("headerImageUrl", imageUrl);
      clearErrors("headerImageUrl");
    }
  }, [imageUrl, setValue]);

  const onSubmitData = async (data: HeaderImageInput) => {
    try {
      updateBusiness({
        variables: {
          input: {
            headerImageUrl: imageUrl,
            headerImageUrls: [imageUrl],
            about: _business.about,
            cancellationPolicy: _business?.cancellationPolicy,
            acceptsOnlineBookings: data?.acceptsOnlineBookings || false,
            bookingUrlIdentifier:
              removeSpaceAndSpecialCharacters(debouncedSearchQuery),
            pointValue: 0,
            onboardingStep: 'booking_link_created'
          }
        }
      }).then(({ data }) => {
        if (data?.updateBusiness) {
          setBusinessData(data?.updateBusiness?.business);
          closeModal()
        }

        if (data?.updateBusiness?.errors) {
          const message = data?.updateBusiness?.errors[0]?.message || API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED;
          addToast({
            variant: "error",
            message,
          });
        }
      });
    } catch (updateBusinessHeaderImageError) {
      if (axios.isAxiosError(updateBusinessHeaderImageError)) {
        addToast({
          message: updateBusinessHeaderImageError?.response?.data?.message || API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED,
          variant: 'error',
        })
      }
    }
  }


  const checkBusinessIdentifier = async () => {
    try {
      updateBusiness({
        variables: {
          input: {
            headerImageUrl: _business.headerImageUrl,
            about: _business.about,
            cancellationPolicy: _business?.cancellationPolicy,
            acceptsOnlineBookings:
              _business?.acceptsOnlineBookings || false,
            pointValue: 0,
            bookingUrlIdentifier:
              removeSpaceAndSpecialCharacters(debouncedSearchQuery),
            logoUrl: _business?.logoUrl,
            onboardingStep: 'booking_link_created'
          },
        },
      }).then(({ data }) => {
        if (data?.updateBusiness) {
          setBusinessData(data?.updateBusiness?.business);
          setBookingPageLinkStatus(true);
        }

        if (data?.updateBusiness?.errors) {
          const message =
            data?.updateBusiness?.errors[0]?.message ||
            API_ERRORS.BUSINESS_HEADER_IMAGE_UPLOAD_FAILED;
          addToast({
            variant: 'error',
            message,
          });
        }
      });
    } catch (errorBusinessBookingUrl) {
      if (axios.isAxiosError(errorBusinessBookingUrl)) {
        const message =
          errorBusinessBookingUrl?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_LINK_FAILED;
        addToast({
          variant: 'error',
          message,
        });
      }
    }
  };

  useEffect(() => {
    if (
      debouncedSearchQuery &&
      debouncedSearchQuery !== _business?.bookingUrlIdentifier
    ) {
      checkBusinessIdentifier();
    }
  }, [debouncedSearchQuery]);



  const copyBookingLink = () => {
    copyTextToClipboard(`${SPLICE_BOOKING_LINK}${debouncedSearchQuery}`);
    addToast({
      variant: 'success',
      message: 'Booking link copied to clipboard',
    });
  };

  useEffect(() => {
    if (_business) {
      setValue('about', _business?.about)
      setValue('headerImageUrl', _business?.headerImageUrl)
      setValue('cancellationPolicy', _business?.cancellationPolicy || "")
      setValue('acceptsOnlineBookings', _business?.acceptsOnlineBookings)
      setDebouncedSearchQuery(_business?.bookingUrlIdentifier || '')
    }
  }, [_business])
  
  return (
    <Modal title="Set up your appointment bookings page" show={isVisible} closeModal={closeModal} subTitle='This is the page where customers make appointments to book your services'>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <div className="w-full flex flex-col gap-y-4 items-center">
          <div className="flex justify-center items-center gap-x-1">
            <Paragraph size="b4" weight="normal" color={COLORS.GREY[400]}>
              {SPLICE_BOOKING_LINK}
            </Paragraph>
            <BookingPageSetup
              defaultSearchTerm={_business?.bookingUrlIdentifier || ''}
              setDebouncedSearchQuery={setDebouncedSearchQuery}
              successIcon={bookingPageLinkStatus}
            />
            {bookingPageLinkStatus && (
              <SvgGroupLink
                width="60px"
                height="60px"
                className="cursor-pointer"
                onClick={copyBookingLink}
              />
            )}
          </div>

          {bookingPageLinkStatus && (
            <>
              <Anchor
                to={`${SPLICE_BOOKING_LINK}${debouncedSearchQuery}`}
                target="_blank"
              >
                <div className="flex justify-center items-center space-x-2">
                  <Paragraph size="b5">Preview </Paragraph>
                  <SvgExternal width="2em" height="2em" />
                </div>
              </Anchor>
            </>
          )}
        </div>
        <Controller
          control={control}
          name="acceptsOnlineBookings"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex flex-col space-y-4">
                <FormLabel htmlFor='acceptsOnlineBookings'>Do you wish to make this business's online bookings public?</FormLabel>
                <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                  {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS
                      .map((acceptsOnlineBookings: {
                        label: string;
                        value: boolean;
                      }) => {
                        return (
                          <div
                            className="flex"
                            onClick={() => onChange(acceptsOnlineBookings?.value)}
                            key={acceptsOnlineBookings?.label}
                          >
                            <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                              <Checkbox
                                isChecked={value === acceptsOnlineBookings?.value}
                              />
                              <span className="text-grey-900 whitespace-nowrap">
                                {acceptsOnlineBookings?.label}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }}
        />
        <div className='w-full flex space-x-2'>
          <Button
            variant='primary'
            className=''
            disabled={isLoading}
            loading={isLoading}
            size='lg'
            rounded='lg'
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default SetupBookingLink