import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgLadyLoyalty = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 251 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M125.5 240.95c60.315 0 109.21-28.231 109.21-63.055S185.815 114.84 125.5 114.84c-60.315 0-109.21 28.231-109.21 63.055S65.185 240.95 125.5 240.95Z"
      fill="#F5F5F5"
    />
    <path
      d="m55.445 193.374-26.61-15.36 26.61-15.365 26.61 15.365-26.61 15.36Z"
      fill="#E0E0E0"
    />
    <path
      d="M78.725 197.129 58.96 185.724l19.765-11.41 19.76 11.41-19.76 11.405ZM122.78 227.27c16.027 0 29.02-7.502 29.02-16.755 0-9.254-12.993-16.755-29.02-16.755s-29.02 7.501-29.02 16.755c0 9.253 12.993 16.755 29.02 16.755ZM203.8 200.605 91.06 135.71l15.135-8.735L218.93 191.87l-15.13 8.735Z"
      fill="#E0E0E0"
    />
    <path
      d="m203.065 182.775 6.78 3.94s-3.42-10.92-1.5-21.905c.715-4.145 2.22-6.205 2.755-7.31.535-1.105 4.62-7.955 1-11.115s-12.04 3.125-11.66 20.04a65.802 65.802 0 0 0 2.625 16.35Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m203.065 182.775 6.78 3.94s-3.42-10.92-1.5-21.905c.715-4.145 2.22-6.205 2.755-7.31.535-1.105 4.62-7.955 1-11.115s-12.04 3.125-11.66 20.04a65.802 65.802 0 0 0 2.625 16.35Z"
      fill="#000"
    />
    <path
      d="M206.215 184.855a.252.252 0 0 1-.141-.046.251.251 0 0 1-.089-.119c-.025-.07-2.585-7.175-2.45-18.58.135-11.405 6.155-17.565 6.215-17.61a.245.245 0 0 1 .347.003.244.244 0 0 1-.002.347c-.06.055-5.935 6.035-6.065 17.28-.13 11.245 2.395 18.34 2.42 18.405a.241.241 0 0 1 .014.096.234.234 0 0 1-.081.17.226.226 0 0 1-.083.049.154.154 0 0 1-.085.005Z"
      fill="#fff"
    />
    <path
      d="m205 183.795 11.2 6.59s5.125-.76 4.735-5.585c-.39-4.825-2.47-5.965.125-8.085 2.595-2.12 3.265-2.26 3.14-4.34s-1.525-3-2.18-3.81c-.655-.81-2.5-2.55-1.205-5.055s3.04-4.93.445-6.08c-1.55-.685-4.155.175-5.885 2-1.73 1.825-3.15 3.15-4.79 3.575-1.64.425-5.05.46-4.935 3.28.115 2.82.85 3.795.165 5.05-1.315 2.365-6.815 3.635-.815 12.46Z"
      fill="#16796F"
    />
    <path
      d="M209.535 186.725a.252.252 0 0 1-.245-.225c0-.17-1.23-16.835 10.35-27.78a.243.243 0 0 1 .17-.052.244.244 0 0 1 .165.407c-11.405 10.78-10.21 27.22-10.195 27.385a.253.253 0 0 1-.059.178.254.254 0 0 1-.166.087h-.02Z"
      fill="#fff"
    />
    <path
      d="M211.54 172.415a.24.24 0 0 1-.165-.064.246.246 0 0 1-.075-.161 9.608 9.608 0 0 0-1.032-3.695 9.609 9.609 0 0 0-2.398-2.995.229.229 0 0 1-.099-.158.234.234 0 0 1 .044-.182.25.25 0 0 1 .158-.1.25.25 0 0 1 .182.04 9.89 9.89 0 0 1 3.63 7.065.24.24 0 0 1-.011.096.243.243 0 0 1-.214.169l-.02-.015ZM210.715 175.265a.242.242 0 0 1-.165-.065.244.244 0 0 1 0-.345c.12-.135 3-3.265 9.735-2.345a.247.247 0 0 1 .162.094.249.249 0 0 1-.045.344.238.238 0 0 1-.182.047c-6.44-.885-9.28 2.16-9.305 2.19a.268.268 0 0 1-.091.062.272.272 0 0 1-.109.018Z"
      fill="#fff"
    />
    <path
      d="m206.5 98.13-53.25-86.525a5.05 5.05 0 0 0-6.75-1.78L98.925 36 206.5 98.13Z"
      fill="#16796F"
    />
    <path
      d="M206.5 98.13 98.925 36v93.665a8.968 8.968 0 0 0 4.5 7.77L199 192.61a5.024 5.024 0 0 0 4.998-.033 5.036 5.036 0 0 0 2.502-4.327V98.13Z"
      fill="#16796F"
    />
    <g opacity={0.3} fill="#fff">
      <path d="m206.5 98.13-53.25-86.525a5.05 5.05 0 0 0-6.75-1.78L98.925 36 206.5 98.13Z" />
      <path d="M206.5 98.13 98.925 36v93.665a8.968 8.968 0 0 0 4.5 7.77L199 192.61a5.024 5.024 0 0 0 4.998-.033 5.036 5.036 0 0 0 2.502-4.327V98.13Z" />
    </g>
    <path
      d="m204 99.14-53.28-86.53a5.05 5.05 0 0 0-6.72-1.78L96.395 37 204 99.14Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m204 99.14-53.28-86.53a5.05 5.05 0 0 0-6.72-1.78L96.395 37 204 99.14Z"
      fill="#fff"
    />
    <path
      d="M204 99.14 96.395 37v93.67a8.997 8.997 0 0 0 4.5 7.775l95.55 55.17a5.035 5.035 0 0 0 7.55-4.355L204 99.14Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M204 99.14 96.395 37v93.67a8.997 8.997 0 0 0 4.5 7.775l95.55 55.17a5.035 5.035 0 0 0 7.55-4.355L204 99.14Z"
      fill="#fff"
    />
    <path
      d="m100.885 138.445 95.56 55.17a4.95 4.95 0 0 0 4.865.08l-57.035-85.875-47.88 22.85a9.003 9.003 0 0 0 4.49 7.775Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m100.885 138.445 95.56 55.17a4.95 4.95 0 0 0 4.865.08l-57.035-85.875-47.88 22.85a9.003 9.003 0 0 0 4.49 7.775Z"
      fill="#fff"
    />
    <path
      d="m96.395 37.016 46.59 79.42 61.01-17.295-107.6-62.125Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m96.395 37.016 46.59 79.42 61.01-17.295-107.6-62.125Z"
      fill="#fff"
    />
    <path
      d="M192.19 64.89v37.596l-49.205 13.945-34.505-58.82V16.556l83.71 48.335Z"
      fill="#F0F0F0"
    />
    <path
      d="M55.445 190.555 34.5 178.46v-28.715l20.945 12.095v28.715ZM55.445 190.555V161.84l20.945-12.095v28.715l-20.945 12.095Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M55.445 190.555 34.5 178.46v-28.715l20.945 12.095v28.715Z"
      fill="#000"
    />
    <path
      opacity={0.7}
      d="M55.445 190.555V161.84l20.945-12.095v28.715l-20.945 12.095Z"
      fill="#000"
    />
    <path
      d="m55.445 160.67-22.57-13.035 22.57-13.03 22.57 13.03-22.57 13.035Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="m55.445 160.67-22.57-13.035 22.57-13.03 22.57 13.03-22.57 13.035Z"
      fill="#000"
    />
    <path
      d="m42.69 141.971 22.57 13.03 2.94-1.696-22.57-13.034-2.94 1.7Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m42.69 141.971 22.57 13.03 2.94-1.696-22.57-13.034-2.94 1.7Z"
      fill="#fff"
    />
    <path
      d="m55.445 165.176-22.57-13.03v-4.51l22.57 13.035v4.505ZM55.445 165.176v-4.505l22.57-13.035v4.51l-22.57 13.03Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m55.445 165.176-22.57-13.03v-4.51l22.57 13.035v4.505Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M55.445 165.176v-4.505l22.57-13.035v4.51l-22.57 13.03Z"
      fill="#000"
    />
    <path
      d="m45.63 184.906-2.94-1.685v-29.915l2.94 1.695v29.905ZM65.26 184.906l2.94-1.685v-29.915l-2.94 1.695v29.905Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="m45.63 184.906-2.94-1.685v-29.915l2.94 1.695v29.905Z"
      fill="#000"
    />
    <path
      opacity={0.4}
      d="m65.26 184.906 2.94-1.685v-29.915l-2.94 1.695v29.905Z"
      fill="#000"
    />
    <path
      d="m68.2 141.971-22.57 13.03-2.94-1.696 22.57-13.034 2.94 1.7Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m68.2 141.971-22.57 13.03-2.94-1.696 22.57-13.034 2.94 1.7Z"
      fill="#fff"
    />
    <path
      d="M78.725 194.795 63.42 185.96v-20.98l15.305 8.835v20.98ZM78.725 194.795v-20.98l15.3-8.835v20.98l-15.3 8.835Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="M78.725 194.795 63.42 185.96v-20.98l15.305 8.835v20.98Z"
      fill="#000"
    />
    <path
      opacity={0.7}
      d="M78.725 194.795v-20.98l15.3-8.835v20.98l-15.3 8.835Z"
      fill="#000"
    />
    <path
      d="m78.725 172.961-16.495-9.52 16.495-9.52 16.49 9.52-16.49 9.52Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="m78.725 172.961-16.495-9.52 16.495-9.52 16.49 9.52-16.49 9.52Z"
      fill="#000"
    />
    <path
      d="m69.405 159.301 16.49 9.52 2.15-1.24-16.495-9.52-2.145 1.24Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m69.405 159.301 16.49 9.52 2.15-1.24-16.495-9.52-2.145 1.24Z"
      fill="#fff"
    />
    <path
      d="M78.725 176.255 62.23 166.73v-3.29l16.495 9.52v3.295ZM78.725 176.255v-3.295l16.49-9.52v3.29l-16.49 9.525Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M78.725 176.255 62.23 166.73v-3.29l16.495 9.52v3.295Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M78.725 176.255v-3.295l16.49-9.52v3.29l-16.49 9.525Z"
      fill="#000"
    />
    <path
      d="m71.55 190.665-2.145-1.23V167.58l2.145 1.24v21.845ZM85.895 190.665l2.15-1.23V167.58l-2.15 1.24v21.845Z"
      fill="#16796F"
    />
    <path
      d="m88.045 159.301-16.495 9.52-2.145-1.24 16.49-9.52 2.15 1.24Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m88.045 159.301-16.495 9.52-2.145-1.24 16.49-9.52 2.15 1.24Z"
      fill="#fff"
    />
    <path
      d="m95.66 89.41 9 15.245 10.23-5.475a17.289 17.289 0 0 1 6.465-1.97l-6.305 12.635-10.08 4.915a4.736 4.736 0 0 1-5.735-1.2c-1.14-1.385-2.4-3.65-3.575-7.32-3.345-10.46-4.905-15.92-4.905-15.92l1.9-2.935 3.005 2.025Z"
      fill="#FFA8A7"
    />
    <path
      d="M118.725 191.53c.03-.245.055-.5.085-.705.5-4.105 3.41-17.5 3-23.5l8.885-33.795-14.615-2.455s-6 21.67-6.74 33.5c-.405 6.5.115 17.965.66 26.95a12.539 12.539 0 0 0 8.725.005Z"
      fill="#455A64"
    />
    <path
      d="m113.73 208.065 4-5.885s.5-6.475.975-10.65a12.533 12.533 0 0 1-8.725.025c.45 7.345.915 13.045.915 13.045l2.835 3.465Z"
      fill="#FFA8A7"
    />
    <path
      d="m120.905 131.895-2.42.28c-2 9 4.27 13.185 4.27 13.185s-4.325 13.855-6.26 24.045c-.94 4.955 1.355 7.82 4.31 9.41.67-4.305 1.2-8.685 1-11.5l8.885-33.795-9.785-1.625Z"
      fill="#37474F"
    />
    <path
      d="M117.865 203.155a5.886 5.886 0 0 0-2.405 3 3.786 3.786 0 0 1-1.73 1.905s.405-1.76-.305-2.305c-.71-.545-3.21-2.5-3.475.265-.085.865-.165 1.73-.165 1.73s-3.32 3.6-4.935 4.385c-1.615.785-6.205.685-5.08 4.875 1.125 4.19 9.025 2.155 11.62-.115 2.595-2.27 8.97-1.77 8.88-4.8-.09-3.03-2.205-6.51-2.405-8.94Z"
      fill="#263238"
    />
    <path
      d="M117.75 202.18a5.89 5.89 0 0 0-2.415 3.025 3.835 3.835 0 0 1-1.75 1.92s.41-1.77-.305-2.325c-.715-.555-3.23-2.5-3.5.27l-.165 1.745s-3.34 3.62-4.965 4.41-6.25.69-5.115 4.91c1.135 4.22 9.085 2.17 11.7-.115 2.615-2.285 9.03-1.785 8.935-4.835-.095-3.05-2.215-6.56-2.42-9.005Z"
      fill="#37474F"
    />
    <path
      d="M112.215 210a.236.236 0 0 1-.14-.049.236.236 0 0 1-.085-.121 4.129 4.129 0 0 0-2.445-2.775.24.24 0 0 1-.144-.116.23.23 0 0 1-.016-.184.242.242 0 0 1 .3-.155 4.58 4.58 0 0 1 2.76 3.085c.01.03.014.061.012.093a.239.239 0 0 1-.167.207l-.075.015ZM110.77 211.345a.233.233 0 0 1-.23-.17 4.119 4.119 0 0 0-2.445-2.77.246.246 0 0 1-.16-.3.246.246 0 0 1 .044-.083.246.246 0 0 1 .163-.086.244.244 0 0 1 .093.009 4.581 4.581 0 0 1 2.76 3.09.24.24 0 0 1-.155.3l-.07.01ZM109.34 212.785a.249.249 0 0 1-.23-.17 4.086 4.086 0 0 0-2.445-2.77.24.24 0 0 1-.155-.3.235.235 0 0 1 .207-.17.226.226 0 0 1 .093.01 4.585 4.585 0 0 1 2.76 3.09.242.242 0 0 1-.16.3l-.07.01Z"
      fill="#F0F0F0"
    />
    <path
      d="M104.65 211.225c-1.625.79-6.25.69-5.115 4.91.735 2.72 4.3 2.835 7.44 1.96a9.264 9.264 0 0 0-.165-3.74c-.635-2.355-2.16-3.13-2.16-3.13Z"
      fill="#FAFAFA"
    />
    <path
      d="M149.65 172.69a5.86 5.86 0 0 0 2.275 3.1 3.818 3.818 0 0 1 1.39 2.17s-1.595-.85-2.31-.305c-.715.545-3.225 2.455-.64 3.425l1.625.61s2.61 4.135 2.95 5.9c.34 1.765-.95 6.17 3.39 6.17s4.425-8.155 2.91-11.25c-1.515-3.095.62-9.12-2.33-9.82-2.95-.7-6.865.435-9.26 0Z"
      fill="#263238"
    />
    <path
      d="M142.935 171.33c-.865-.23-1.76-.5-2.66-.785a62.045 62.045 0 0 0-8.7-2.115s6.83-16.73 8.33-25.605-2.045-14.885-2.045-14.885l-19.725 3.5c-1.5 9.08 6.135 13.725 6.135 13.725-.3-.075-4.365 13.3-5.94 23.555-1.575 10.255 7.43 10.585 11.745 10.5 2.06-.03 7.345-.035 12.3-.025 1.625-3.61 1.09-6.395.56-7.865Z"
      fill="#455A64"
    />
    <path
      d="M149 172.595a58.16 58.16 0 0 1-6.055-1.265c.53 1.47 1.04 4.255-.56 7.86l10.435.025c-.01-5.12-3.82-6.62-3.82-6.62Z"
      fill="#FFA8A7"
    />
    <path
      d="M148.675 172.545a5.926 5.926 0 0 0 2.295 3.12 3.857 3.857 0 0 1 1.4 2.185s-1.605-.855-2.325-.305c-.72.55-3.25 2.47-.65 3.445l1.64.615s2.63 4.165 2.97 5.94c.34 1.775-.955 6.215 3.415 6.215s4.455-8.21 2.925-11.325c-1.53-3.115.625-9.185-2.345-9.89-2.97-.705-6.91.455-9.325 0Z"
      fill="#37474F"
    />
    <path
      d="M151.035 181.845a.235.235 0 0 1-.2-.365 4.569 4.569 0 0 1 3.695-1.865.226.226 0 0 1 .093.013.23.23 0 0 1 .136.125.26.26 0 0 1 .007.184.243.243 0 0 1-.216.158 4.091 4.091 0 0 0-3.31 1.64.248.248 0 0 1-.205.11ZM152 183.6a.251.251 0 0 1-.13-.04.237.237 0 0 1-.075-.33 4.595 4.595 0 0 1 3.7-1.865.24.24 0 0 1 .01.48 4.16 4.16 0 0 0-1.849.433 4.162 4.162 0 0 0-1.466 1.207.225.225 0 0 1-.19.115ZM153 185.35a.245.245 0 0 1-.124-.03.235.235 0 0 1-.081-.335 4.579 4.579 0 0 1 3.7-1.865.226.226 0 0 1 .093.013.23.23 0 0 1 .136.125.242.242 0 0 1-.209.342 4.13 4.13 0 0 0-3.315 1.64.238.238 0 0 1-.2.11Z"
      fill="#F0F0F0"
    />
    <path
      d="M154 187.545c.345 1.775-.955 6.215 3.415 6.215 2.82 0 3.85-3.415 3.82-6.675a9.127 9.127 0 0 0-3.65-.815c-2.45 0-3.585 1.275-3.585 1.275Z"
      fill="#FAFAFA"
    />
    <path
      d="M139 75.75s.09-4.555 1.365-4.645c1.275-.09.35 3.14.35 3.14s1.33-.885 1.835-.09c.505.795-2.46 2.095-2.46 2.095l-1.09-.5Z"
      fill="#263238"
    />
    <path
      d="M112.31 84.675s.905 7.545 12.355 2.45c0 0-10.865 20.035 8.62 20.035 8.555 0 14.335-4.185 13.175-11.24 0 0 4.79-.105 5-3.945 0 0-5.865 2.565-8.415-3.235-2.55-5.8.95-11.535-3.395-13.855 0 0-2.425-7.705-17.18-3.595a4.655 4.655 0 0 0-4.435-.455c-2.605 1-3.27 5.595-2.605 9.075.665 3.48-.055 6.23-3.12 4.765Z"
      fill="#263238"
    />
    <path
      d="m136.11 98.745 2.315.63v15.295l-2.315 7.375a62.743 62.743 0 0 1 2.59 8.74 22.775 22.775 0 0 1-11.835 3.41c-6.87 0-10.785-3.11-10.785-3.11l-.87-10.18s-5.095-3.255-2.595-9.755c2.5-6.5 8.755-13.94 8.755-13.94h2.25s5.215 3.14 12.49 1.535Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m136.11 98.745 2.315.63v15.295l-2.315 7.375a62.743 62.743 0 0 1 2.59 8.74 22.775 22.775 0 0 1-11.835 3.41c-6.87 0-10.785-3.11-10.785-3.11l-.87-10.18s-5.095-3.255-2.595-9.755c2.5-6.5 8.755-13.94 8.755-13.94h2.25s5.215 3.14 12.49 1.535Z"
      fill="#000"
    />
    <path
      d="m132.675 97.86 3.435.885s-3.565 2.185-4.9 9.255a1.625 1.625 0 0 1-.745 1.081 1.614 1.614 0 0 1-1.3.174l-9.23-2.715a1.607 1.607 0 0 1-1.029-.945A1.618 1.618 0 0 1 119 104.2c1.14-2.03 3.075-5.295 4.64-7h3.075l5.96.66Z"
      fill="#FFA8A7"
    />
    <path d="m132.675 97.86-8.01-.6V87.125h8.01V97.86Z" fill="#FFA8A7" />
    <path
      d="M124.665 96.82a12.883 12.883 0 0 0 7.36-7v-2.695h-7.36v9.695Z"
      fill="#F28F8F"
    />
    <path
      d="M122.795 74s-7.325.43-6.42 12.96c.42 5.79 1.775 8.79 6.235 8.57 5.055-.245 12.23-3.72 11.76-13.635C133.9 71.98 122.795 74 122.795 74Z"
      fill="#FFA8A7"
    />
    <path
      d="M132.675 87.125s-2 .53-2-2.35c0 0-8.925-.605-10.895-9.46 0 0 .88-2.655 6.45-2.655s10.26 4.93 8.805 11.705l-2.36 2.76Z"
      fill="#263238"
    />
    <path d="M121.745 84.435v4.305l-2.52-.77 2.52-3.535Z" fill="#F28F8F" />
    <path
      d="M124.665 85.77a.88.88 0 1 0 0-1.76.88.88 0 0 0 0 1.76ZM119.77 84.28a.88.88 0 1 1-1.761.01.88.88 0 0 1 1.761-.01ZM117.82 82.455l1.72-1s-.66-.685-1.315-.275a1.204 1.204 0 0 0-.405 1.275Z"
      fill="#263238"
    />
    <path
      d="M121.965 90.17a4.5 4.5 0 0 0 2.825-.76s.19 1.5-1.055 1.775c-1.245.275-1.77-1.015-1.77-1.015Z"
      fill="#F28F8F"
    />
    <path
      d="M131.965 87.45a4.816 4.816 0 0 1 .395-2.125c.335-.745.925-1.5 1.95-1.525 2.25-.065 2.555 2.105 2.525 2.875-.08 2.025-1.72 4.215-4.775 4.3l-.095-3.525ZM137.66 99.165s-4.275 3.815-2.295 9.28c1.98 5.465 6 12.255 7.135 14.68 0 0-9.59 1.09-12 1.33l1.365 6.285s13.655.275 16.59-.805c2.935-1.08 4.3-3.67 2.765-7.7-1.15-3-6.155-13.875-8.575-19.1a7.012 7.012 0 0 0-4.325-3.76l-.66-.21Z"
      fill="#FFA8A7"
    />
    <path
      d="M64.905 76.47v46.09a5.081 5.081 0 0 0 2.545 4.405l57.125 32.92a2.298 2.298 0 0 0 2.294-.008 2.292 2.292 0 0 0 1.136-1.992V111.87a6.393 6.393 0 0 0-3.2-5.54L68.94 74.14a2.69 2.69 0 0 0-4.035 2.33Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="M64.905 76.47v46.09a5.081 5.081 0 0 0 2.545 4.405l57.125 32.92a2.298 2.298 0 0 0 2.294-.008 2.292 2.292 0 0 0 1.136-1.992V111.87a6.393 6.393 0 0 0-3.2-5.54L68.94 74.14a2.69 2.69 0 0 0-4.035 2.33Z"
      fill="#000"
    />
    <path
      d="M63.66 76.935v46.09a5.089 5.089 0 0 0 2.54 4.4l57.13 32.92a2.28 2.28 0 0 0 2.296-.004 2.29 2.29 0 0 0 1.134-1.996V112.33a6.395 6.395 0 0 0-3.2-5.54L67.695 74.6a2.69 2.69 0 0 0-4.035 2.335Z"
      fill="#16796F"
    />
    <path
      d="m85.635 84.94 1.085-.555 2.195 1.265-.97.62-2.31-1.33Z"
      fill="#16796F"
    />
    <path
      d="m85.635 138.625 2.31 1.33V86.27l-2.31-1.33v53.685Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="m85.635 138.625 2.31 1.33V86.27l-2.31-1.33v53.685Z"
      fill="#fff"
    />
    <path
      d="m96.635 90.095-2.305-1.33s-.445.66-.77 3.89c-.27 2.695-10.06-3-5.94-8.175 2.595-3.245 10.38 3.02 9.015 5.615ZM130.5 124.455a33.903 33.903 0 0 1-3.745 0c-.73-.135-3.075-2.41-3.7-2.5-.625-.09-2.22.915-1 4.735 1.22 3.82 3 5.555 5.78 5.145 2.78-.41 4-1.09 4-1.09l-1.335-6.29Z"
      fill="#FFA8A7"
    />
    <path
      d="M130.5 124.455a22.694 22.694 0 0 0-2.5-1.73v2.775l2.5-1.045Z"
      fill="#FFA8A7"
    />
    <path
      d="m63.66 101.055 63.1 36.43v-2.915l-63.1-36.43v2.915Z"
      fill="#16796F"
    />
    <path
      opacity={0.4}
      d="m63.66 101.055 63.1 36.43v-2.915l-63.1-36.43v2.915Z"
      fill="#fff"
    />
    <path
      d="M86.38 111.355s-2.62-8.845-.58-9.545c1.645-.565 3.315.94 2.715 4.225a60.193 60.193 0 0 0-.73 6.375 1.125 1.125 0 0 0 .949.086 1.126 1.126 0 0 0 .671-.676c1-2.765 3.205-7.085 1.89-10.385-.935-2.35-4.88-5.53-7.835-4.24s-1.855 7.71 1.31 13.96c0 0 .585 1.41 1.61.2Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M86.38 111.355s-2.62-8.845-.58-9.545c1.645-.565 3.315.94 2.715 4.225a60.193 60.193 0 0 0-.73 6.375 1.125 1.125 0 0 0 .949.086 1.126 1.126 0 0 0 .671-.676c1-2.765 3.205-7.085 1.89-10.385-.935-2.35-4.88-5.53-7.835-4.24s-1.855 7.71 1.31 13.96c0 0 .585 1.41 1.61.2Z"
      fill="#fff"
    />
    <path
      d="M87.055 115.55s2.62 8.845.58 9.545c-1.645.565-3.315-.94-2.715-4.225.356-2.109.6-4.235.73-6.37a1.114 1.114 0 0 0-.95-.084 1.116 1.116 0 0 0-.67.679c-1 2.765-3.205 7.08-1.89 10.38.935 2.35 4.88 5.53 7.835 4.24s1.855-7.71-1.31-13.96c0-.005-.585-1.415-1.61-.205Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M87.055 115.55s2.62 8.845.58 9.545c-1.645.565-3.315-.94-2.715-4.225.356-2.109.6-4.235.73-6.37a1.114 1.114 0 0 0-.95-.084 1.116 1.116 0 0 0-.67.679c-1 2.765-3.205 7.08-1.89 10.38.935 2.35 4.88 5.53 7.835 4.24s1.855-7.71-1.31-13.96c0-.005-.585-1.415-1.61-.205Z"
      fill="#fff"
    />
    <path
      d="m88.05 117.535-2.97-1.715a2.648 2.648 0 0 1-1.325-2.29v-3.08a1.384 1.384 0 0 1 2.08-1.2l2.54 1.465a2.9 2.9 0 0 1 1.45 2.5v3.285a1.185 1.185 0 0 1-1.775 1.035Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m88.05 117.535-2.97-1.715a2.648 2.648 0 0 1-1.325-2.29v-3.08a1.384 1.384 0 0 1 2.08-1.2l2.54 1.465a2.9 2.9 0 0 1 1.45 2.5v3.285a1.185 1.185 0 0 1-1.775 1.035Z"
      fill="#fff"
    />
    <path
      d="m126.76 137.485 1.245-.74v-3.005l-1.245.83v2.915Z"
      fill="#16796F"
    />
  </svg>
);
export default SvgLadyLoyalty;
