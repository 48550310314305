import BusinessHoursForm from 'components/uicomponents/BusinessHoursForm'
import { useSalonCache, useUserCache } from 'hooks';
import React from 'react'
import { Modal } from 'ui';

const AddBusinessHours = ({ isVisible, closeModal }: {
  isVisible: boolean,
  closeModal: () => void
}) => {
  const { getUserData } = useUserCache()
  const { getSalonFieldValue } = useSalonCache();
  const user = getUserData()
  return (
    <Modal
      title='Business hours'
      subTitle='Choose days and set times your weekly working hours'
      show={isVisible}
      closeModal={closeModal}
    >
      <BusinessHoursForm
        buttonText='Save'
        businessId={user?.business?.id}
        salonId={getSalonFieldValue('id')}
        accountSetup={false}
        onNextPage={closeModal}
      />
    </Modal>
  )
}

export default AddBusinessHours