/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react'
import {
  Button,
  CalendarPicker,
  Checkbox,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Layout,
  Modal,
  MultiSelect,
  MultiSelectOption,
  Paginator,
  Paragraph,
  Pill,
  SearchTerm,
  SelectInput,
  Skeleton,
  SvgLadyLoyalty,
  SvgMember,
  SvgMemberlove,
  SvgMembership,
  Table,
  TableFilterProps,
  Tabs,
  ToastProps,
  ToastWrapper,
  ToggleOnly
} from '../ui'
import { useModal, useToast } from 'hooks'
import {
  Client,
  ClientRewardHistory,
  CreatePromoInput,
  LoyaltyProgram,
  Promo,
  Service,
  User
} from 'core/generated'
import {
  SvgArrowBack,
  SvgArrowBox,
  SvgArrowDown,
  SvgArrowUpBox,
  SvgBrandLoyalty,
  SvgCheckMarkGreen,
  SvgChevronLeft,
  SvgDarkUserCircle,
  SvgFilter,
  SvgGreenSignal,
  SvgGreySignal,
  SvgIonCopyOutline,
  SvgLoyaltyPinIcon,
  SvgLoyaltyStepFour,
  SvgLoyaltyStepOne,
  SvgLoyaltyStepThree,
  SvgLoyaltyStepTwo,
  SvgPercentageBox,
  SvgPercentageDarkGreenCircle,
  SvgPromoEmpty,
  SvgShoppingTagCircle,
} from 'ui'
import { COLORS } from 'constants/colors'
import { Menu, Transition } from '@headlessui/react'
import Label from 'ui/atoms/formLabel/Label'
import FullTable from 'ui/organism/table/Table'
import {
  checkPromoDatesValidity,
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  copyTextToClipboard,
  formatDateToOriginalDate,
  formatInToPrice,
  formatNumber,
  formatTableHeadersFilterArray,
  getDayMonthAndNumberAndTimeFromDate,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  limitString,
  sortOptions
} from '../components/utils/misc'
import { Controller, useForm } from 'react-hook-form'
import { ERRORS } from 'constants/errors'
import { useSalonCache } from 'hooks/useSalonCache'
import { useUserCache } from 'hooks/useUserCache'
import {
  convertMilestonesInputForCreation,
  formatBusinessPackagesToSelectField,
  formatBusinessProductsToSelectField,
  formatBusinessServicesToSelectField,
  formatSalonBranchesToSelectField,
  getServicesAndProductsForAMileStone
} from '../components/uicomponents/utils'
import { LoyaltyInput, LoyaltyMilestoneInput } from '../components/uicomponents/types'
import {
  LOYALTY_MEMBER_HEADINGS,
  LOYALTY_MEMBER_HEADINGS_MOBILE,
  VALID_FOR
} from 'constants/information'
import { SummaryData } from '../components/uicomponents/homeComponents/types'
import Chart from 'react-google-charts'
import { DEFAULT_CURRENCY, DEFAULT_CURRENCY_CODE } from 'constants/currency'
import { PermissionActionProp } from '../components/utils/types'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import { canPerformAction, checkPageRightStatus } from '../components/utils/permission'
import AddonSubscription from '../components/uicomponents/AddonSubscription'
import LoyaltyCard from 'ui/molecules/loyaltyCard/LoyaltyCard'
import { CollapseRowProps } from 'ui/organism/collapseRow/types'
import PromptLoyaltyDeletion from '../components/uicomponents/PromptLoyaltyDeletion'
import BlockPage from '../components/uicomponents/BlockPage'
import PromptLoyaltyFinalDeletion from '../components/uicomponents/PromptLoyaltyFinalDeletion'
import { ACTVITY_LOYALTY_TABLE_HEADERS, ARCHIVED_MEMBERSHIP_TABLE_HEADERS, LOYALTY_ACTIVITY_TAB, LOYALTY_ACTIVITY_TABS, MEMBERSHIP_ACTIVITY_TAB, MEMBERSHIP_ACTIVITY_TABS, MEMBERSHIP_HISTORY, MEMBERSHIP_HISTORY_MOBILE, MEMBERSHIP_TABLE_HEADERS, MEMBERSHIP_TABLE_HEADERS_MOBILE, MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS } from '../components/uicomponents/customerDasboard/constants'
import CustomerDashboardShimmer from '../components/uicomponents/customerDasboard/DashboardShimmer'
import { useActiveMemberships, useArchiveMembership, useArchiveMemberships, useBusinessServices, useCreateLoyalty, useCreateMembership, useCreatePromo, useDeleteLoyaltyProgram, useDeleteMembership, useDeletePromo, useGetMemberships, useGetPromos, useLoyaltyOverview, useLoyaltyProgram, useMembershipPurchase, useRestoreMembership, useUpgradeMembership } from 'api/useLoyaltyAndPromos';
import { useGetClient, useGetClients } from 'api/useClient';
import { MembershipProps } from 'api/types/general'

const LOYALTY_AND_PROMOS_TAB_NAME = {
  LOYALTY: 'Loyalty',
  PROMOS: 'Promos',
  MEMBERSHIP: 'Membership',
}

const LOYALTY_AND_PROMOS_TABS = [
  {
    key: LOYALTY_AND_PROMOS_TAB_NAME.PROMOS,
    title: LOYALTY_AND_PROMOS_TAB_NAME.PROMOS,
    show: true
  },
  {
    key: LOYALTY_AND_PROMOS_TAB_NAME.LOYALTY,
    title: LOYALTY_AND_PROMOS_TAB_NAME.LOYALTY,
    show: true
  },
  {
    key: LOYALTY_AND_PROMOS_TAB_NAME.MEMBERSHIP,
    title: LOYALTY_AND_PROMOS_TAB_NAME.MEMBERSHIP,
    show: true
  }
]

const PROMO_HEADINGS = [
  {
    label: 'Promo code',
    value: 'code',
    show: true
  },
  {
    label: 'Applied to',
    value: 'appliedTo',
    show: true
  },
  {
    label: 'Location(s)',
    value: 'locations',
    show: true
  },
  {
    label: 'Created',
    value: 'created',
    show: true
  },
  {
    label: 'Times used',
    value: 'saleCount',
    show: true
  },
  {
    label: 'Promo value',
    value: 'promoValue',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  }
]

const MOBILE_PROMO_HEADINGS = [
  {
    title: 'Promo code',
    key: 'code'
  },
  {
    title: 'Promo value',
    key: 'promoValue'
  }
]

const LoyaltyAndPromos = () => {
  const [activeTab, setActiveTab] = useState(LOYALTY_AND_PROMOS_TAB_NAME.PROMOS)
  const { toast, addToast } = useToast()
  const [openPromoModal, setOpenPromoModal] = useState(false);
  const [openLoyaltyModal, setOpenLoyaltyModal] = useState(false);
  const [reloadLoyaltyPage, setReloadLoyaltyPage] = useState(false);
  const { setSalonData } = useSalonCache()
  const { setBusinessData } = useUserCache()
  const [showLoyaltyPage, setShowLoyaltyPage] = useState<string>(checkPageRightStatus('loyalty', 'Loyalty::ViewLoyalty'))


  const openAddLoyaltyModal = () => {
    setOpenLoyaltyModal(true)
  }

  const closeLoyaltyModal = () => {
    setOpenLoyaltyModal(false)
  }

  const openAddPromoModal = () => {
    setOpenPromoModal(true)
  }

  const closePromoModal = () => {
    setOpenPromoModal(false)
  }

  const reloadPage = async () => {
    setReloadLoyaltyPage(!reloadLoyaltyPage)
  }

  useEffect(() => {
    setShowLoyaltyPage(checkPageRightStatus('loyalty', 'Loyalty::ViewLoyalty'))
    // reload window
  }, [reloadLoyaltyPage])
  return (
    <Layout
      pageTitle='Promos'
      openModal={
        activeTab === LOYALTY_AND_PROMOS_TAB_NAME.LOYALTY
          ? openAddLoyaltyModal
          : openAddPromoModal
      }
      pageLevel={2}
    >
      <ToastWrapper toast={toast as ToastProps} />
      <Tabs
        tabs={LOYALTY_AND_PROMOS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === LOYALTY_AND_PROMOS_TAB_NAME.LOYALTY ? (
        <>
          {showLoyaltyPage === 'showPage' ? (
            <LoyaltyPage
              openPage={openLoyaltyModal}
              closePage={closeLoyaltyModal}
              reloadPage={reloadLoyaltyPage}
              setReloadPage={setReloadLoyaltyPage}
            />
          ) : showLoyaltyPage === 'shouldSubscribe' ? (
            <AddonSubscription module='loyalty' addToast={addToast} reloadPage={reloadPage} />
          ) : showLoyaltyPage === 'hidePage' ? <BlockPage /> : null}
        </>
      ) : activeTab === LOYALTY_AND_PROMOS_TAB_NAME.PROMOS ? (
        <>
          <Promos openPage={openPromoModal} closePage={closePromoModal} addToast={addToast} />
        </>
      ) : (
        <Membership openPage={openPromoModal} closePage={closePromoModal} />
      )}
    </Layout>
  )
}

const LoyaltyCardShimmer = () => {
  return (
    <div className='flex flex-wrap w-full gap-10'>
      {[1, 2, 3].map((item) => (
        <div
          className='w-full xl:max-w-[268px] flex flex-col p-6 space-y-6 border border-grey-100 rounded-md'
          key={item}
        >
          <Skeleton width='40px' height='40px' />
          <Skeleton width='80%' height='1.5rem' />
          <div className='flex flex-col space-y-2'>
            <Skeleton width='70%' height='1rem' />
            <Skeleton width='80%' height='1rem' />
          </div>
          <Skeleton width='4rem' height='2rem' />
        </div>
      ))}
    </div>
  )
}

const LoyaltyPage = ({
  openPage,
  closePage,
  reloadPage,
  setReloadPage
}: {
  openPage: boolean
  closePage: () => void
  reloadPage: boolean,
  setReloadPage: (value: boolean) => void
}) => {
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const token = localStorage.getItem('token')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('')
  const [clientDebouncedSearchQuery, setClientDebouncedSearchQuery] = useState<string>('');
  const [memberHeadings, setMemberHeadings] = useState(LOYALTY_MEMBER_HEADINGS)
  const { toast, addToast } = useToast()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingLoyaltyOverviewData, setLoadingLoyaltyOverviewData] =
    useState(false)
  const [activityAndRewardActiveTab, setActivityAndRewardActiveTab] = useState(LOYALTY_ACTIVITY_TAB.REWARDS)
  const [client, setClient] = useState<Client>()
  const { getSalonFieldValue } = useSalonCache()
  const {
    data: loyaltyData,
    loading: loadingLoyalty,
    refetch
  } = useLoyaltyProgram()
  const loyalty = useMemo(() => loyaltyData?.loyaltyProgram, [loyaltyData])
  const {
    data: loyaltyOverviewData,
    loading,
    refetch: refetchLoyaltyOverview
  } = useLoyaltyOverview({
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end')
  })
  const loyaltyOverview = useMemo(() => loyaltyOverviewData?.loyaltyOverviewData, [loyaltyOverviewData])
  const [before, setBefore] = useState(null)
  const [after, setAfter] = useState(null)
  const {
    data: clientsData,
    refetch: refetchClients,
  } = useGetClients({
    salonId,
    q: debouncedSearchQuery,
    after,
    before,
    first: 50
  })
  const clients = useMemo(() => clientsData?.salonClients, [clientsData])
  const {
    isVisible: isAddLoyaltyModalVisible,
    closeModal: closeAddLoyaltyModal,
    openModal: openAddLoyaltyModal
  } = useModal()

  const {
    openModal: openViewClientActivityModal,
    closeModal: closeViewClientActivityModal,
    isVisible: isViewClientActivityModalVisible
  } = useModal()

  const handleCloseLoyaltyModal = () => {
    closeAddLoyaltyModal()
    closePage()
    refetch()
    refetchLoyaltyOverview()
  }

  useEffect(() => {
    if (openPage) {
      openAddLoyaltyModal()
    }
  }, [openPage])

  useEffect(() => {
    if (reloadPage) {
      refetchLoyaltyOverview()
      refetch
    }
  }, [reloadPage])

  useEffect(() => {
    refetchLoyaltyOverview()
  }, [selectedDates])

  const chartData: SummaryData = [
    ['Status', 'Count'],
    ['Total issued', loyaltyOverview?.redemptionRate?.totalIssued],
    ['Total redeemed', loyaltyOverview?.redemptionRate?.totalRedeemed]
  ]

  const chartOptions = {
    pieHole: 0.4,
    is3D: false,
    pieSliceText: 'none',
    colors: ['#E2E2E2', '#7EA05C']
  }

  const generateMemberTableData = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    members: any,
    tableHeadings: TableFilterProps
  ) => {
    return members?.map?.((member: any) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'date':
              rowData[heading.value] = getNumberMonthAndYearFromDate(member?.date)
              break
            case 'name':
              rowData[heading.value] = member?.name
              break
            case 'spendValue':
              rowData[heading.value] = member?.spendType === 'client_visits' ? Number(member?.spendValue) + ' times' : formatInToPrice(member?.spendValue)?.replace(".00", "")
              break
            case 'pointsEarned':
              rowData[heading.value] = member?.pointsEarned
              break
            case 'discountValue':
              rowData[heading.value] = member?.discountType === 'percentage' ? `${member?.discountValue?.replace(".0", "")}%` : formatInToPrice(member?.discountValue)?.replace(".00", "")
              break
          }
        }
      })
      return rowData
    })
  }

  const membersData = generateMemberTableData(
    loyaltyOverview?.topMembers,
    memberHeadings
  )

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in clientHeadings then update the show
    const updatedHeadings = memberHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setMemberHeadings(updatedHeadings)
  }

  const goToNext = () => {
    if (clients?.pageInfo?.hasNextPage) {
      const after = clients?.pageInfo?.endCursor;
      setAfter(after)
      refetchClients()
    }
  }

  const goToPrev = () => {
    if (clients?.pageInfo?.hasPreviousPage) {
      const before = clients?.pageInfo?.startCursor;
      setBefore(before)
      refetchClients()
    }
  }

  const CLIENTS_HEADING = [
    {
      label: 'Name',
      value: 'name',
      show: true
    },
    {
      label: 'Phone',
      value: 'phone',
      show: true
    },
    {
      label: 'Last Visit',
      value: 'lastVisit',
      show: true
    },
    {
      label: 'Amount spent',
      value: 'amountSpent',
      show: true
    },
    {
      label: 'Points',
      value: 'points',
      show: true
    }
  ]

  const MOBILE_CLIENTS_HEADINGS = [
    {
      title: 'Name',
      key: 'name'
    },
    {
      title: 'Phone',
      key: 'phone'
    },
    {
      title: 'Points',
      key: 'points'
    }
  ]

  const openClientLoyaltyActivity = (id: number) => {
    const client = clients?.nodes[id] || null

    if (client) {
      setClient(client)
      openViewClientActivityModal()
    }
  }

  const initiateCloseViewClientActivityModal = () => {
    setClient(null)
    closeViewClientActivityModal()
  }

  const tableClientsData = clients?.nodes?.map((client) => ({
    name: client?.firstName + " " + client?.lastName,
    phone: client?.phone,
    lastVisit: client?.lastVisit
      ? getDayMonthAndNumberAndTimeFromDate(client?.lastVisit)
      : null,
    amountSpent: formatInToPrice(client?.totalTransactions),
    points: Number(client?.pointsEarned)
  }))

  useEffect(() => {
    refetchClients
  }, [clientDebouncedSearchQuery])

  const getLoyaltyContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col space-y-6 p-6'>
          <LoyaltyCardShimmer />
        </div>
      )
    } else if (loyalty) {
      return (
        <div className='flex flex-col space-y-6'>
          {loyalty?.status === 'active' ?
            <>
              <div className='justify-between items-center hidden xl:flex border-b border-grey-50 py-6 px-4'>
                <Heading variant='h2' size='h11' weight='semiBold'>
                  Loyalty
                </Heading>
                <div className='flex space-x-2'>
                  {loyalty?.status === 'active' ?
                    <Button
                      variant='primary'
                      size='sm'
                      disabled={false}
                      loading={false}
                      type='button'
                      rounded='md'
                      fontSize='b5'
                      onClick={openAddLoyaltyModal}
                    >
                      Edit Loyalty Program
                    </Button> : <Button
                      variant='primary'
                      size='sm'
                      disabled={false}
                      loading={false}
                      type='button'
                      rounded='md'
                      fontSize='b5'
                      onClick={openAddLoyaltyModal}
                    >
                      Create Loyalty Program
                    </Button>}
                </div>
              </div>
              <div className='w-full flex flex-col lg:flex-row p-4 gap-4'>
                <div className='w-full max-w-[500px] flex flex-col space-y-4'>
                  <div className='w-full flex justify-between p-4 border border-grey-100 rounded-md'>
                    <div className='flex space-x-4 items-start'>
                      <SvgLoyaltyPinIcon width="20px" height="20px" />
                      <div className='flex flex-col'>
                        <Paragraph size='b5' weight='normal' color={COLORS.BLACK}>Loyalty program status</Paragraph>
                        <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]}>Track your loyalty program status</Paragraph>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2 rounded-full border border-grey-100 bg-grey-50 py-2 px-4">
                      {loyalty?.status === 'active' ?
                        <>
                          <SvgGreenSignal />
                          <Paragraph size='b4' weight='semiBold' color={COLORS.GREEN[300]} className=''>Active</Paragraph>
                        </>
                        : <>
                          <SvgGreySignal />
                          <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[300]} className=''>Inactive</Paragraph>
                        </>
                      }
                    </div>
                  </div>
                  <LoyaltyCard loyalty={loyalty} onClick={loyalty?.status === 'active' ? openAddLoyaltyModal : null} />
                </div>
                <div className='w-full lg:max-w/1/2 flex flex-col space-y-4'>
                  <div className='w-full flex flex-col border border-grey-50 p-4 rounded-md'>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>
                      Redemption rate
                    </Paragraph>
                    <Chart
                      chartType='PieChart'
                      width='100%'
                      height='100%'
                      data={chartData}
                      options={chartOptions}
                    />
                  </div>
                  <div className='w-full flex justify-between items-center border border-grey-50 p-4 rounded-md'>
                    <div className='flex space-x-3'>
                      <SvgArrowUpBox width='28px' height='28px' />
                      <div className=''>
                        <Paragraph size='b4' color={COLORS.GREY[900]}>
                          Top service redeemed{' '}
                        </Paragraph>
                        <Paragraph size='b5' color={COLORS.GREY[400]}>
                          Most popular service redeemed with reward
                        </Paragraph>
                      </div>
                    </div>
                    <Paragraph size='b1' color={COLORS.GREY[900]} weight='semiBold'>
                      {loyaltyOverview?.topServicePurchased}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </> :
            <>
              <div className='justify-between items-center hidden xl:flex border-b border-grey-50 py-6 px-4'>
                <Heading variant='h2' size='h11' weight='semiBold'>
                  Loyalty
                </Heading>
                <div className='flex space-x-2'>
                  {loyalty?.status === 'active' ?
                    <Button
                      variant='primary'
                      size='sm'
                      disabled={false}
                      loading={false}
                      type='button'
                      rounded='md'
                      fontSize='b5'
                      onClick={openAddLoyaltyModal}
                    >
                      Edit Loyalty Program
                    </Button> : <Button
                      variant='primary'
                      size='sm'
                      disabled={false}
                      loading={false}
                      type='button'
                      rounded='md'
                      fontSize='b5'
                      onClick={openAddLoyaltyModal}
                    >
                      Create Loyalty Program
                    </Button>}
                </div>
              </div>
              <div className='w-full flex flex-col space-y-2 py-8 items-center justify-center'>
                <div className='w-full max-w-[250px] flex justify-center items-center'>
                  <SvgLadyLoyalty width="100%" height="100%" />
                </div>
                <Heading variant='h2' size='h7' weight="bold">
                  No Loyalty Program
                </Heading>
                <Button
                  variant='text'
                  size='none'
                  disabled={false}
                  loading={false}
                  type='button'
                  fontSize='b5'
                  fontWeight='semiBold'
                  onClick={openAddLoyaltyModal}
                  className='text-grey-300'
                >
                  Begin by creating a new {<span className="text-green-300 font-semibold">one.</span>}
                </Button>
              </div>
            </>}
          <div className='w-full flex flex-col space-y-4'>
            <div className='w-full flex border-l border-t border-r border-grey-50'>
              <Tabs
                tabs={LOYALTY_ACTIVITY_TABS}
                activeTab={activityAndRewardActiveTab}
                setActiveTab={setActivityAndRewardActiveTab}
                variant='background'
              />
            </div>
            {activityAndRewardActiveTab === LOYALTY_ACTIVITY_TAB.REWARDS ?
              <>
                <div className='w-full flex justify-between px-4 py-3 border-t border-grey-50'>
                  <div className='w-full lg:max-w-[50%] flex space-x-3 items-center'>
                    <SearchTerm setDebouncedSearchQuery={setDebouncedSearchQuery} />
                  </div>
                  <CalendarPicker {...{ selectedDates, setSelectedDates }} />
                </div>
                <FullTable
                  headers={formatTableHeadersFilterArray(memberHeadings)}
                  mobileHeaders={LOYALTY_MEMBER_HEADINGS_MOBILE}
                  rows={membersData}
                  tableOptions={{
                    view: true,
                    edit: true,
                    delete: true,
                    duplicate: false
                  }}
                />
              </>
              : (
                <>
                  <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
                    <Paragraph size='b4' weight='semiBold' className=''>
                      Client list
                    </Paragraph>
                    <div className='flex space-x-2 items-center'>
                      <SearchTerm setDebouncedSearchQuery={setClientDebouncedSearchQuery} size="sm" />
                      <Paginator pageInfo={clients?.pageInfo} {...{ goToNext, goToPrev }} />
                    </div>
                  </div>
                  <FullTable
                    headers={CLIENTS_HEADING}
                    mobileHeaders={MOBILE_CLIENTS_HEADINGS}
                    rows={tableClientsData}
                    onClick={openClientLoyaltyActivity}
                  />
                </>
              )}
          </div>
        </div>
      )
    }
    return (
      <div className='w-full flex justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-4'>
          <div className='w-full max-w-[350px] flex justify-center items-center'>
            <SvgLadyLoyalty width="100%" height="100%" />
          </div>
          <Heading variant='h2' size='h3'>
            Loyalty
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any loyalty yet.
          </Paragraph>
          <Button
            variant='primary'
            size='lg'
            disabled={false}
            loading={false}
            type='button'
            rounded='lg'
            onClick={openAddLoyaltyModal}
          >
            Create Loyalty Program
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {getLoyaltyContent()}
      <AddLoyalty
        isVisible={isAddLoyaltyModalVisible}
        closeModal={handleCloseLoyaltyModal}
        loyalty={loyalty}
        addToast={addToast}
      />
      <ViewClientActivityModal
        isVisible={isViewClientActivityModalVisible}
        closeModal={initiateCloseViewClientActivityModal}
        client={client}
      />
    </>
  )
}

type LoyaltyProps = {
  loyalty?: LoyaltyProgram
  isVisible: boolean
  closeModal: () => void
  addToast: (toast: ToastProps) => void
}

const AddLoyalty = ({ isVisible, closeModal, loyalty, addToast }: LoyaltyProps) => {
  const { getUserData } = useUserCache()
  const [updatedLoyalty, setUpdatedLoyalty] = useState<LoyaltyProgram | null>(null)
  const [step, setStep] = useState(1)
  const user = getUserData()
  const [mileStones, setMileStones] = useState<LoyaltyMilestoneInput[]>([])
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<LoyaltyInput>({
    defaultValues: {
      isAutomatic: true,
      requirementPoints: 1,
    }
  })
  const {
    loading: saving,
    createLoyalty
  } = useCreateLoyalty()
  const {
    deleteLoyaltyProgram
  } = useDeleteLoyaltyProgram()
  const {
    isVisible: isDeleteLoyaltyModalVisible,
    closeModal: closeDeleteLoyaltyModal,
    openModal: openDeleteLoyaltyModal
  } = useModal()
  const {
    isVisible: isDeleteLoyaltyFinalModalVisible,
    closeModal: closeDeleteLoyaltyFinalModal,
    openModal: openDeleteLoyaltyFinalModal
  } = useModal()

  const {
    data: servicesData,
    refetch: refetchBusinessServices
  } = useBusinessServices({
    businessId: user?.business?.id
  });
  const servicesOptions = useMemo(() => {
    return servicesData?.businessServices?.map((x) => ({ label: x.name, value: x.id })) || []
  }, [servicesData]) as MultiSelectOption[]
  const productsOptions = []

  useEffect(() => {
    if (user?.business?.id) {
      refetchBusinessServices()
    }
  }, [user?.business?.id, refetchBusinessServices])

  const checkIfRequiredFieldsAreFilledAsync = (input: LoyaltyInput) => {
    if (!input?.requirementType) {
      addToast({
        message: 'Define the earning criteria',
        variant: 'error'
      })
      return false
    }

    if (!input?.validityMonths) {
      addToast({
        message: 'Define reward validity period',
        variant: 'error'
      })
      return false
    }

    if (!input?.requirementValue) {
      addToast({
        message: 'Define the value of a point',
        variant: 'error'
      })
      return false
    }

    if (!input?.milestone?.customerReceivesType) {
      addToast({
        message: 'Define the reward monetary type',
        variant: 'error'
      })
      return false
    }

    if (!input?.milestone?.customerReaches) {
      addToast({
        message: 'Define the reward trigger',
        variant: 'error'
      })
      return false
    }

    if (!input?.milestone?.serviceIds) {
      addToast({
        message: 'Choose the reward service',
        variant: 'error'
      })
      return false
    }

    return true
  }

  const addLoyaltyReward = async (input: LoyaltyInput) => {
    const checkIfRequiredFieldsAreFilled = checkIfRequiredFieldsAreFilledAsync(input);
    if (!checkIfRequiredFieldsAreFilled) return;

    const milestone = convertMilestonesInputForCreation(
      input?.milestone,
      servicesOptions,
      productsOptions
    )
    const points = milestone?.customerPointsAttained === 0 ? Number(milestone?.customerReaches?.toString()?.replaceAll(",", "")) / Number(input?.requirementValue?.toString()?.replaceAll(",", "")) : milestone?.customerPointsAttained;

    delete milestone?.customerReaches;
    const inputData = {
      id: loyalty?.id || null,
      ruleRequirement: input?.requirementType,
      rewardPoint: input?.requirementPoints? Number(input?.requirementPoints.toString().replace(/,/g, ''))
      : 1,
      ruleValue: input?.requirementValue
        ? Number(input?.requirementValue.toString().replace(/,/g, ''))
        : 0,
      milestones: [{
        ...milestone,
        id: loyalty?.milestones[0]?.id || null,
        customerPointsAttained: points
      }],
      validityMonths: Number(input?.validityMonths),
    }
    createLoyalty({
      variables: {
        input: { ...inputData }
      }
    }).then(({ data }) => {
      const { createLoyaltyProgram: cl } = data
      if (cl.status === 200) {
        setUpdatedLoyalty(cl?.loyaltyProgram)
        setStep(5)
        addToast({ message: 'Loyalty saved successfully', variant: 'success' })
      } else {
        addToast({ message: cl.errors[0].message, variant: 'error' })
      }
    }).catch((error) => {
      addToast({ message: error.message, variant: 'error' })
    })
  }

  const disableLoyalty = async () => {
    deleteLoyaltyProgram({
      variables: {
        input: { id: loyalty?.id }
      }
    }).then(({ data }) => {
      const { deleteLoyaltyProgram: dl } = data
      if (dl.status === 200) {
        closeDeleteLoyaltyFinalModal()
        setUpdatedLoyalty(null)
        closeModal()
        resetForm()
        addToast({ message: 'Loyalty deleted successfully', variant: 'success' })
      } else {
        addToast({ message: dl.errors[0].message, variant: 'error' })
      }
    })
  }

  const handleCancelOrDelete = async () => {
    if (loyalty) openDeleteLoyaltyModal()
    else {
      resetForm()
      setUpdatedLoyalty(null)
      _closeModal()
    }
  }

  const resetForm = () => {
    setValue('requirementType', '')
    setValue('requirementValue', 1)
    setValue('requirementPoints', 1)
    setMileStones([])
    setValue("validityMonths", 0)
    setValue("milestone.customerPointsAttained", null)
    setValue("milestone.customerReceives", null)
    setValue("milestone.customerReaches", null)
    setValue("milestone.customerReceivesType", '')
    setValue("milestone.name", '')
    setValue("milestone.serviceIds", [])
    setValue('includeSales', false)
    setStep(1)
  }

  const _closeModal = () => {
    closeModal()
    setUpdatedLoyalty(null)
    resetForm()
    setStep(1)
  }

  const addMileStone = () => {
    setMileStones([
      ...mileStones,
      {
        customerPointsAttained: null,
        customerReceives: null,
        customerReaches: null,
        name: 'Mile',
        id: undefined,
        serviceIds: [],
        customerReceivesType: 'fixed_value',
        show: true
      }
    ])
  }

  useEffect(() => {
    if (loyalty && loyalty?.status === 'active') {
      setValue('requirementType', loyalty.ruleRequirement)
      setValue('requirementPoints', loyalty.rewardPoint)
      setValue('requirementValue', loyalty.ruleValue)
      setValue('includeSales', loyalty.includeSales)
      setValue('validityMonths', loyalty.validityMonths)
      const miles = Array.isArray(loyalty?.milestones)
        ? loyalty.milestones.map((x) => {
          return {
            customerPointsAttained: x.customerPointsAttained,
            customerReceives: x.customerReceives,
            customerReaches: 0,
            name: x.name,
            id: x.id,
            customerReceivesType: x.customerReceivesType,
            serviceIds: getServicesAndProductsForAMileStone(
              x.services || [],
              x.products || []
            ),
            show: true
          }
        })
        : []
      setMileStones(miles)
      miles?.map((mile) => {
        const customerReaches = mile?.customerPointsAttained * loyalty?.ruleValue;
        setValue(
          `milestone.customerPointsAttained`,
          mile?.customerPointsAttained
        )
        setValue(`milestone.customerReceives`, mile?.customerReceives)
        setValue(`milestone.customerReaches`, customerReaches)
        setValue(
          `milestone.customerReceivesType`,
          mile?.customerReceivesType
        )
        setValue(`milestone.name`, mile?.name || "")
        setValue(`milestone.id`, mile?.id)
        setValue(`milestone.serviceIds`, mile?.serviceIds as [])
      })
    } else {
      resetForm()
      setMileStones([])
    }
  }, [loyalty, setValue, isVisible])

  useEffect(() => {
    if (watch('milestone.customerReaches')) {
      const points = Number(watch('milestone.customerReaches')?.toString()?.replaceAll(",", "")) / Number(watch('requirementValue')?.toString()?.replaceAll(",", "")) * (Number(watch('requirementPoints')?.toString()?.replaceAll(",", "")));
      setValue(`milestone.customerPointsAttained`, points)
    }
  }, [watch('milestone.customerReaches'), watch('requirementValue'), watch('requirementPoints')])

  const ProgressBar = () => {
    const steps = Array.from({ length: 5 }, (_, i) => i + 1);
    return (
      <div className='w-full flex items-center justify-center p-4'>
        {/* <div className='w-full flex items-center'>
        <div className="w-[20px] h-[20px] bg-green-300 rounded-full"></div>
        <div className='w-full border-2 border-green-300'></div>
      </div> */}
        {steps.map((_, index) => {
          const isActive = step >= index + 1
          return (
            <div key={index} className='w-full flex items-center'>
              <div className={`w-[20px] h-[20px] rounded-full ${isActive ? 'bg-green-300' : 'bg-grey-100'}`}></div>
              <div className={`w-full border-2 ${isActive ? 'border-green-300' : 'border-grey-100'}`}></div>
            </div>
          )
        })}
        <div className='w-fit flex items-center'>
          <div className={`w-[20px] h-[20px] rounded-full ${step >= 5 ? 'bg-green-300' : 'bg-grey-100'}`}></div>
        </div>
      </div>
    )
  }

  const LOYALTY_OPTIONS = [{
    value: 'client_visits',
    label: 'Visits - how many times they come to your business'
  }, {
    value: 'client_spends',
    label: 'Amount spent  - how much they spend '
  }]

  const SHORT_LOYALTY_OPTIONS = [{
    value: 'client_visits',
    label: 'Visits'
  }, {
    value: 'client_spends',
    label: 'Spends'
  }]

  const handleRequirementChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setValue('requirementType', selectedValue);
  };

  const moveToNextStep = () => {
    setStep(step + 1)
    if (step === 2 && mileStones?.length === 0) {
      addMileStone()
    }
  }

  const handleDeletionLoyaltyModal = (action?: string) => {
    if (action === 'delete') {
      closeDeleteLoyaltyModal()
      openDeleteLoyaltyFinalModal()
    }

    if (action === 'back') closeDeleteLoyaltyModal();
  }

  const handleDeletionLoyaltyFinalModal = (action?: string) => {
    if (action === 'delete') disableLoyalty();

    if (action === 'back') closeDeleteLoyaltyFinalModal();
  }

  return (
    <>
      <Modal
        title=''
        subTitle=''
        variant='right'
        show={isVisible}
        closeModal={_closeModal}
      >
        <form
          onSubmit={handleSubmit(addLoyaltyReward)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          {/* progress bar */}
          {step !== 5 ? (
            <>
              <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
                <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
                  <Button
                    variant='text'
                    size='none'
                    type='button'
                    className='w-fit'
                    fontSize='b4'
                    onClick={_closeModal}
                  >
                    <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
                  </Button>
                </div>
                <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
                  <Button
                    variant='icon'
                    size='square'
                    type='button'
                    rounded='md'
                    className='w-fit'
                    fontSize='b4'
                    onClick={_closeModal}
                  >
                    <SvgChevronLeft width='24px' height='24px' />
                  </Button>
                  <Paragraph size='b3' weight='bold'>
                    {loyalty ? 'Edit loyalty' : 'Create loyalty'}
                  </Paragraph>
                </div>
              </div>

              <div className='w-full flex flex-col space-y-4'>
                <div className='w-full xl:w-1/2 flex flex-col space-y-6 px-6'>
                  <Heading variant='h1' size='h9' weight='semiBold'>
                    {loyalty ? 'Edit loyalty' : 'Create loyalty'}
                  </Heading>
                  <Paragraph size='b4'>
                    {loyalty ? 'Edit loyalty program' : 'Create loyalty program'}
                  </Paragraph>
                </div>

                <ProgressBar />
              </div>
            </>
          ) : null}

          {step === 5 ? (
            <div className='w-full flex flex-col p-6 space-y-6'>
              <div className='text-center'>
                <div className='flex justify-center'>
                  <SvgCheckMarkGreen width='50' height='50' />
                </div>
                <Paragraph size='b3'>
                  Loyalty program {loyalty ? 'updated' : 'created'}!
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[400]}>
                  Success! You've {loyalty ? 'updated' : 'created'} your Loyalty Program 🥳
                </Paragraph>
              </div>

              <div className='w-full justify-center items-center max-w-[500px] mx-auto'>
                <LoyaltyCard loyalty={updatedLoyalty} />
              </div>

              <div className='w-full flex items-center justify-center pt-[30px]'>
                <Button
                  variant='primary'
                  className=''
                  disabled={false}
                  loading={saving}
                  size='md'
                  rounded='lg'
                  type='button'
                  onClick={_closeModal}
                >
                  Finish
                </Button>
              </div>
            </div>
          ) : null}

          <div className='w-full p-6 space-y-6'>
            {step === 1 ? (
              <LoyaltyHeaderRow
                title='Define Loyalty Program Rules'
                leadingIcon={
                  <SvgLoyaltyStepOne width="30px" height="30px" />
                }
              >
                <div className='w-full space-y-6'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>Here you can customise your loyalty program to your business needs. You can define how many points a customer earns per visit or per amount they spent.</Paragraph>
                  <Controller
                    control={control}
                    name='requirementType'
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className='w-full flex flex-col space-y-4'>
                          <label htmlFor='isDepositRequired' className='text-b4 text-grey-900 font-semibold'>
                            Define the earning criteria - <span className='text-grey-300'>what action do you want to reward customers for?</span>
                          </label>
                          <div className='w-full flex flex-col gap-2'>
                            {Array.isArray(LOYALTY_OPTIONS) &&
                              LOYALTY_OPTIONS.length
                              ? LOYALTY_OPTIONS.map(
                                (requirementType: {
                                  label: string
                                  value: string
                                }) => {
                                  const label = requirementType?.label?.split("-")
                                  return (
                                    <div
                                      className='flex'
                                      onClick={!loyalty || loyalty?.status !== 'active' ? () => onChange(requirementType?.value) : null}
                                      key={requirementType?.label}
                                    >
                                      <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                        <Checkbox
                                          isChecked={value === requirementType?.value}
                                          disabled={loyalty && loyalty?.status === 'active'}
                                        />
                                        <span className='text-grey-300 text-b4 font-semibold whitespace-nowrap'>
                                          {label[0]} - <span className='font-normal'>{label[1]}</span>
                                        </span>
                                      </div>
                                    </div>
                                  )
                                }
                              )
                              : null}
                          </div>
                        </div>
                      )
                    }}
                  />

                  <div className='w-full space-y-5 pt-[50px]'>
                    <Controller
                      control={control}
                      name='includeSales'
                      render={({ field: { onChange, value } }) => {
                        return (
                          <div
                            className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                            onClick={() => onChange(!value)}
                          >
                            <label
                              className="w-fit text-b5 xl:text-b4 font-normal text-grey-300 subpixel-antialiased"
                              htmlFor="includeSales"
                            >
                              Do you want to include sales in the loyalty tally?
                            </label>
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <ToggleOnly isChecked={value} disabled={loyalty && loyalty?.status === 'active'} />
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                </div>
              </LoyaltyHeaderRow>
            ) : null}

            {step === 2 ? (
              <LoyaltyHeaderRow
                title='Define the value of a point '
                leadingIcon={
                  <SvgLoyaltyStepTwo width="30px" height="30px" />
                }
              >
                <div className='w-full space-y-3'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>Here you can design how much 1 point is worth.</Paragraph>
                  <div className='w-full flex flex-row-reverse gap-x-2 justify-center items-end'>
                    {/* <div className='w-full border border-grey-100 flex items-center px-4 py-3 font-normal rounded-md'>
                      <Paragraph size='b4' color={COLORS.GREY[900]} weight="semiBold">1 point</Paragraph>
                    </div> */}
                    <Controller
                      control={control}
                      name='requirementPoints'
                      rules={{
                        required: ERRORS.SPENDING_THRESHOLD_REQUIRED
                      }}
                      render={({ field }) => (
                        <div className='w-full flex flex-col space-y-2'>
                          <div className='w-full flex items-center border mt-6 xl:mt-8 border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                            <input
                              className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) =>
                                field.onChange(formatNumber(e.target.value))
                              }
                              placeholder='0'
                              disabled={loyalty && loyalty?.status === 'active'}
                            />
                            <div className='w-fit flex items-center space-x-2'>
                              <Paragraph size='b4' color={COLORS.GREY[900]} weight="semiBold">Point(s)</Paragraph>
                            </div>
                          </div>

                          {errors?.requirementPoints?.message && (
                            <FormHelperText variant='error'>
                              {errors.requirementPoints.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                    <span className='text-grey-900 pb-4'> = </span>
                    <Controller
                      control={control}
                      name='requirementValue'
                      rules={{
                        required: ERRORS.SPENDING_THRESHOLD_REQUIRED
                      }}
                      render={({ field }) => (
                        <div className='w-full flex flex-col space-y-2'>
                          <div className='w-full flex items-center border mt-6 xl:mt-8 border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                            <span>{watch('requirementType') === 'client_spends' ? DEFAULT_CURRENCY_CODE : ""}</span>
                            <input
                              className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) =>
                                field.onChange(formatNumber(e.target.value))
                              }
                              placeholder='0'
                              disabled={loyalty && loyalty?.status === 'active'}
                            />
                            <div className='w-fit flex items-center space-x-2'>
                              <select disabled={loyalty && loyalty?.status === 'active'} id="" className='w-fit text-grey-300 flex items-center appearance-none font-normal text-b6 xl:text-b6 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400' value={watch('requirementType')} onChange={handleRequirementChange}>
                                {SHORT_LOYALTY_OPTIONS.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <SvgArrowDown width="10px" height="10px" />
                            </div>
                          </div>

                          {errors?.requirementValue?.message && (
                            <FormHelperText variant='error'>
                              {errors.requirementValue.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </LoyaltyHeaderRow>
            ) : null}

            {step === 4 ? (
              <LoyaltyHeaderRow
                title='Specify any special conditions'
                leadingIcon={
                  <SvgLoyaltyStepThree width="30px" height="30px" />
                }
              >
                <div className='w-full flex flex-col space-y-5'>
                  <div className='w-full space-y-5'>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>How long should rewards be valid for? Do you want rewards to expire after a set number of months or last forever?</Paragraph>
                    <div className='w-full xl:w-1/2 xl:pr-2'>
                      <SelectInput
                        name='validityMonths'
                        id='validityMonths'
                        label='Valid for'
                        control={control}
                        rules={{
                          required: ERRORS.VALID_FOR_REQUIRED
                        }}
                        error={errors.validityMonths}
                        options={[...VALID_FOR, {
                          label: 'Forever',
                          value: '99999'
                        }]}
                        value={watch('validityMonths')}
                        placeholder='Select validity period'
                      />
                    </div>
                  </div>
                </div>
              </LoyaltyHeaderRow>
            ) : null}

            {step === 3 ? (
              <LoyaltyHeaderRow
                title='Customize Rewards'
                leadingIcon={
                  <SvgLoyaltyStepFour width="30px" height="30px" />
                }
              >
                <div className='w-full flex space-x-4'>
                  <div className='w-fit flex space-x-2 items-center bg-green-300 py-2 px-4 rounded-md'>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>
                      {watch('requirementType') === 'client_spends' ? DEFAULT_CURRENCY_CODE + " " : ""}
                      {formatNumber(watch('requirementValue'))}
                      {watch('requirementType') === 'client_spends' ? "" : " Visit(s)"}
                    </Paragraph>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}> = </Paragraph>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>{watch('requirementPoints')} Point</Paragraph>
                  </div>
                </div>
                <div className='w-full flex flex-col space-y-5'>
                  <Paragraph size='b4' weight='semiBold' color={COLORS.GREY[400]}>Here you can customise the rewards your customers earn. You can also define the goal/target points for customers. This is what you want your customers to accomplish.</Paragraph>

                  {Array?.isArray(mileStones) && mileStones?.length
                    ? mileStones.map((mileStone, index) => {
                      return (
                        <div
                          className='w-full flex flex-col space-y-4'
                          key={index}
                        >
                          <div className='w-full flex-col space-y-3'>
                            <div className='w-full flex flex-col space-y-2'>
                              <FormLabel htmlFor='service'>
                                When a customer {watch('requirementType') === "client_visits" ? 'visits' : 'spends'}
                              </FormLabel>
                              <div className='w-full flex gap-x-2 justify-center items-end'>
                                <Controller
                                  control={control}
                                  name={`milestone.customerReaches`}
                                  render={({ field }) => (
                                    <div className='w-full flex flex-col space-y-2'>
                                      <div className='w-full flex items-center border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                                        <input
                                          className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                                          type='text'
                                          {...field}
                                          value={formatNumber(field.value)}
                                          onChange={(e) =>
                                            field.onChange(formatNumber(e.target.value))
                                          }
                                          placeholder='10'
                                          disabled={loyalty && loyalty?.status === 'active'}
                                        />
                                        <div className='w-fit flex items-center space-x-2'>
                                          <Paragraph size='b4' color={COLORS.GREY[400]}>{watch('requirementType') === "client_visits" ? 'times' : DEFAULT_CURRENCY_CODE}</Paragraph>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <span className='text-grey-900 pb-4'> = </span>
                                <div className='w-full border border-grey-100 flex items-center px-4 py-3 font-normal rounded-md'>
                                  <Paragraph size='b4' color={COLORS.GREY[900]} weight="semiBold">{watch('milestone.customerPointsAttained')} Point(s)</Paragraph>
                                </div>
                              </div>
                            </div>
                            <div className='w-full grid grid-cols-2 gap-x-4'>
                              <div className='w-full flex flex-col space-y-2'>
                                <FormLabel>Reward them with</FormLabel>
                                <div className='w-full flex border border-grey-20 rounded-lg h-[50px]'>
                                  <div className='flex h-[50px]'>
                                    <Button
                                      rounded='sm'
                                      size='sm'
                                      type='button'
                                      variant='light'
                                      className={
                                        watch(
                                          `milestone.customerReceivesType`
                                        ) === 'percentage'
                                          ? 'border-2 text-green-500'
                                          : ''
                                      }
                                      onClick={() =>
                                        setValue(
                                          `milestone.customerReceivesType`,
                                          'percentage'
                                        )
                                      }
                                    >
                                      %
                                    </Button>
                                    <Button
                                      rounded='sm'
                                      size='sm'
                                      type='button'
                                      variant='light'
                                      className={
                                        watch(
                                          `milestone.customerReceivesType`
                                        ) === 'fixed_value'
                                          ? 'border-2 text-green-500'
                                          : ''
                                      }
                                      onClick={() =>
                                        setValue(
                                          `milestone.customerReceivesType`,
                                          'fixed_value'
                                        )
                                      }
                                    >
                                      {DEFAULT_CURRENCY}
                                    </Button>
                                  </div>
                                  <Input
                                    name={`milestone.customerReceives`}
                                    label=''
                                    id={`customer-receives`}
                                    type='text'
                                    placeholder='2'
                                    control={control}
                                    className='border-0'
                                  />
                                </div>
                              </div>
                              <Controller
                                control={control}
                                name={`milestone.serviceIds`}
                                render={({
                                  field: { onChange, value }
                                }) => {
                                  return (
                                    <div className='w-full flex flex-col space-y-2'>
                                      <FormLabel htmlFor='service'>
                                        This reward applies to
                                      </FormLabel>
                                      <MultiSelect
                                        selected={value || []}
                                        options={[
                                          ...servicesOptions,
                                          ...productsOptions
                                        ]}
                                        setSelected={onChange}
                                      />
                                    </div>
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : null}
                </div>
              </LoyaltyHeaderRow>
            ) : null}
          </div>
          {step !== 5 ?
            <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
              {step > 1 && step !== 5 ? (
                <div className='p-4'>
                  <Button
                    variant='text'
                    className='text-grey-300'
                    size='md'
                    type='button'
                    onClick={() => setStep(step - 1)}
                  >
                    &#60; Back to previous step
                  </Button>
                </div>
              ) : (
                <span></span>
              )}
              <div className='w-full xl:w-1/2 flex justify-end p-4 gap-4'>
                <Button
                  variant='text'
                  className={`text-red-500`}
                  size='sm'
                  type='button'
                  onClick={handleCancelOrDelete}
                >
                  {loyalty && loyalty?.status === 'active' ? 'Delete' : 'Cancel'}
                </Button>
                {step > 0 && step !== 4 ? (
                  <Button
                    variant='primary'
                    size='md'
                    rounded='lg'
                    onClick={moveToNextStep}
                    type='button'

                  >
                    Continue
                  </Button>
                ) : null}
                {step === 4 ? (
                  <Button
                    disabled={saving}
                    loading={saving} variant='primary' size='md' rounded='lg' type='submit'>
                    Proceed
                  </Button>
                ) : null}
              </div>
            </div> : null}
        </form>
      </Modal>
      <PromptLoyaltyDeletion
        isVisible={isDeleteLoyaltyModalVisible}
        closeModal={handleDeletionLoyaltyModal}
      />
      <PromptLoyaltyFinalDeletion
        isVisible={isDeleteLoyaltyFinalModalVisible}
        closeModal={handleDeletionLoyaltyFinalModal}
      />
    </>
  )
}

const Promos = ({
  openPage,
  closePage,
  addToast
}: {
  openPage: boolean
  closePage: () => void
  addToast: (toast: ToastProps) => void
}) => {
  const [promo, setPromo] = useState<Promo | null>(null)
  const [duplicatePromo, setDuplicatePromo] = useState<Promo | null>(null)
  const [promoHeadings, setPromoHeadings] = useState(PROMO_HEADINGS)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const actions = {
    addPromo: canPerformAction(PERMISSION_CONSTANTS?.promos?.add),
    editPromo: canPerformAction(PERMISSION_CONSTANTS?.promos?.edit),
    deletePromo: canPerformAction(PERMISSION_CONSTANTS?.promos?.delete)
  }
  const {
    loading: isLoading,
    data: promosData,
    refetch
  } = useGetPromos(debouncedSearchQuery);
  const promos = useMemo(() => promosData?.promos?.promos || [], [promosData])
  const topPerformingPromo = useMemo(() => promosData?.promos?.topPerformingPromo, [promosData])
  const {
    isVisible: isAddPromosModalVisible,
    openModal: openAddPromoModal,
    closeModal: closeAddPromosModal
  } = useModal()
  const {
    isVisible: isViewPromoModalVisible,
    openModal: openViewPromoModal,
    closeModal: closeViewPromoModal
  } = useModal()
  const {
    isVisible: isDeletePromoDialogModalVisible,
    openModal: openDeletePromoDialogModal,
    closeModal: closeDeletePromoDialogModal
  } = useModal()

  useEffect(() => {
    refetch()
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (openPage) {
      openAddPromoModal()
    }
  }, [openPage])

  const handleCloseAddPromoModal = () => {
    setPromo(null)
    setDuplicatePromo(null)
    closeAddPromosModal()
    refetch()
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleCloseViewPromoModal = (action?: string) => {
    if (action === 'addPromo') {
      closeViewPromoModal()
      openAddPromoModal()
      return;
    }
    setPromo(null)
    closeViewPromoModal()
    closePage()
  }

  const handleCloseDeletePromoModal = () => {
    setPromo(null)
    closeDeletePromoDialogModal()
    closePage()
    refetch()
  }

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in productHeadings then update the show
    const updatedHeadings = promoHeadings.map((heading) => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setPromoHeadings(updatedHeadings)
  }

  const PromoCardShimmer = () => {
    return (
      <div style={{ gap: '2.5rem' }} className='flex flex-wrap w-full'>
        {[1, 2, 3].map((item, index) => (
          <div
            style={{ maxWidth: '200px' }}
            className='w-full flex flex-col p-6 space-y-6 border border-grey-100 rounded-md'
            key={`pso${index}`}
          >
            <Skeleton width='40px' height='40px' />
            <Skeleton width='80%' height='1.5rem' />
            <Skeleton width='70%' height='1rem' />
            <Skeleton width='4rem' height='2rem' />
          </div>
        ))}
      </div>
    )
  }

  const getIfPromoHasStarted = (startAt: string) => {
    if (!startAt) return false
    const [datePart, timePart] = startAt.split('T');
    const [timeWithoutOffset] = timePart.split('Z');
    const startDate = new Date(`${datePart}T${timeWithoutOffset}`)
    const currentDate = new Date()

    return currentDate >= startDate
  }

  const generatePromosTableData = (
    promos: Promo[],
    tableHeadings: TableFilterProps,
    countryCode?: string
  ) => {
    return promos?.map?.((promo) => {
      const promoHasStarted = getIfPromoHasStarted(promo?.startAt)
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        const applicableItems = [
          promo?.isServiceApplicable && 'Services',
          promo?.isProductApplicable && 'Products',
          promo?.isPackageApplicable && 'Vouchers'
        ]
          .filter(Boolean)
          .join(', ')
        if (heading.show) {
          switch (heading.value) {
            case 'code':
              rowData[heading.value] = (
                <Paragraph size='b5' className='uppercase'>
                  {promo?.title}
                </Paragraph>
              )
              break
            case 'appliedTo':
              rowData[heading.value] = `
                ${applicableItems}
              `
              break
            case 'locations':
              rowData[heading.value] = `
                ${promo?.salons?.map((salon) => salon?.branchName)}
              `
              break
            case 'created':
              rowData[heading.value] = getDayMonthAndNumberAndTimeFromDate(
                promo?.createdAt
              )
              break
            case 'saleCount':
              rowData[heading.value] = promo.promotionsApplied
              break
            case 'promoValue':
              rowData[heading.value] = `${promo?.promoType === 'percentage'
                ? `${promo?.promoValue}%`
                : `${formatInToPrice(promo?.promoValue)}`
                }`
              break
            case 'status':
              rowData[heading.value] = promo ? (
                <Pill variant={!promo.isActive ? 'light' : !promoHasStarted ? 'light' : 'success'}>
                  {!promo.isActive ? 'Inactive' : !promoHasStarted ? 'Inactive' : 'Active'}
                </Pill>
              ) : (
                <Paragraph size='b5'> - </Paragraph>
              )
              break
          }
        }
      })
      return rowData
    })
  }

  const openPromo = (id: number, action = 'view') => {
    const promo = promos[id]

    switch (action) {
      case 'view':
        setPromo(promo)
        openViewPromoModal()
        break
      case 'delete':
        setPromo(promo)
        openDeletePromoDialogModal()
        break
      case 'edit':
        setPromo(promo)
        openAddPromoModal()
        break
      default:
        setDuplicatePromo(promo)
        openAddPromoModal()
        break
    }
  }

  const getPromosContent = () => {
    if (isLoading) {
      return (
        <div className='flex flex-col space-y-6 p-6'>
          <PromoCardShimmer />
        </div>
      )
    } else if (Array.isArray(promos) && promos.length > 0) {
      const tableClientsData = generatePromosTableData(
        promos,
        promoHeadings,
        'NG'
      )
      return (
        <div className='flex flex-col'>
          <div className='w-full px-6 py-4 justify-between items-center hidden border-b border-grey-50 xl:flex'>
            <Heading variant='h2' size='h11' weight='semiBold'>
              Promo list
            </Heading>
            {actions?.addPromo ?
              <Button
                variant='primary'
                size='sm'
                disabled={false}
                loading={false}
                type='button'
                rounded='md'
                fontSize='b5'
                onClick={openAddPromoModal}
              >
                Create New Promo
              </Button> : null}
          </div>

          <div
            style={{
              gap: '1.5rem',
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
              display: 'grid'
            }}
            className='grid grid-cols-1 w-full p-6 border-b border-grey-50'
          >
            <div
              style={{ maxWidth: '360px', minHeight: '120px' }}
              className='w-full flex p-4 gap-4 cursor-pointer border border-grey-100 rounded-md'
            >
              <SvgPercentageBox width='32px' height='32px' />
              <div className='flex flex-col h-full w-full justify-between'>
                <div className='flex flex-col'>
                  <Paragraph
                    size='b5'
                    color={COLORS.GREY[300]}
                    weight='semiBold'
                  >
                    Total promos
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    Total number of promos
                  </Paragraph>
                </div>

                <Paragraph size='b1' color={COLORS.GREY[900]} weight='semiBold'>
                  {promos.length}
                </Paragraph>
              </div>
            </div>
            <div
              style={{ maxWidth: '360px', minHeight: '120px' }}
              className='w-full flex p-4 gap-4 cursor-pointer border border-grey-100 rounded-md'
            >
              <SvgPercentageBox width='32px' height='32px' />
              <div className='flex flex-col h-full w-full justify-between'>
                <div className='flex flex-col'>
                  <Paragraph
                    size='b5'
                    color={COLORS.GREY[300]}
                    weight='semiBold'
                  >
                    Active promos
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    Number of active promos{' '}
                  </Paragraph>
                </div>

                <Paragraph size='b1' color={COLORS.GREY[900]} weight='semiBold'>
                  {promos.filter((x) => x.isActive === true).length}
                </Paragraph>
              </div>
            </div>
            <div
              style={{ maxWidth: '360px', minHeight: '120px', gap: '1rem' }}
              className='w-full flex p-4 cursor-pointer border border-grey-100 rounded-md'
            >
              <SvgArrowBox width='32px' height='32px' />
              <div className='flex flex-col h-full w-full justify-between'>
                <div className='flex flex-col'>
                  <Paragraph
                    size='b5'
                    color={COLORS.GREY[300]}
                    weight='semiBold'
                  >
                    Top performing promo
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    Promo that has been used the most
                  </Paragraph>
                </div>

                <Paragraph
                  size='b1'
                  color={COLORS.GREY[900]}
                  weight='semiBold'
                  className='uppercase'
                >
                  {topPerformingPromo ? topPerformingPromo?.title : '-'}
                </Paragraph>
              </div>
            </div>
          </div>

          <div className='flex flex-col xl:flex-row py-4 px-8 space-x-4'>
            <div
              style={{ width: '50%' }}
              className='w-full xl:w-6/12 flex items-center space-x-4'
            >
              <SearchTerm
                placeholder='Search by name, date'
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Menu as='div' className='relative inline-block text-left'>
                <div>
                  <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
                    <SvgFilter width='20px' height='20px' />
                    <span className='hidden xl:inline'>Filter</span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
                    <div className='flex flex-col p-4 space-y-4'>
                      {Array.isArray(promoHeadings) &&
                        promoHeadings.length &&
                        promoHeadings.map((heading, index) => (
                          <Label
                            className='flex space-x-2 items-center cursor-pointer'
                            key={index}
                            htmlFor={heading.value}
                            onClick={() =>
                              handleHeadingCheckboxChange(heading.value)
                            }
                          >
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <FullTable
            headers={formatTableHeadersFilterArray(promoHeadings)}
            mobileHeaders={MOBILE_PROMO_HEADINGS}
            rows={tableClientsData}
            onClick={openPromo}
            tableOptions={{
              edit: actions?.editPromo,
              delete: actions?.deletePromo,
              duplicate: actions?.addPromo,
              view: true
            }}
          />
        </div>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div
          style={{ maxWidth: '450px' }}
          className='flex flex-col justify-center items-center space-y-6 w-full p-12'
        >
          <div className='w-full max-w-[350px] flex justify-center items-center'>
            <SvgPromoEmpty width="100%" height="100%" />
          </div>
          <Heading variant='h2' size='h3'>
            Promos
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You don't have any promo yet.
          </Paragraph>
          {actions?.addPromo ?
            <Button
              variant='primary'
              size='lg'
              disabled={false}
              loading={false}
              type='button'
              rounded='lg'
              onClick={openAddPromoModal}
            >
              Create New Promo
            </Button> : null}
        </div>
      </div>
    )
  }

  return (
    <div>
      {getPromosContent()}
      <ViewPromoModal
        isVisible={isViewPromoModalVisible}
        closeModal={handleCloseViewPromoModal}
        promo={promo}
        actions={actions}
        addToast={addToast}
      />
      <DeletePromoDialog
        isVisible={isDeletePromoDialogModalVisible}
        closeModal={handleCloseDeletePromoModal}
        promo={promo}
        addToast={addToast}
      />
      <AddPromo
        isVisible={isAddPromosModalVisible}
        closeModal={handleCloseAddPromoModal}
        promo={promo}
        duplicatePromo={duplicatePromo}
        actions={actions}
        addToast={addToast}
      />
    </div>
  )
}

type ViewPromoModalProps<T extends string> = {
  promo: Promo
  closeModal: (action?: string) => void
  isVisible: boolean
  addToast?: (toast: ToastProps) => void
  actions: PermissionActionProp<T>
}

const ViewPromoModal = ({
  isVisible,
  closeModal,
  addToast,
  promo,
  actions
}: ViewPromoModalProps<'editPromo' | 'deletePromo'>) => {

  const copyDiscountCode = () => {
    copyTextToClipboard(`${promo?.title}`)
    addToast &&
      addToast({
        variant: 'success',
        message: 'Discount code copied to clipboard'
      })
  }
  return (
    <>
      <Modal show={isVisible} closeModal={closeModal} variant='right'>
        <div
          style={{ marginTop: '40px', marginBottom: '40px' }}
          className='w-full flex flex-col space-y-10 relative'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div
              style={{ padding: '1.25rem 1.5rem 1.25rem 1.5rem' }}
              className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'
            >
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={() => closeModal()}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={() => closeModal()}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Promo
              </Paragraph>
            </div>
          </div>

          <div
            style={{ marginTop: '50px' }}
            className='flex flex-row justify-between items-center px-6 py-4'
          >
            <div className=''>
              <Heading variant='h1' size='h9' weight='semiBold'>
                Promo details
              </Heading>
              <Paragraph size='b4' color={COLORS.GREY[300]}>
                Find information about promo here
              </Paragraph>
            </div>
            {promo && actions?.editPromo ? (
              <div className=''>
                <Button
                  variant='primary'
                  size='sm'
                  type='button'
                  rounded='md'
                  onClick={() => closeModal('addPromo')}
                  disabled={!promo?.isActive}
                >
                  Edit promo
                </Button>
              </div>
            ) : null}
          </div>

          {promo ? (
            <>
              <div
                style={{
                  gap: '1.5rem',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'
                }}
                className='grid grid-cols-1 md:grid-cols-3 w-full gap-6 p-6'
              >
                <div
                  style={{ maxWidth: '360px', minHeight: '120px', gap: '1rem' }}
                  className='w-full xl:max-w-[360px] min-h-[120px] flex flex-col p-4 gap-4 cursor-pointer border border-grey-100 rounded-md'
                >
                  <SvgShoppingTagCircle width='32px' height='32px' />
                  <div className='flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      Total sales
                    </Paragraph>
                    <Paragraph size='b5' color={COLORS.GREY[900]}>
                      {formatInToPrice(promo.totalSales)}
                    </Paragraph>
                  </div>
                </div>
                <div
                  style={{ maxWidth: '360px', minHeight: '120px', gap: '1rem' }}
                  className='w-full flex flex-col p-4  cursor-pointer border border-grey-100 rounded-md'
                >
                  <SvgPercentageDarkGreenCircle width='32px' height='32px' />
                  <div className='flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      Promotions applied
                    </Paragraph>
                    <Paragraph size='b5' color={COLORS.GREY[900]}>
                      {promo.promotionsApplied}
                    </Paragraph>
                  </div>
                </div>
                <div
                  style={{ maxWidth: '360px', minHeight: '120px', gap: '1rem' }}
                  className='w-full xl:max-w-[360px] min-h-[120px] flex flex-col p-4 gap-4 cursor-pointer border border-grey-100 rounded-md'
                >
                  <SvgDarkUserCircle width='32px' height='32px' />
                  <div className='flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      Total clients
                    </Paragraph>
                    <Paragraph size='b5' color={COLORS.GREY[900]}>
                      {promo.totalClients}
                    </Paragraph>
                  </div>
                </div>
              </div>
              <div className='w-full px-6'>
                <div className='w-full flex flex-col space-y-2 p-4 border border-grey-50 rounded-md'>
                  <div className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Apply to
                    </Paragraph>
                    <div className='w-full md:max-w-[50%] flex flex-col items-end gap-2'>
                      {Array?.isArray(promo?.services) &&
                        promo?.services?.length ? (
                        <Paragraph size='b4' color={COLORS.GREY[900]}>
                          <span className='font-semibold'>Services:</span>{' '}
                          {promo?.services
                            ?.map((service) => service?.name)
                            .join(', ') || ''}
                        </Paragraph>
                      ) : null}
                      {Array?.isArray(promo?.products) &&
                        promo?.products?.length ? (
                        <Paragraph size='b4' color={COLORS.GREY[900]}>
                          <span className='font-semibold'>Products:</span>{' '}
                          {promo?.products
                            ?.map((product) => product?.name)
                            .join(', ') || ''}
                        </Paragraph>
                      ) : null}
                      {Array?.isArray(promo?.packages) &&
                        promo?.packages?.length ? (
                        <Paragraph size='b4' color={COLORS.GREY[900]}>
                          <span className='font-semibold'>Vouchers:</span>{' '}
                          {promo?.packages
                            ?.map((salonPackage) => salonPackage.name)
                            .join(', ') || ''}
                        </Paragraph>
                      ) : null}
                    </div>
                  </div>

                  <div
                    style={{ flexDirection: 'row' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Created
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>
                      {getDayMonthAndNumberAndTimeFromDate(
                        promo?.createdAt,
                      )}
                    </Paragraph>
                  </div>

                  <div
                    style={{ flexDirection: 'row', gap: '0.5rem' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Start date
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>
                      {getDayMonthAndNumberAndTimeFromDate(promo?.startAt)}
                    </Paragraph>
                  </div>

                  <div
                    style={{ flexDirection: 'row', gap: '0.5rem' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      End date
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>
                      {getDayMonthAndNumberAndTimeFromDate(promo?.endAt)}
                    </Paragraph>
                  </div>

                  <div
                    style={{ flexDirection: 'row', gap: '0.5rem' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Promotion value
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>{`${promo?.promoType === 'percentage'
                      ? `${promo?.promoValue}%`
                      : `${formatInToPrice(promo?.promoValue || 0)}`
                      }`}</Paragraph>
                  </div>

                  <div
                    style={{ flexDirection: 'row', gap: '0.5rem' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Promotion limits
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>
                      {promo?.redemptionLimit}
                    </Paragraph>
                  </div>

                  <div
                    style={{ flexDirection: 'row', gap: '0.5rem' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Discount code
                    </Paragraph>
                    <div
                      className='flex items-center gap-2 cursor-pointer'
                      onClick={copyDiscountCode}
                    >
                      <Paragraph
                        size='b4'
                        color={COLORS.GREY[900]}
                        className='uppercase'
                      >
                        {promo?.title}
                      </Paragraph>
                      <SvgIonCopyOutline width='16px' height='16px' />
                    </div>
                  </div>

                  <div
                    style={{ flexDirection: 'row', gap: '0.5rem' }}
                    className='w-full flex flex-col md:flex-row justify-between md:items-center gap-2 p-3 bg-grey-10'
                  >
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Locations
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[900]}>
                      {promo?.salons
                        ?.map((salon) => salon?.branchName)
                        .join(', ') || '-'}
                    </Paragraph>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  )
}

type DeletePromoProps = {
  promo?: Promo
  isVisible: boolean
  closeModal: () => void
  addToast: (toast: ToastProps) => void
}

type AddPromoProps<T extends string> = DeletePromoProps & {
  duplicatePromo?: Promo
  addToast: (toast: ToastProps) => void
  actions: PermissionActionProp<T>
}
const AddPromo = ({ promo, isVisible, closeModal, duplicatePromo, actions, addToast }: AddPromoProps<'deletePromo'>) => {
  const { getUserData } = useUserCache();
  const [typeSign, setTypeSign] = useState<string | null>('-')
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreatePromoInput>({
    defaultValues: {
      isPackageApplicable: true,
      isServiceApplicable: true,
      isProductApplicable: true
    }
  })
  const userData = getUserData();
  const {
    loading: createPromoIsLoading,
    createPromo
  } = useCreatePromo()
  const {
    deletePromo
  } = useDeletePromo()
  const resetForm = () => {
    setValue('title', '')
    setValue('description', '')
    setValue('promoValue', 0)
    setValue('promoType', '')
    setValue('startDate', '')
    setValue('startTime', '')
    setValue('endDate', '')
    setValue('endTime', '')
    setValue('isPackageApplicable', false)
    setValue('isServiceApplicable', false)
    setValue('isProductApplicable', false)
    setValue('requiredExpenditure', null)
    setValue('redemptionLimit', 1)
    setValue('salons', [])
    setValue('services', [])
    setValue('products', [])
    setValue('packages', [])
  }

  const createPromo_ = async (input: CreatePromoInput) => {
    const startAt = `${input.startDate}T${input.startTime}`
    const endAt = `${input.endDate}T${input.endTime}`
    if (!input.startDate && !input.endDate) return
    if (!promo) {
      const validateDates = checkPromoDatesValidity(startAt, endAt)
      if (!validateDates?.isValid) {
        addToast({
          message: validateDates?.error,
          variant: 'error'
        })
        return
      }
    }
    type Option = {
      label: string
      value: string
    }
    const salons = (JSON.parse(JSON.stringify(input.salons)) as Option[])?.map(
      (x) => x.value
    )
    const packages =
      input?.isPackageApplicable && input?.packages?.length
        ? (JSON.parse(JSON.stringify(input.packages)) as Option[]).map(
          (x) => x.value
        )
        : []
    const products =
      input?.isProductApplicable && input?.products?.length
        ? (JSON.parse(JSON.stringify(input.products)) as Option[]).map(
          (x) => x.value
        )
        : []
    const services =
      input?.isServiceApplicable && input?.services?.length
        ? (JSON.parse(JSON.stringify(input.services)) as Option[]).map(
          (x) => x.value
        )
        : []

    const payload = {
      ...input,
      title: input.title.replace(/ /g, '').toLowerCase(),
      salons,
      id: promo ? promo.id : null,
      redemptionLimit: Number(input.redemptionLimit) || 1,
      requiredExpenditure: Number(input.requiredExpenditure),
      promoValue: Number(input.promoValue.toString().replace(/,/g, '')),
      packages,
      products,
      services,
      isPackageApplicable: input.isPackageApplicable
        ? input.isPackageApplicable
        : false,
      isServiceApplicable: input.isServiceApplicable
        ? input.isServiceApplicable
        : false,
      isProductApplicable: input.isProductApplicable
        ? input.isProductApplicable
        : false
    }

    createPromo({
      variables: {
        input: { ...payload }
      }
    }).then(({ data }) => {
      const { createPromo } = data;
      if (createPromo?.status === 201) {
        addToast({ variant: 'success', message: 'Promo saved successfully' })
        handleClose()
      } else {
        addToast({ variant: 'error', message: createPromo.errors[0].message })
      }
    })
  }

  const businessBranchOptions = userData?.business?.salons?.map((x) => ({ label: x.branchName, value: x.id }))
  const servicesOptions = userData?.business?.services?.map((x) => ({ label: x.name, value: x.id })) || []
  const productsOptions = userData?.business?.products?.map((x) => ({ label: x.name, value: x.id })) || []
  const packagesOptions = userData?.business?.packages?.filter((voucher) => voucher?.isDiscountApplicable)?.map((x) => ({ label: x.name, value: x.id })) || []

  const LOYALTY_TYPE_REQUIREMENTS = [
    {
      value: 'percentage',
      label: 'Percentage'
    },
    {
      value: 'fixed',
      label: 'Fixed Amount'
    }
  ]

  const handleCancelOrDelete = async () => {
    if (promo) {

      deletePromo({
        variables: {
          input: { id: promo?.id }
        }
      }).then(({ data }) => {
        const { deletePromo } = data;
        if (deletePromo?.status === 200) {
          addToast({ variant: 'success', message: 'Promo Deleted Successfully' })
          handleClose()
        }

        if (deletePromo?.errors?.length) {
          addToast({ variant: 'error', message: deletePromo?.errors[0]?.message })
        }
      })
    } else {
      handleClose()
    }
  }

  useEffect(() => {
    if (watch('promoType')) {
      if (watch('promoType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY)
      } else if (watch('promoType') === 'percentage') {
        setTypeSign('%')
      }
    } else {
      setTypeSign('-')
    }
  }, [watch('promoType')])

  const updateValues = ({
    promoData,
    isDuplicate = false
  }: {
    promoData: Promo
    isDuplicate?: boolean
  }) => {
    setValue('title', promoData?.title)
    setValue('description', promoData.description)
    setValue('promoValue', promoData.promoValue)
    setValue('promoType', promoData.promoType)
    setValue('isPackageApplicable', promoData.isPackageApplicable || false)
    setValue('isServiceApplicable', promoData.isServiceApplicable || false)
    setValue('isProductApplicable', promoData.isProductApplicable || false)

    if (!isDuplicate) {
      setValue('startDate', convertFullDateStringToDate(promoData.startAt))
      setValue('startTime', convertFullDateStringToTime(promoData.startAt))
      if (promoData?.isActive) {
        setValue('endTime', convertFullDateStringToTime(promoData.endAt))
        setValue('endDate', convertFullDateStringToDate(promoData.endAt))
      }
    }

    setValue('redemptionLimit', promoData?.redemptionLimit || 1)
    setValue('requiredExpenditure', promoData?.requiredExpenditure)
    setValue(
      'salons',
      formatSalonBranchesToSelectField(promoData?.salons || []) as []
    )
    setValue(
      'packages',
      formatBusinessPackagesToSelectField(promoData.packages || []) as []
    )
    setValue(
      'services',
      formatBusinessServicesToSelectField(promoData.services || []) as []
    )
    setValue(
      'products',
      formatBusinessProductsToSelectField(promoData.products || []) as []
    )
  }

  useEffect(() => {
    if (promo && isVisible) {
      updateValues({ promoData: promo })
    } else if (duplicatePromo && isVisible) {
      updateValues({ promoData: duplicatePromo, isDuplicate: true })
    } else {
      setValue('redemptionLimit', 1)
      setValue('isPackageApplicable', true)
      setValue('isServiceApplicable', true)
      setValue('isProductApplicable', true)
      const user = getUserData()
      const business = user?.business
      if (business?.salons && business?.salons?.length > 0)
        setValue(
          'salons',
          formatSalonBranchesToSelectField(business?.salons) as []
        )
      if (business?.packages && business?.packages?.length > 0 && !promo && !duplicatePromo)
        setValue(
          'packages',
          formatBusinessPackagesToSelectField(business?.packages) as []
        )
      if (business?.services && business?.services?.length > 0 && !promo && !duplicatePromo)
        setValue(
          'services',
          formatBusinessServicesToSelectField(business?.services) as []
        )
      if (business?.products && business?.products?.length > 0 && !promo && !duplicatePromo)
        setValue(
          'products',
          formatBusinessProductsToSelectField(business?.products) as []
        )
    }
  }, [isVisible, promo, duplicatePromo])

  const handleClose = () => {
    resetForm()
    closeModal()
  }

  return (
    <Modal show={isVisible} closeModal={handleClose} variant='right'>
      <form
        style={{ marginTop: '80px', marginBottom: '80px' }}
        onSubmit={handleSubmit(createPromo_)}
        className='w-full relative'
        autoComplete='off'
      >
        <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
          <div
            style={{ padding: '1.25rem 1.5rem 1.25rem 1.5rem' }}
            className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'
          >
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
        </div>

        <div className='w-full flex flex-col px-6 py-4 space-y-6'>
          <Heading variant='h1' size='h9' weight='semiBold'>
            {promo ? 'Edit Promo' : 'Add new promo'}
          </Heading>
          <Paragraph size='b4'>
            {promo
              ? 'Modify existing promo for your business'
              : 'Create a new promo for your business'}
          </Paragraph>
        </div>

        <div className='w-full p-6 space-y-6'>
          <Input
            name='title'
            label='Title'
            coLabel='(Code the customer applies)'
            id='loyalty-title'
            className='uppercase'
            type='text'
            placeholder='E.g SPLICE10'
            control={control}
            rules={{
              required: ERRORS.PROMO_NAME_REQUIRED
            }}
            error={errors?.title}
          />
          <Input
            name='description'
            label='Description'
            id='description'
            type='text'
            placeholder='A brief description of this service'
            control={control}
          />

          <div className='w-full flex items-center gap-x-4'>
            <SelectInput
              name='promoType'
              id='promo-type'
              label='Promo value'
              control={control}
              rules={{
                required: ERRORS.PROMO_TYPE_REQUIRED
              }}
              value={watch('promoType')}
              error={errors.promoType}
              options={LOYALTY_TYPE_REQUIREMENTS}
              placeholder='Select Requirement'
            />
            <span className='text-b5 text-grey-900 mt-6 xl:mt-8'>
              {typeSign}
            </span>
            <Controller
              control={control}
              name='promoValue'
              rules={{
                required: ERRORS.SPENDING_THRESHOLD_REQUIRED
              }}
              render={({ field }) => (
                <>
                  <input
                    className='w-full flex items-center appearance-none border mt-6 xl:mt-7 border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                    type='text'
                    {...field}
                    value={formatNumber(field.value)}
                    onChange={(e) =>
                      field.onChange(formatNumber(e.target.value))
                    }
                    placeholder='2'
                  />
                  {errors?.promoValue?.message && (
                    <FormHelperText variant='error'>
                      {errors.promoValue.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </div>

          <div className='w-full flex gap-x-4'>
            <Input
              name='startDate'
              label='Start Date'
              id='start-at'
              type='date'
              placeholder='DD/MM/YY'
              control={control}
              disabled={promo ? true : false}
              rules={{
                required: ERRORS.DATE_REQUIRED
              }}
              error={errors?.startDate}
            />
            <Input
              name='startTime'
              label='Start Time'
              id='start-time'
              type='time'
              control={control}
              disabled={promo ? true : false}
              rules={{
                required: ERRORS.TIME_REQUIRED
              }}
              error={errors.startTime}
            />
          </div>

          <div className='w-full flex gap-x-4'>
            <Input
              name='endDate'
              label='End Date'
              id='end-at'
              type='date'
              placeholder='DD/MM/YY'
              control={control}
              rules={{
                required: ERRORS.DATE_REQUIRED
              }}
              error={errors.endDate}
            />
            <Input
              name='endTime'
              label='End Time'
              id='end-time'
              type='time'
              control={control}
              rules={{
                required: ERRORS.TIME_REQUIRED
              }}
              error={errors.endTime}
            />
          </div>

          <div className='space-y-2'>
            <label className='text-b6 xl:text-b4 text-grey-900 font-normal w-full block subpixel-antialiased'>
              Apply to
            </label>
            <div className='flex space-x-4'>
              <Controller
                control={control}
                name='isServiceApplicable'
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className='flex' onClick={() => onChange(!value)}>
                      <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                        <Checkbox isChecked={value} />
                        <span className='text-grey-900 whitespace-nowrap'>
                          Services
                        </span>
                      </div>
                    </div>
                  )
                }}
              />
              <Controller
                control={control}
                name='isProductApplicable'
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className='flex' onClick={() => onChange(!value)}>
                      <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                        <Checkbox isChecked={value} />
                        <span className='text-grey-900 whitespace-nowrap'>
                          Products
                        </span>
                      </div>
                    </div>
                  )
                }}
              />
              <Controller
                control={control}
                name='isPackageApplicable'
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className='flex' onClick={() => onChange(!value)}>
                      <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                        <Checkbox isChecked={value} />
                        <span className='text-grey-900 whitespace-nowrap'>
                          Vouchers
                        </span>
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'row' }}
            className='w-full flex flex-col xl:flex-row gap-4 items-center'
          >
            {watch('isServiceApplicable') ? (
              <div className='w-full xl:w-60 flex flex-col xl:flex-row gap-4'>
                <Controller
                  control={control}
                  name='services'
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const errorMessage = errors.services?.message
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>Add Service(s)</FormLabel>
                        <MultiSelect
                          // @ts-expect-error Type definition of selected
                          selected={value || []}
                          options={servicesOptions as []}
                          setSelected={onChange}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: watch('services') ? 'Add a service' : false
                  }}
                />
              </div>
            ) : null}

            {watch('isProductApplicable') ? (
              <div className='w-full xl:w-60 flex flex-col xl:flex-row gap-4'>
                <Controller
                  control={control}
                  name='products'
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const errorMessage = errors?.products?.message
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>Add Product(s)</FormLabel>
                        <MultiSelect
                          // @ts-expect-error Type definition of selected
                          selected={value || []}
                          options={productsOptions as []}
                          setSelected={onChange}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: watch('products') ? 'Add a product' : false
                  }}
                />
              </div>
            ) : null}

            {watch('isPackageApplicable') ? (
              <div className='w-full xl:w-60 flex flex-col xl:flex-row gap-4'>
                <Controller
                  control={control}
                  name='packages'
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const errorMessage = errors?.packages?.message
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>Add Voucher(s)</FormLabel>
                        <MultiSelect
                          // @ts-expect-error Type definition of selected
                          selected={value || []}
                          options={packagesOptions as []}
                          setSelected={onChange}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: watch('packages') ? 'Add a voucher' : false
                  }}
                />
              </div>
            ) : null}
          </div>

          <div>
            <div className='w-full flex items-center gap-x-4'>
              <Input
                name='requiredExpenditure'
                label="Client's spend"
                coLabel='(amount a client needs to spend)'
                id='client-spend'
                type='number'
                placeholder=''
                control={control}
                error={errors.requiredExpenditure}
              />

              <Input
                name='redemptionLimit'
                label="Client's use limit"
                coLabel='(number of times it can be used)'
                id='client-spend'
                type='number'
                defaultValue={1}
                placeholder=''
                control={control}
                error={errors.redemptionLimit}
              />
            </div>
          </div>

          <div className='w-full flex flex-col space-y-2'>
            <div className='w-full xl:w-1/2 flex flex-col xl:flex-row gap-4'>
              <Controller
                control={control}
                name='salons'
                render={({
                  field: { onChange, value },
                  formState: { errors }
                }) => {
                  const errorMessage = errors?.services?.message
                  return (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='service'>Add Location(s)</FormLabel>
                      <MultiSelect
                        // @ts-expect-error Type definition of selected
                        selected={value || []}
                        options={businessBranchOptions as []}
                        setSelected={onChange}
                      />
                      {errorMessage && (
                        <FormHelperText variant='error'>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </div>
                  )
                }}
                rules={{
                  required: 'Add at least one location'
                }}
              />
            </div>
          </div>

          <div className='flex flex-row-reverse'>
            <Button
              variant='primary'
              className='w-52'
              disabled={createPromoIsLoading}
              loading={createPromoIsLoading}
              size='md'
              rounded='lg'
            >
              {promo ? 'Save' : 'Create'}
            </Button>
            <Button
              variant='text'
              className={`text-red-500 w-52`}
              size='sm'
              type='button'
              onClick={handleCancelOrDelete}
            >
              {promo && actions?.deletePromo ? 'Delete' : 'Cancel'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const DeletePromoDialog = ({ promo, isVisible, closeModal, addToast }: DeletePromoProps) => {
  const {
    loading,
    deletePromo
  } = useDeletePromo()

  const handleDelete = () => {
    deletePromo({
      variables: {
        input: { promoId: promo?.id }
      }
    }).then(({ data }) => {
      const { deletePromo } = data;
      if (deletePromo?.status === 200) {
        addToast({ variant: 'success', message: 'Promo Deleted Successfully' })
        closeModal()
      }

      if (deletePromo?.errors?.length) {
        addToast({ variant: 'error', message: deletePromo?.errors[0]?.message })
      }
    })
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Promo
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {promo?.title}? This means that
                this promo will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={handleDelete}
                disabled={loading}
                loading={loading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={closeModal}
                disabled={loading}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const LoyaltyHeaderRow = ({ title, children, leadingIcon }: CollapseRowProps) => {
  return (
    <div className="w-full flex flex-col space-y-3">
      <div
        className="flex items-center justify-between cursor-pointer bg-grey-10 px-4 py-2 rounded-tl-md rounded-tr-md"
      >
        <div className='flex items-center space-x-3'>
          <div className='flex bg-green-300/10 w-[32px] h-[32px] justify-center items-center rounded-full'>
            {leadingIcon}
          </div>
          <Paragraph size='b4'>
            {title}
          </Paragraph>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-3 py-6 space-y-4">
        {children}
      </div>
    </div>
  );
}

type ClientActvityModalProps = {
  isVisible: boolean
  closeModal: () => void
  client: Client
}

const ViewClientActivityModal = ({
  isVisible,
  closeModal,
  client
}: ClientActvityModalProps) => {
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const salonId = salon?.id;
  const generateActivityLoyaltyTableData = (activities: ClientRewardHistory[], countryCode?: string) => {
    return activities?.map?.((activity) => {
      return {
        date: getNumberMonthAndYearFromDate(activity?.transactionDate),
        activityDone: activity?.description,
        pointsEarned: () => {
          if (activity?.pointsEarned?.toString().includes('-')) {
            return (
              <span className="text-red-500 text-b4">{activity?.pointsEarned}</span>
            )
          }
          return (
            <span className="text-green-500 text-b4">+{activity?.pointsEarned}</span>
          )
        },
        pointsBalance: activity?.cumulativePointBalance,
      };
    });
  }

  const {
    loading: isLoading,
    data: salonClientData,
    refetch,
  } = useGetClient({
    clientId: client?.id,
    salonId
  })
  const clientRecord = useMemo(() => salonClientData?.salonClient, [salonClientData])

  const showInitialLoadingShimmer = isLoading && !clientRecord;
  const activityLoyaltyTableData = generateActivityLoyaltyTableData(clientRecord?.clientRewardHistories || [], 'NG')
  return (
    <Modal show={isVisible} closeModal={closeModal} variant='right'>
      <div className='relative'>
        <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgChevronLeft width='24px' height='24px' />
            </Button>
            <Paragraph size='b3' weight='bold'>
              {client?.firstName} Activity
            </Paragraph>
          </div>
        </div>

        <div
          style={{ marginTop: '70px' }}
          className='w-full max-w-5xl flex flex-col space-y-6 h-fit p-6'
        >
          {showInitialLoadingShimmer ? (
            <CustomerDashboardShimmer />
          ) : (
            <div className='flex flex-col space-y-4'>
              <div className='w-full xl:max-w-[400px] flex flex-col justify-between md:h-[135px] p-4 border border-grey-50 rounded-md'>
                <div className='w-full flex flex-col'>
                  <Heading variant='h1' size='h11' weight='bold' color={COLORS.GREEN['300']}>Total rewards</Heading>
                  <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']} className='hidden md:flex'>Number of milestones reached</Paragraph>
                </div>

                <Paragraph size='b1' weight='normal' color={COLORS.GREY['900']}>{clientRecord?.clientMilestones?.length || 0}</Paragraph>
              </div>

              <Table headers={formatTableHeadersFilterArray(ACTVITY_LOYALTY_TABLE_HEADERS)} mobileHeaders={MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS} rows={activityLoyaltyTableData} hasBorder={true} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

const Membership = ({
  openPage,
  closePage,
}: {
  openPage: boolean
  closePage: () => void
}) => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [membership, setMembership] = useState<MembershipProps | null>(null)
  const { getUserData } = useUserCache()
  const user: User = getUserData();
  const {
    data,
    refetch: refetchTotalMemberships
  } = useGetMemberships(user?.business?.bookingUrlIdentifier)
  const totalMemberships = useMemo(() => {
    if (data) {
      return data?.business?.memberships
    }
  }, [data])
  const {
    data: activeMemberships,
    loading,
    refetch
  } = useActiveMemberships({
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end')
  })
  const memberships = useMemo(() => {
    if (activeMemberships) {
      return activeMemberships?.activeMemberships
    }
  }, [activeMemberships])
  const {
    data: purchaseData,
    refetch: refetchTopPurchases
  } = useMembershipPurchase({
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end')
  });
  const topPurchaseMembership = useMemo(() => {
    if (purchaseData) {
      if (Array?.isArray(purchaseData?.membershipPurchases) && purchaseData?.membershipPurchases?.length) {
        return purchaseData?.membershipPurchases?.reduce((prev, current) => {
          return prev?.purchaseCount > current?.purchaseCount ? prev : current
        })
      }
    }
  }, [purchaseData])

  const {
    data: archivedData,
    refetch: refetchArchivedData
  } = useArchiveMemberships({
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end')
  })
  const archivedMemberships = useMemo(() => {
    if (archivedData) {
      return archivedData?.archivedMemberships
    }
  }, [archivedData])
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const { toast, addToast } = useToast()
  const [activityAndRewardActiveTab, setActivityAndRewardActiveTab] = useState(MEMBERSHIP_ACTIVITY_TAB.MEMBERSHIPS)
  const {
    isVisible: isAddMembershipModalVisible,
    closeModal: closeAddMembershipModal,
    openModal: openAddMembershipModal
  } = useModal()

  const {
    isVisible: isViewMembershipModalVisible,
    closeModal: closeViewMembershipModal,
    openModal: openViewMembershipModal
  } = useModal()

  const {
    isVisible: isUpgradeMembershipModalVisible,
    closeModal: closeUpgradeMembershipModal,
    openModal: openUpgradeMembershipModal
  } = useModal()

  const {
    isVisible: isPauseMembershipModalVisible,
    closeModal: closePauseMembershipModal,
    openModal: openPauseMembershipModal
  } = useModal()

  const {
    isVisible: isDeleteMembershipModalVisible,
    closeModal: closeDeleteMembershipModal,
    openModal: openDeleteMembershipModal
  } = useModal()

  const {
    closeModal: closeRestoreMembershipDialogModal,
    isVisible: isRestoreMembershipModalVisible,
    openModal: openRestoreMembershipDialogModal
  } = useModal()

  useEffect(() => {
    if (salon?.id, user?.business?.bookingUrlIdentifier) {
      refetch()
      refetchTotalMemberships()
      refetchTopPurchases()
    }
  }, [refetch, salon?.id, user?.business?.bookingUrlIdentifier])

  useEffect(() => {
    if (activityAndRewardActiveTab === MEMBERSHIP_ACTIVITY_TAB.ARCHIVED) {
      refetchArchivedData()
    }
    if (activityAndRewardActiveTab === MEMBERSHIP_ACTIVITY_TAB.MEMBERSHIPS) {
      refetch()
      refetchTotalMemberships()
    }
  }, [activityAndRewardActiveTab, refetch, refetchArchivedData])

  const handleCloseMembershipModal = () => {
    closeAddMembershipModal()
    closePage()
    refetch()
    refetchTotalMemberships()
  }

  const initiateCloseViewMembershipModal = () => {
    closeViewMembershipModal()
    setMembership(null)
  }

  const initiateCloseUpgradeMembershipModal = () => {
    closeUpgradeMembershipModal()
    setMembership(null)
    refetch()
  }

  const initiateClosePauseMembershipModal = () => {
    closePauseMembershipModal()
    setMembership(null)
    refetch()
    refetchArchivedData()
    refetchTotalMemberships()
  }

  const initiateCloseDeleteMembershipModal = () => {
    closeDeleteMembershipModal()
    setMembership(null)
    refetch()
  }

  const initiateCloseRestoreMembershipModal = () => {
    closeRestoreMembershipDialogModal()
    setMembership(null)
    refetch()
    refetchArchivedData()
    refetchTotalMemberships()
  }

  const generateMembershipsTableData = (
    memberships: MembershipProps[],
    tableHeadings: TableFilterProps,
  ) => {
    return memberships?.map?.((membership) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = membership?.name
              break
            case 'amount':
              rowData[heading.value] = formatInToPrice(membership?.fee)
              break
            case 'reward':
              rowData[heading.value] = `${membership?.rewardPercentage}%`
              break
            case 'services':
              rowData[heading.value] = membership?.services?.length ? limitString(membership?.services?.map((service) => service?.name).join(', '), 20, true) : '-'
              break
            case 'validityPeriod':
              rowData[heading.value] = `${membership?.validityPeriod} month(s)`
              break
            case 'clients':
              rowData[heading.value] = membership?.clients?.length ? membership?.clients?.map((client) => client?.firstName).join(', ') : '-'
              break
          }
        }
      })
      return rowData
    })
  }

  const generateArchivedMembershipsTableData = (
    memberships: MembershipProps[],
    tableHeadings: TableFilterProps,
  ) => {
    return memberships?.map?.((membership) => {
      const rowData = {} // Using 'any' here, you can replace it with a more specific type
      tableHeadings.forEach((heading) => {
        if (heading.show) {
          switch (heading.value) {
            case 'name':
              rowData[heading.value] = membership?.name
              break
            case 'amount':
              rowData[heading.value] = formatInToPrice(membership?.fee)
              break
            case 'reward':
              rowData[heading.value] = `${membership?.rewardPercentage}%`
              break
            case 'services':
              rowData[heading.value] = membership?.services?.length ? limitString(membership?.services?.map((service) => service?.name).join(', '), 20, true) : '-'
              break
            case 'validityPeriod':
              rowData[heading.value] = `${membership?.validityPeriod} month(s)`
              break
            case 'clients':
              rowData[heading.value] = membership?.clients?.length ? membership?.clients?.map((client) => client?.firstName).join(', ') : '-'
              break
            case 'cta':
              rowData[heading.value] = (
                <div className='flex space-x-4'>
                  <Button
                    variant='light'
                    size='xs'
                    rounded='lg'
                    fontSize='b5'
                    fontWeight='semiBold'
                    className='px-5 py-2'
                  >
                    Restore
                  </Button>
                </div>
              )
              break
          }
        }
      })
      return rowData
    })
  }

  const tableMembershipsData = generateMembershipsTableData(
    totalMemberships,
    MEMBERSHIP_TABLE_HEADERS,
  )

  const tableArchivedMembershipsData = generateArchivedMembershipsTableData(
    archivedMemberships,
    ARCHIVED_MEMBERSHIP_TABLE_HEADERS,
  )

  const restoreMembership = (id: number) => {
    const membership = archivedMemberships[id]
    if (!membership) return;
    setMembership(membership)
    openRestoreMembershipDialogModal()
  }

  const getContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col space-y-6 p-6'>
          <LoyaltyCardShimmer />
        </div>
      )
    } else if (Array?.isArray(memberships) && memberships?.length || Array?.isArray(archivedMemberships) && archivedMemberships?.length || Array?.isArray(totalMemberships) && totalMemberships?.length) {
      return (
        <div className='flex flex-col space-y-6'>
          <div className='justify-between items-center hidden xl:flex border-b border-grey-50 py-6 px-4'>
            <Heading variant='h2' size='h11' weight='semiBold'>
              Membership
            </Heading>
            <div className='flex space-x-2'>
              <Button
                variant='primary'
                size='sm'
                disabled={false}
                loading={false}
                type='button'
                rounded='md'
                fontSize='b5'
                onClick={openAddMembershipModal}
              >
                Add new membership
              </Button>
            </div>
          </div>
          <div className='flex flex-col w-full'>
            <div className="w-full flex p-4 border-y border-grey-100">
              <div className='w-full flex flex-col lg:flex-row gap-3'>
                <div className='w-full flex flex-col gap-3 border border-grey-100 bg-grey-50/20 p-4 min-h-[131px] max-w-[350px] rounded-md'>
                  <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                    <SvgMember width="32px" height="32px" />
                  </span>

                  <div className='w-full flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                      Total memberships
                    </Paragraph>
                    <Paragraph size='b1' weight='semiBold' className=''>
                      {totalMemberships?.length || 0}
                    </Paragraph>
                  </div>
                </div>
                {activityAndRewardActiveTab === MEMBERSHIP_ACTIVITY_TAB.MEMBERSHIPS ?
                  <div className='w-full flex flex-col gap-3 border border-grey-100 bg-grey-50/20 p-4 h-min-[131px] max-w-[400px] rounded-md'>
                    <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                      <SvgMember width="32px" height="32px" />
                    </span>

                    <div className='w-full flex flex-col'>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                        Active memberships
                      </Paragraph>
                      <Paragraph size='b1' weight='semiBold' className=''>
                        {memberships?.length || 0}
                      </Paragraph>
                    </div>
                  </div> :
                  <div className='w-full flex flex-col gap-3 border border-grey-100 bg-grey-50/20 p-4 h-min-[131px] max-w-[400px] rounded-md'>
                    <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                      <SvgMember width="32px" height="32px" />
                    </span>

                    <div className='w-full flex flex-col'>
                      <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                        Archived memberships
                      </Paragraph>
                      <Paragraph size='b1' weight='semiBold' className=''>
                        {archivedMemberships?.length || 0}
                      </Paragraph>
                    </div>
                  </div>}
                <div className='w-full flex flex-col gap-3 border border-grey-100 bg-grey-50/20 p-4 h-min-[131px] max-w-[400px] rounded-md'>
                  <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                    <SvgMember width="32px" height="32px" />
                  </span>

                  <div className='w-full flex flex-col'>
                    <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold' className=''>
                      Best performing membership
                    </Paragraph>
                    {topPurchaseMembership ?
                      <Paragraph size='b1' weight='semiBold' className='text-wrap'>
                        {topPurchaseMembership?.membership?.name} <span className="text-b4">
                          {`(bought ${topPurchaseMembership?.purchaseCount || 0} times)`}
                        </span>
                      </Paragraph> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <div className='w-full flex border-l border-t border-r border-grey-50'>
                {Array?.isArray(MEMBERSHIP_ACTIVITY_TABS) && MEMBERSHIP_ACTIVITY_TABS?.length ? MEMBERSHIP_ACTIVITY_TABS.map((tab, index) => {
                  return (
                    <Button
                      key={index}
                      variant={activityAndRewardActiveTab === tab.key ? 'light' : 'text'}
                      className={`${activityAndRewardActiveTab === tab.key ? 'text-green-300 border-b border-green-300 font-semibold' : 'text-grey-300'} w-fit focus:outline-none`}
                      size='md'
                      onClick={() => setActivityAndRewardActiveTab(tab.key)}
                    >
                      {tab.title}
                    </Button>
                  )
                }) : null}
              </div>
              {activityAndRewardActiveTab === MEMBERSHIP_ACTIVITY_TAB.MEMBERSHIPS ?
                <>
                  <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 lg:px-6'>
                    <Paragraph size='b4' weight='semiBold' className=''>
                      Overview
                    </Paragraph>
                    <div className='flex justify-between px-4 py-3 border-t border-grey-50'>
                      <CalendarPicker {...{ selectedDates, setSelectedDates }} />
                    </div>
                  </div>
                  <FullTable
                    headers={MEMBERSHIP_TABLE_HEADERS}
                    mobileHeaders={MEMBERSHIP_TABLE_HEADERS_MOBILE}
                    rows={tableMembershipsData}
                    onClick={openMembershipPage}
                    tableOptions={{
                      view: false,
                      edit: true,
                      archive: true,
                      delete: true,
                      duplicate: false,
                    }}
                  />
                </>
                : (
                  <>
                    <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 lg:px-6'>
                      <Paragraph size='b4' weight='semiBold' className=''>
                        Overview
                      </Paragraph>
                      <div className='flex justify-between px-4 py-3 border-t border-grey-50'>
                        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
                      </div>
                    </div>
                    <FullTable
                      headers={ARCHIVED_MEMBERSHIP_TABLE_HEADERS}
                      mobileHeaders={MEMBERSHIP_TABLE_HEADERS_MOBILE}
                      rows={tableArchivedMembershipsData}
                      onClick={restoreMembership}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      )
    }
    const featuredList = [{
      name: "Exclusive VIP Perks",
      description: "Give members access to special benefits like priority bookings, exclusive discounts, or complimentary treatments."
    }, {
      name: "Budget-Friendly Options",
      description: "Clients save on their favorite services over time, making premium beauty care more affordable."
    }, {
      name: "Priority Booking Access",
      description: "Give members the advantage of early booking privileges for high-demand services and appointments."
    }, {
      name: "Upsell Potential",
      description: "Encourage clients to explore more services by offering higher discounts on premium packages."
    }]
    return (
      <div className="w-full xl:border-none bg-white py-12 px-9">
        <div className="w-full flex flex-col-reverse lg:flex-row lg:justify-between items-center gap-6">
          <div className="w-full flex flex-col space-y-4">
            {/* <span className="text-green-300 font-semibold rounded-full py-2 px-4 border border-green-150 w-fit shadow-md">
              Addon
            </span> */}
            <div className="w-full flex flex-col space-y-2">
              <Heading
                variant="h1"
                size="h9"
                weight="semiBold"
                color={COLORS.GREY[900]}
              >
                Attract and reward loyal clients with Memberships
              </Heading>
              <Paragraph size="b3" color={COLORS.GREY[300]}>
                Allow clients to pay a flat fee to unlock exclusive discounts, creating a win-win for both your business and your clients.
              </Paragraph>
            </div>

            <div className="w-full flex flex-col space-y-4">
              {featuredList && featuredList?.length
                ? featuredList?.map((list) => {
                  return (
                    <div className="w-full flex items-start space-x-4">
                      <span className="text-b4">✨️</span>
                      <div className="flex flex-col space-y-2">
                        {list?.name && list?.name !== '' ? (
                          <Paragraph size="b4">{list?.name}</Paragraph>
                        ) : null}
                        <Paragraph size="b4" color={COLORS.GREY[300]}>
                          {list?.description}
                        </Paragraph>
                      </div>
                    </div>
                  );
                })
                : null}
            </div>

            <div className="w-1/2">
              <Button
                variant='primary'
                size='lg'
                disabled={false}
                loading={false}
                type='button'
                rounded='lg'
                onClick={openAddMembershipModal}
              >
                Get started
              </Button>
            </div>
          </div>
          <div className="w-full max-w-[350px] flex justify-center items-center">
            <SvgMembership width="100%" height="100%" />
          </div>
        </div>
      </div>
    )
  }

  const openMembershipPage = (id: number, action?: string) => {
    if (!totalMemberships?.length) return;
    const membership = totalMemberships[id];
    if (!membership) return;

    setMembership(membership)
    if (action === 'edit') {
      openUpgradeMembershipModal()
    } else if (action === 'archive') {
      openPauseMembershipModal()
    } else if (action === 'delete') {
      openDeleteMembershipModal()
    } else {
      openViewMembershipModal()
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      {getContent()}
      <AddMembership
        isVisible={isAddMembershipModalVisible}
        closeModal={handleCloseMembershipModal}
        addToast={addToast}
      />
      <ViewMembershipModal
        isVisible={isViewMembershipModalVisible}
        closeModal={initiateCloseViewMembershipModal}
        addToast={addToast}
        membership={membership}
      />
      <UpgradeMembership
        isVisible={isUpgradeMembershipModalVisible}
        closeModal={initiateCloseUpgradeMembershipModal}
        addToast={addToast}
        membership={membership}
      />
      <PauseMembership
        isVisible={isPauseMembershipModalVisible}
        closeModal={initiateClosePauseMembershipModal}
        addToast={addToast}
        membership={membership}
      />
      <DeleteMembership
        isVisible={isDeleteMembershipModalVisible}
        closeModal={initiateCloseDeleteMembershipModal}
        addToast={addToast}
        membership={membership}
      />
      <RestoreMembership
        isVisible={isRestoreMembershipModalVisible}
        closeModal={initiateCloseRestoreMembershipModal}
        addToast={addToast}
        membership={membership}
      />
    </>
  )
}

export type AddMembershipProps = {
  membership?: MembershipProps | null
  isVisible: boolean
  closeModal: () => void
  addToast: (toast: ToastProps) => void
}

type MembershipInput = {
  name: string;
  fee: number;
  rewardPercentage: number;
  serviceIds: MultiSelectOption[];
  validityPeriod: number;
  clientIds: MultiSelectOption[];
}

const AddMembership = ({ isVisible, closeModal, addToast }: AddMembershipProps) => {
  const { getUserData } = useUserCache()
  const { getSalonData } = useSalonCache()
  const user = getUserData()
  const salon = getSalonData();
  const [after, setAfter] = useState<string | null>(null)
  const [before, setBefore] = useState(null)
  const [clientsOptions, setClientOptions] = useState<MultiSelectOption[]>([])
  const {
    data: servicesData,
    refetch: refetchBusinessServices
  } = useBusinessServices({
    businessId: user?.business?.id
  });
  const servicesOptions = useMemo(() => {
    return servicesData?.businessServices?.map((x) => ({ label: x.name, value: x.id })) || []
  }, [servicesData]) as MultiSelectOption[]

  useEffect(() => {
    if (user?.business?.id) {
      refetchBusinessServices()
    }
  }, [user?.business?.id, refetchBusinessServices])
  const [step, setStep] = useState(1)
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<MembershipInput>({
  })

  const {
    loading,
    createMembership
  } = useCreateMembership()

  const formatClientsToSelectField = (clients: Client[]): MultiSelectOption[] => {
    return Array.isArray(clients) && clients.length
      ? clients.map((client) => ({
        label: `${client.firstName} ${client.lastName} - ${client.phone}`,
        value: client.id
      }))
      : []; // Return an empty array if clients is not valid or empty
  };

  const {
    data: clientsData,
    refetch,
  } = useGetClients({
    salonId: salon?.id,
    after,
    before,
    first: 50
  })

  useMemo(() => {
    if (clientsData && clientsData?.salonClients?.nodes) {
      const options = formatClientsToSelectField(clientsData?.salonClients?.nodes) || [];
      const combinedNodes = [...clientsOptions, ...options]
        .reduce((acc: MultiSelectOption[], client: MultiSelectOption) => {
          if (!acc.some(existingClient => existingClient.value === client.value)) {
            acc.push(client);
          }
          return acc;
        }, []);
      setClientOptions(sortOptions([...combinedNodes]));

      if (clientsData?.salonClients?.pageInfo?.hasNextPage) {
        setAfter(clientsData?.salonClients?.pageInfo?.endCursor)
        refetch({
          after: clientsData?.salonClients?.pageInfo?.endCursor
        })
      }
    }
  }, [clientsData])

  const resetForm = () => {
    setValue('name', '')
    setValue('fee', null)
    setValue('rewardPercentage', null)
    setValue('serviceIds', [])
    setValue('validityPeriod', null)
    setValue('clientIds', [])
    setStep(1)
  }

  const _closeModal = () => {
    closeModal()
    resetForm()
  }

  const moveToNextStep = () => {
    setStep(step + 1)
  }

  const ProgressBar = () => {
    const steps = Array.from({ length: 3 }, (_, i) => i + 1);
    return (
      <div className='w-full flex items-center justify-center p-4'>
        {steps.map((_, index) => {
          const isActive = step >= index + 1
          return (
            <div key={index} className='w-full flex items-center'>
              <div className={`w-[20px] h-[20px] rounded-full ${isActive ? 'bg-green-300' : 'bg-grey-100'}`}></div>
              <div className={`w-full border-2 ${isActive ? 'border-green-300' : 'border-grey-100'}`}></div>
            </div>
          )
        })}
        <div className='w-fit flex items-center'>
          <div className={`w-[20px] h-[20px] rounded-full ${step >= 5 ? 'bg-green-300' : 'bg-grey-100'}`}></div>
        </div>
      </div>
    )
  }

  const handleCancelOrDelete = async () => {
    _closeModal()
  }

  const checkIfRequiredFieldsAreFilledAsync = (input: MembershipInput) => {
    if (!input.name) {
      addToast({
        message: 'Membership name is required',
        variant: 'error'
      })
      return false
    }
    if (!input.fee) {
      addToast({
        message: 'Membership fee is required',
        variant: 'error'
      })
      return false
    }
    if (!input.rewardPercentage) {
      addToast({
        message: 'Reward percentage is required',
        variant: 'error'
      })
      return false
    }
    if (!input.validityPeriod) {
      addToast({
        message: 'Validity period is required',
        variant: 'error'
      })
      return false
    }
    if (!input?.serviceIds?.length) {
      addToast({
        message: 'At least one service is required',
        variant: 'error'
      })
      return false
    }
    return true
  }

  const addMembershipReward = (input: MembershipInput) => {
    // membershipData
    const checkIfRequiredFieldsAreFilled = checkIfRequiredFieldsAreFilledAsync(input);
    if (!checkIfRequiredFieldsAreFilled) return;

    const payload = {
      name: input.name,
      fee: parseFloat(input?.fee?.toString().replace(/,/g, "")),
      rewardPercentage: parseFloat(input?.rewardPercentage?.toString().replace(/,/g, "")),
      validityPeriod: parseFloat(input?.validityPeriod?.toString().replace(/,/g, "")),
      serviceIds: input?.serviceIds?.length ? input.serviceIds.map((service) => service.value) : [],
      clientIds: input?.clientIds?.length ? input.clientIds.map((client) => client.value) : [],
    }

    createMembership({
      variables: {
        input: { ...payload, salonId: salon?.id }
      }
    }).then(({ data }) => {
      if (data?.createMembership?.status === 200 || data?.createMembership?.status === 201) {
        addToast({
          message: 'Membership created successfully',
          variant: 'success'
        })
        _closeModal()
      }

      if (data?.createMembership?.errors?.length) {
        addToast({
          message: data?.createMembership?.errors[0]?.message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  }
  return (
    <Modal
      show={isVisible}
      closeModal={_closeModal}
      variant='right'
    >
      <form
        onSubmit={handleSubmit(addMembershipReward)}
        className='w-full relative my-[80px]'
        autoComplete='off'
      >
        {/* progress bar */}
        {step !== 5 ? (
          <>
            <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
              <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
                <Button
                  variant='text'
                  size='none'
                  type='button'
                  className='w-fit'
                  fontSize='b4'
                  onClick={_closeModal}
                >
                  <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
                </Button>
              </div>
              <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
                <Button
                  variant='icon'
                  size='square'
                  type='button'
                  rounded='md'
                  className='w-fit'
                  fontSize='b4'
                  onClick={_closeModal}
                >
                  <SvgChevronLeft width='24px' height='24px' />
                </Button>
                <Paragraph size='b3' weight='bold'>
                  Create membership
                </Paragraph>
              </div>
            </div>

            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full xl:w-1/2 flex flex-col space-y-6 px-6'>
                <Heading variant='h1' size='h9' weight='semiBold'>
                  Create membership
                </Heading>
                <Paragraph size='b4'>
                  Create a rewarding program for your customers
                </Paragraph>
              </div>

              <ProgressBar />
            </div>
          </>
        ) : null}

        <div className='w-full p-6 space-y-6'>
          {step === 1 ? (
            <LoyaltyHeaderRow
              title='Define Membership Rules'
              leadingIcon={
                <SvgLoyaltyStepOne width="30px" height="30px" />
              }
            >
              <div className='w-full space-y-6'>
                <Paragraph size='b4' color={COLORS.GREY[300]}>Here you can customise your memberships to your business needs. What should be the membership name?</Paragraph>
                <div className="w-full flex flex-col space-y-2">
                  <Input
                    name="name"
                    label="Enter membership name"
                    id="name"
                    type="text"
                    placeholder="Enter membership name"
                    control={control}
                    rules={{
                      required: ERRORS.FIRST_NAME_REQUIRED,
                    }}
                    error={errors.name}
                  />
                </div>
                <div className="w-full flex gap-x-4">
                  <div className='w-full flex flex-col space-y-2'>
                    <FormLabel htmlFor='fee'>
                      Membership fee (How much is the customer paying)
                    </FormLabel>
                    <div className='w-full flex gap-x-2 justify-center items-end'>
                      <Controller
                        control={control}
                        name="fee"
                        render={({ field }) => (
                          <div className='w-full flex flex-col space-y-2'>
                            <div className='w-full flex items-center border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                              <div className='w-fit px-1 flex items-center space-x-2'>
                                <Paragraph size='b4' color={COLORS.GREY[400]}>{DEFAULT_CURRENCY}</Paragraph>
                              </div>
                              <input
                                className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                                type='text'
                                {...field}
                                value={formatNumber(field.value)}
                                onChange={(e) =>
                                  field.onChange(formatNumber(e.target.value))
                                }
                                placeholder='10'
                              />
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </LoyaltyHeaderRow>
          ) : null}
          {step === 2 ? (
            <LoyaltyHeaderRow
              title='Specify any special conditions'
              leadingIcon={
                <SvgLoyaltyStepThree width="30px" height="30px" />
              }
            >
              <div className='w-full flex flex-col space-y-6'>
                {/* <Paragraph size='b4' color={COLORS.GREY[300]}>What services should the membership apply to? How long should it be valid for?</Paragraph> */}
                <div className="w-full flex flex-col space-y-4">
                  <div className='flex items-center space-x-3'>
                    <Paragraph size='b4' color={COLORS.GREEN[300]} weight="bold">
                      {watch('name')} membership
                    </Paragraph>
                  </div>
                  <div className='w-full flex flex-col md:flex-row gap-4'>
                    <div className='w-full md:w-1/2 flex flex-col space-y-2'>
                      <FormLabel htmlFor='service'>
                        How much discount should a customer be rewarded?
                      </FormLabel>
                      <div className='w-full flex gap-x-2 justify-center items-end'>
                        <Controller
                          control={control}
                          name="rewardPercentage"
                          render={({ field }) => (
                            <div className='w-full flex flex-col space-y-2'>
                              <div className='w-full flex items-center border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                                <input
                                  className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                                  type='text'
                                  {...field}
                                  value={formatNumber(field.value)}
                                  onChange={(e) =>
                                    field.onChange(formatNumber(e.target.value))
                                  }
                                  placeholder='10'
                                />
                                <div className='w-fit flex items-center space-x-2'>
                                  <Paragraph size='b4' color={COLORS.GREY[400]}>%</Paragraph>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <SelectInput
                        name='validityPeriod'
                        id='validityPeriod'
                        label='How long should it be valid for?'
                        control={control}
                        rules={{
                          required: ERRORS.VALID_FOR_REQUIRED
                        }}
                        error={errors.validityPeriod}
                        options={[...VALID_FOR, {
                          label: 'Forever',
                          value: '9999'
                        }]}
                        value={watch('validityPeriod')}
                        placeholder='Select validity period'
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-col md:flex-row gap-4 pt-8">
                  <Controller
                    control={control}
                    name="serviceIds"
                    render={({
                      field: { onChange, value }
                    }) => {
                      return (
                        <div className='w-full flex flex-col space-y-2'>
                          <FormLabel htmlFor='service'>
                            What services should the discount apply to?
                          </FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={[
                              ...servicesOptions
                            ]}
                            setSelected={onChange}
                          />
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </LoyaltyHeaderRow>
          ) : null}
          {step === 3 ? (
            <LoyaltyHeaderRow
              title='Assign Membership'
              leadingIcon={
                <SvgLoyaltyStepFour width="30px" height="30px" />
              }
            >
              <div className='w-full space-y-6'>
                <Paragraph size='b4' color={COLORS.GREY[300]}>Who are the customers that have the particular membership?</Paragraph>
                <div className="w-full flex flex-col md:flex-row gap-4">
                  <Controller
                    control={control}
                    name="clientIds"
                    render={({
                      field: { onChange, value }
                    }) => {
                      return (
                        <div className='w-full flex flex-col space-y-2'>
                          <FormLabel htmlFor='clientIds'>
                            Select customers who have this membership
                          </FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={[
                              ...clientsOptions
                            ]}
                            setSelected={onChange}
                          />
                        </div>
                      )
                    }}
                  />
                </div>
              </div>
            </LoyaltyHeaderRow>
          ) : null}
        </div>
        {step !== 5 ?
          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            {step > 1 && step !== 5 ? (
              <div className='p-4'>
                <Button
                  variant='text'
                  className='text-grey-300'
                  size='md'
                  type='button'
                  onClick={() => setStep(step - 1)}
                >
                  &#60; Back to previous step
                </Button>
              </div>
            ) : (
              <span></span>
            )}
            <div className='w-full xl:w-1/2 flex justify-end p-4 gap-4'>
              <Button
                variant='text'
                className={`text-red-500`}
                size='sm'
                type='button'
                onClick={handleCancelOrDelete}
              >
                Cancel
              </Button>
              {step > 0 && step !== 3 ? (
                <Button
                  variant='primary'
                  size='md'
                  rounded='lg'
                  onClick={moveToNextStep}
                  type='button'

                >
                  Continue
                </Button>
              ) : null}
              {step === 3 ? (
                <Button variant='primary' size='md' rounded='lg' type='submit' disabled={loading} loading={loading}>
                  Proceed
                </Button>
              ) : null}
            </div>
          </div> : null}
      </form>
    </Modal>
  )
}

const ViewMembershipModal = ({ isVisible, closeModal, addToast, membership }: AddMembershipProps) => {
  const [servicesShowAll, setServicesShowAll] = useState(false)
  const getVoucherPillsForServices = (services: Service[]) => {
    if (Array.isArray(services) && services.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {services.map(voucherService => {
          return <Pill variant='light' rounded='lg' className='py-2 px-2' key={voucherService?.id}>{voucherService?.name}</Pill>
        })}
      </div>)
    }

    return "-"
  }
  const nameMap = {
    "name": "Membership Name",
    "servicesIncluded": "Services applied to",
    "amount": "Amount",
    "reward": "Reward",
    "validityPeriod": "Valid for",
    "dateCreated": "Date created"
  };
  const membershipMap = {
    "name": membership?.name,
    "servicesIncluded": servicesShowAll ? getVoucherPillsForServices(membership?.services) : getVoucherPillsForServices(membership?.services?.slice(0, 5)),
    "amount": formatInToPrice(membership?.fee),
    "reward": `${membership?.rewardPercentage}%`,
    "validityPeriod": `${membership?.validityPeriod} month(s)`,
    "dateCreated": getNumberMonthAndYearFromDate(membership?.createdAt),
  };

  const purchasedRows = membership?.clients?.map((client: Client) => {
    return {
      date: getNumberMonthAndYearFromDate(client?.clientMembership?.purchaseDate),
      client: client?.firstName,
      location: client?.clientMembership?.salon?.branchName,
      lastUse: '-'
    }
  })
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>Membership details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Membership details</Heading>
              <Paragraph size="b4">Find information about package here</Paragraph>
            </div>
          </div>
          <div className="w-full flex border border-grey-50 rounded-md p-4 flex-col space-y-3">
            {Object.entries(membershipMap).map(([key, value]) => (

              nameMap[key] && value !== null && (
                <div key={key} className="px-2 flex justify-between items-start space-x-4 bg-grey-10">
                  <div className="flex flex-col space-y-1 p-2">
                    <Paragraph size="b4" color="grey-300" className="text-left">
                      {nameMap[key]}
                    </Paragraph>
                  </div>
                  <div className="flex flex-col space-y-1 items-end max-w-[60%]">
                    <Paragraph size="b4" color={COLORS.GREY[900]} weight="semiBold" className="text-right">
                      {value}
                    </Paragraph>
                    {key === 'servicesIncluded' ?
                      <div className="w-full py-2">
                        {servicesShowAll ?
                          <span
                            className='p-0 text-green-300 underline cursor-pointer font-bold text-b6'
                            onClick={() => setServicesShowAll(false)}
                            role='button'
                          >
                            Click to view less
                          </span> : <span
                            className='p-0 text-green-300 underline cursor-pointer font-bold text-b6'
                            onClick={() => setServicesShowAll(true)}
                            role='button'
                          >
                            Click to view more
                          </span>}
                      </div> : null}
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
        <div className="pt-10">
          <FullTable
            headers={MEMBERSHIP_HISTORY}
            mobileHeaders={MEMBERSHIP_HISTORY_MOBILE}
            rows={purchasedRows}
          />
        </div>
      </div>
    </Modal >
  )
}

const UpgradeMembership = ({ isVisible, closeModal, addToast, membership }: AddMembershipProps) => {
  const {
    loading,
    upgradeMembership
  } = useUpgradeMembership()
  const { getUserData } = useUserCache()
  const { getSalonData } = useSalonCache()
  const user = getUserData()
  const salon = getSalonData();
  const [after, setAfter] = useState<string | null>(null)
  const [clientsOptions, setClientOptions] = useState<MultiSelectOption[]>([])
  const {
    data: servicesData,
    refetch: refetchBusinessServices
  } = useBusinessServices({
    businessId: user?.business?.id
  });
  const servicesOptions = useMemo(() => {
    return servicesData?.businessServices?.map((x) => ({ label: x.name, value: x.id })) || []
  }, [servicesData]) as MultiSelectOption[]

  useEffect(() => {
    if (user?.business?.id) {
      refetchBusinessServices()
    }
  }, [user?.business?.id, refetchBusinessServices])
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<MembershipInput>({
  })

  const formatClientsToSelectField = (clients: Client[]): MultiSelectOption[] => {
    return Array.isArray(clients) && clients.length
      ? clients.map((client) => ({
        label: `${client.firstName} ${client.lastName} - ${client.phone}`,
        value: client.id
      }))
      : []; // Return an empty array if clients is not valid or empty
  };

  const {
    data: clientsData,
    refetch,
  } = useGetClients({
    salonId: salon?.id,
    after,
    before: null,
    first: 50
  })

  useMemo(() => {
    if (clientsData && clientsData?.salonClients?.nodes) {
      const options = formatClientsToSelectField(clientsData?.salonClients?.nodes) || [];
      const combinedNodes = [...clientsOptions, ...options]
        .reduce((acc: MultiSelectOption[], client: MultiSelectOption) => {
          if (!acc.some(existingClient => existingClient.value === client.value)) {
            acc.push(client);
          }
          return acc;
        }, []);
      setClientOptions(sortOptions([...combinedNodes]));

      if (clientsData?.salonClients?.pageInfo?.hasNextPage) {
        setAfter(clientsData?.salonClients?.pageInfo?.endCursor)
        refetch({
          after: clientsData?.salonClients?.pageInfo?.endCursor
        })
      }
    }
  }, [clientsData])

  const resetForm = () => {
    setValue('name', '')
    setValue('fee', null)
    setValue('rewardPercentage', null)
    setValue('serviceIds', [])
    setValue('validityPeriod', null)
    setValue('clientIds', [])
  }

  useEffect(() => {
    if (membership) {
      setValue('name', membership?.name)
      setValue('fee', membership?.fee)
      setValue('rewardPercentage', membership?.rewardPercentage)
      setValue('serviceIds', membership?.services?.map((service) => ({ label: service.name, value: service.id })))
      setValue('validityPeriod', membership?.validityPeriod)
      setValue('clientIds', formatClientsToSelectField(membership?.clients))
    }
  }, [membership, setValue])

  const _closeModal = () => {
    closeModal()
    resetForm()
  }

  const checkIfRequiredFieldsAreFilledAsync = (input: MembershipInput) => {
    if (!input.name) {
      addToast({
        message: 'Membership name is required',
        variant: 'error'
      })
      return false
    }
    if (!input.fee) {
      addToast({
        message: 'Membership fee is required',
        variant: 'error'
      })
      return false
    }
    if (!input.rewardPercentage) {
      addToast({
        message: 'Reward percentage is required',
        variant: 'error'
      })
      return false
    }
    if (!input.validityPeriod) {
      addToast({
        message: 'Validity period is required',
        variant: 'error'
      })
      return false
    }
    if (!input.serviceIds.length) {
      addToast({
        message: 'At least one service is required',
        variant: 'error'
      })
      return false
    }
    return true
  }

  const updateMembership = (input: MembershipInput) => {
    const checkIfRequiredFieldsAreFilled = checkIfRequiredFieldsAreFilledAsync(input);
    if (!checkIfRequiredFieldsAreFilled) return;

    const payload = {
      id: membership?.id || null,
      name: input.name,
      fee: parseFloat(input?.fee?.toString().replace(/,/g, "")),
      rewardPercentage: parseFloat(input?.rewardPercentage?.toString().replace(/,/g, "")),
      validityPeriod: parseFloat(input?.validityPeriod?.toString().replace(/,/g, "")),
      serviceIds: input.serviceIds.map((service) => service.value),
      clientIds: input.clientIds.map((client) => client.value),
    }

    upgradeMembership({
      variables: {
        input: { ...payload }
      }
    }).then(({ data }) => {
      if (data?.updateMembership?.status === 200) {
        addToast({
          message: 'Membership upgraded successfully',
          variant: 'success'
        })
        _closeModal()
      }

      if (data?.updateMembership?.errors?.length) {
        addToast({
          message: data?.updateMembership?.errors[0]?.message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })

  }
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <form onSubmit={handleSubmit(updateMembership)} className='w-full relative my-[80px]'
        autoComplete='off'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Edit membership</Heading>
              <Paragraph size="b4">Find information about membership here</Paragraph>
            </div>
          </div>
          {/* content */}
          <div className="w-full flex flex-col space-y-6">
            <div className='w-full flex space-x-4 items-start text-green-300 bg-green-300/5 rounded-md py-4 px-5'>
              <SvgMemberlove width="30px" height="30px" />
              <div className='w-full flex flex-col space-y-2'>
                <Paragraph size="b4" color={COLORS.GREEN[300]} weight="semiBold">{membership?.name}</Paragraph>
                <Paragraph size="b4" color={COLORS.GREEN[300]}>{formatInToPrice(membership?.fee)} spends • {membership?.rewardPercentage}% discount •  {membership?.services?.length} services</Paragraph>
              </div>
            </div>

            <div
              className='w-full flex flex-col space-y-4'>
              <div className="w-full flex flex-col space-y-2">
                <Input
                  name="name"
                  label="Enter membership name"
                  id="name"
                  type="text"
                  placeholder="Enter membership name"
                  control={control}
                  rules={{
                    required: ERRORS.FIRST_NAME_REQUIRED,
                  }}
                  error={errors.name}
                />
              </div>
              <div className="w-full flex gap-x-4">
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='fee'>
                    Membership fee
                  </FormLabel>
                  <div className='w-full flex gap-x-2 justify-center items-end'>
                    <Controller
                      control={control}
                      name="fee"
                      render={({ field }) => (
                        <div className='w-full flex flex-col space-y-2'>
                          <div className='w-full flex items-center border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                            <div className='w-fit px-1 flex items-center space-x-2'>
                              <Paragraph size='b4' color={COLORS.GREY[400]}>{DEFAULT_CURRENCY}</Paragraph>
                            </div>
                            <input
                              className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) =>
                                field.onChange(formatNumber(e.target.value))
                              }
                              placeholder='10'
                            />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='service'>
                    Reward
                  </FormLabel>
                  <div className='w-full flex gap-x-2 justify-center items-end'>
                    <Controller
                      control={control}
                      name="rewardPercentage"
                      render={({ field }) => (
                        <div className='w-full flex flex-col space-y-2'>
                          <div className='w-full flex items-center border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400 space-x-3'>
                            <input
                              className='w-full flex items-center appearance-none font-normal text-b6 xl:text-b4 text-grey-900 focus:outline-none focus:border-0 focus:shadow-none placeholder:text-grey-400'
                              type='text'
                              {...field}
                              value={formatNumber(field.value)}
                              onChange={(e) =>
                                field.onChange(formatNumber(e.target.value))
                              }
                              placeholder='10'
                            />
                            <div className='w-fit flex items-center space-x-2'>
                              <Paragraph size='b4' color={COLORS.GREY[400]}>%</Paragraph>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-4">
                <Controller
                  control={control}
                  name="serviceIds"
                  render={({
                    field: { onChange, value }
                  }) => {
                    return (
                      <div className='w-full md:w-1/2 flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>
                          This reward applies to
                        </FormLabel>
                        <MultiSelect
                          selected={value || []}
                          options={[
                            ...servicesOptions
                          ]}
                          setSelected={onChange}
                        />
                      </div>
                    )
                  }}
                />
                <div className="w-full md:w-1/2">
                  <SelectInput
                    name='validityPeriod'
                    id='validityPeriod'
                    label='Valid for'
                    control={control}
                    rules={{
                      required: ERRORS.VALID_FOR_REQUIRED
                    }}
                    error={errors.validityPeriod}
                    options={[...VALID_FOR, {
                      label: 'Forever',
                      value: '9999'
                    }]}
                    value={watch('validityPeriod')}
                    placeholder='Select validity period'
                  />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-4">
                <Controller
                  control={control}
                  name="clientIds"
                  render={({
                    field: { onChange, value }
                  }) => {
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='clientIds'>
                          Select customers who have this membership
                        </FormLabel>
                        <MultiSelect
                          selected={value || []}
                          options={[
                            ...clientsOptions
                          ]}
                          setSelected={onChange}
                        />
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-end border-t border-grey-100 cursor-pointer p-4'>
          {/* <div className='w-full xl:w-1/2 flex justify-end p-4 gap-4'> */}
          <Button
            variant='text'
            className={`text-red-500`}
            size='sm'
            type='button'
            onClick={_closeModal}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            size='md'
            rounded='lg'
            loading={loading}
            disabled={loading}
          >
            Upgrade
          </Button>
          {/* </div> */}
        </div>
      </form>
    </Modal>
  )
}

const PauseMembership = ({ isVisible, closeModal, addToast, membership }: AddMembershipProps) => {
  const { loading: isLoading, archiveMembership } = useArchiveMembership()
  const handleDelete = () => {
    archiveMembership({
      variables: {
        input: {
          id: membership.id
        },
      }
    }).then(({ data }) => {
      const { archiveMembership } = data
      if (archiveMembership?.status === 200) {
        addToast({
          message: 'Membership Archived successfully',
          variant: 'success',
        })
        closeModal()
      }

      if (archiveMembership?.errors?.length) {
        addToast({
          message: archiveMembership?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }
  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Archive Membership
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to archive {membership?.name}?
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const DeleteMembership = ({ isVisible, closeModal, addToast, membership }: AddMembershipProps) => {
  const { loading: isLoading, deleteMembership } = useDeleteMembership()
  const handleDelete = () => {
    deleteMembership({
      variables: {
        input: {
          id: membership.id
        },
      }
    }).then(({ data }) => {
      const { deleteMembership } = data
      if (deleteMembership?.status === 200) {
        addToast({
          message: 'Membership Deleted successfully',
          variant: 'success',
        })
        closeModal()
      }

      if (deleteMembership?.errors?.length) {
        addToast({
          message: deleteMembership?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }
  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Membership
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {membership?.name}? This means that
                this membership will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const RestoreMembership = ({ isVisible, closeModal, addToast, membership }: AddMembershipProps) => {
  const { loading: isLoading, restoreMembership } = useRestoreMembership()
  const handleDelete = () => {
    restoreMembership({
      variables: {
        input: {
          id: membership.id
        },
      }
    }).then(({ data }) => {
      const { restoreMembership } = data
      if (restoreMembership?.status === 200) {
        addToast({
          message: 'Membership Restored successfully',
          variant: 'success',
        })
        closeModal()
      }

      if (restoreMembership?.errors?.length) {
        addToast({
          message: restoreMembership?.errors[0]?.message,
          variant: 'error',
        })
      }
    })
  }
  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Restore Membership
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to restore {membership?.name}?
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={false}
                loading={isLoading}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={() => closeModal()}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default LoyaltyAndPromos
