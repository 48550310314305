import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { WalletModalProps } from '../types'
import { Button, Checkbox, Heading, Modal, NameAvatar, Paragraph, Pill, SearchTerm, SvgArrowBack, SvgChevronLeft, SvgFilter, SvgMemberlove, SvgWallet, Table } from 'ui'
import { useGetClient } from 'api/useClient'
import { useModal, useSalonCache } from 'hooks'
import { Client, ClientWalletTransaction } from 'core/generated'
import { MOBILE_WALLET_HISTORY_HEADERS, WALLET_HISTORY_HEADERS } from 'modules/clients/constants'
import ViewTransactionModal from 'modules/clients/modals/ViewTransactionModal'
import { formatInToPrice, getNumberMonthAndYearFromDate } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import { Menu, Transition } from '@headlessui/react'
import Label from 'ui/atoms/formLabel/Label'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'

const ClientMembershipDetails = (props: WalletModalProps) => {
  const [servicesShowAll, setServicesShowAll] = useState(false)
  const [filteredWalletTransactions, setFilteredWalletTransactions] = useState<ClientWalletTransaction[] | null>(null)
  const [searchWalletTransactionPreference, setSearchWalletTransactionPreference] = useState('')
  const [currentWalletTransaction, setCurrentWalletTransaction] = useState<ClientWalletTransaction | null>(null)
  const { getSalonData } = useSalonCache()
  const {
    openModal: openViewWalletTransactionModal,
    closeModal: closeViewWalletTransactionModal,
    isVisible: isViewWalletTransactionModalVisible
  } = useModal()
  const salon = getSalonData();
  const salonId = salon?.id;
  const {
    data: clientData,
    loading: clientLoading,
    refetch
  } = useGetClient({
    clientId: props?.client?.id,
    salonId
  })
  const client = useMemo(() => clientData?.salonClient, [clientData])
  const clientListPreferences = useMemo(() => {
    setFilteredWalletTransactions(client?.membershipWalletTransactions)
    return [];
  }, [client])


  useEffect(() => {
    if (searchWalletTransactionPreference && searchWalletTransactionPreference !== '') {
      // check if searchWalletTransactionPreference is like transactionType or amount
      const filtered = client?.membershipWalletTransactions?.filter?.((transaction) => {
        return transaction?.transactionType?.toLowerCase?.()?.includes?.(searchWalletTransactionPreference?.toLowerCase?.()) || transaction?.amount?.toString?.()?.includes?.(searchWalletTransactionPreference?.toLowerCase?.())
      })

      setFilteredWalletTransactions(filtered)
    } else {
      setFilteredWalletTransactions(client?.membershipWalletTransactions)
    }
  }, [searchWalletTransactionPreference])

  const clientAvatarAndNameView = (client: Client) => {
    let charater = '--'
    if (client?.fullName) {
      // split fullName
      const fullName = client?.fullName.split(' ')
      // get first and second letter of each name
      if (fullName.length > 1) {
        charater = `${fullName[0]?.charAt(0)}${fullName[1]?.length > 0
          ? fullName[1]?.charAt(0)
          : fullName[0]?.charAt(1)
          }`
      } else {
        charater = fullName[0]?.charAt(0) + fullName[0]?.charAt(1)
      }
    }

    return (
      <NameAvatar name={charater}
        size='lg' />
    )
  }

  const closeWalletTransactionModal = () => {
    setCurrentWalletTransaction(null)
    closeViewWalletTransactionModal()
  }

  const [walletTransactionsFilter, setWalletTransactionsFilter] = useState([
    {
      label: 'Credit',
      value: 'credit',
      show: true
    },
    {
      label: 'Debit',
      value: 'debit',
      show: true
    }
  ])

  const openWalletTransaction = (id?: number) => {
    if (!filteredWalletTransactions?.length) return;
    const walletTransaction = filteredWalletTransactions[id];
    if (!walletTransaction) return;

    setCurrentWalletTransaction(walletTransaction)
    openViewWalletTransactionModal()
  }

  const generateWalletTransactions = (walletTransactions: ClientWalletTransaction[]) => {
    return walletTransactions?.map?.((walletTransaction) => {
      return {
        date: getNumberMonthAndYearFromDate(walletTransaction?.createdAt),
        transactionType: walletTransaction?.transactionType,
        amount: () => {
          if (walletTransaction?.transactionType === 'debit') {
            return (
              <span className='text-red-500 text-b4'>
                -{formatInToPrice(walletTransaction?.amount)}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              +{formatInToPrice(walletTransaction?.amount)}
            </span>
          )
        },
        trailingBalance: () => {
          if (walletTransaction?.trailingBalance?.toString()?.includes('-')) {
            return (
              <span className='text-red-500 text-b4'>
                {formatInToPrice(walletTransaction?.trailingBalance)}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              {formatInToPrice(walletTransaction?.trailingBalance)}
            </span>
          )
        }
      }
    })
  }

  const nameMap = {
    "name": "Name",
    "servicesIncluded": "Applicable services",
    "amount": "Spends",
    "reward": "Discount",
    "validityPeriod": "Valid for",
  };

  const membershipMap = {
    "name": client?.clientMembership?.membership?.name,
    "amount": client?.clientMembership?.membership ? formatInToPrice(client?.clientMembership?.membership?.fee) : '-',
    "reward": `${client?.clientMembership?.membership?.rewardPercentage}%`,
    "servicesIncluded": client?.clientMembership?.membership && client?.clientMembership?.membership?.services?.length ? servicesShowAll ? client?.clientMembership?.membership?.services?.map((service) => service?.name).join(', ') : client?.clientMembership?.membership?.services?.slice(0, 5)?.map((service) => service?.name).join(', ') : '-',
    "validityPeriod": `${client?.clientMembership?.membership?.validityPeriod} month(s)`,
  };

  const handleKeyWalletTransactionCheckboxChange = (value: string) => {
    // modify keyPreferencesFilter show
    const index = walletTransactionsFilter?.findIndex?.((filter) => filter?.value === value)
    if (index === -1) return
    const newWalletTransactionsFilter = [...walletTransactionsFilter];
    newWalletTransactionsFilter[index].show = !newWalletTransactionsFilter[index].show;
    setWalletTransactionsFilter(newWalletTransactionsFilter)
  }

  useEffect(() => {
    if (Array?.isArray(walletTransactionsFilter) && walletTransactionsFilter?.length) {
      const filtered = walletTransactionsFilter?.filter?.((filter) => filter?.show)?.map?.((filter) => filter?.value)
      const filteredTransactions = client?.clientWalletTransactions?.filter?.((transaction) => {
        return filtered?.includes?.(transaction?.transactionType)
      })
      setFilteredWalletTransactions(filteredTransactions)
    }
  }, [walletTransactionsFilter])

  const walletTransactions = generateWalletTransactions(filteredWalletTransactions)
  return (
    <>
      <Modal
        show={props.isOpen}
        closeModal={props.closeModal}
        variant='right'
      >
        {clientLoading ? <ContentLoader /> : null}
        <div className='relative'>
          <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={props?.closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={props?.closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                {client?.firstName} {client?.lastName}
              </Paragraph>
            </div>
          </div>
          <div
            style={{ marginTop: '70px' }}
            className='w-full max-w-5xl flex flex-col space-y-6 h-fit py-6'
          >
            {client ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                  className='flex justify-between xl:items-center px-6 space-x-4 border-b border-grey-100 pb-6'
                >
                  <div className='flex space-x-4 items-center'>
                    {clientAvatarAndNameView(client)}
                    <div className='flex flex-col space-y-2'>
                      <Paragraph size='b2' weight='semiBold' className='flex items-center'>
                        {client?.firstName} {client?.lastName} {client?.clientMembership?.membershipStatus === 'live' ? <SvgMemberlove width="30px" height="30px" /> : null}
                      </Paragraph>
                      <Paragraph size='b4' weight='normal'>
                        {client?.email}
                      </Paragraph>
                    </div>
                    {client?.blacklisted ? <Pill variant="danger">Blacklisted</Pill> : null}
                  </div>
                </div>
              </>
            ) : null}

            <div className='w-full flex flex-col space-y-6'>
              <div className='w-full flex flex-col space-y-4 px-6 py-3'>
                <div className="text-white rounded-lg bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1736846380/zna8xjkzuthqtoyeb6cm.png')] bg-contain bg-repeat w-full h-fit">
                  <div className="flex w-full px-4 py-6 justify-between items-center rounded-lg">
                    <div className='flex items-center space-x-3'>
                      <div className='w-fit flex justify-between items-start text-white rounded-full p-1.5 bg-[#FFFFFF14]'>
                        <SvgWallet width="20px" height="20px" />
                      </div>
                      <div className="flex flex-col">
                        <Paragraph size="b4" weight="semiBold" color={COLORS.WHITE}>
                          Membership Wallet balance
                        </Paragraph>
                        <Paragraph size="b4" weight="bold" color={COLORS.WHITE}>
                          {formatInToPrice(client?.membershipWalletBalance || 0)}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className='w-full flex px-6'>
                <div className="w-full flex flex-col rounded-md border border-grey-100">
                  <div className='w-full flex justify-between items-center border-b border-grey-100 pt-5 pb-3 px-5'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                      Membership information
                    </Paragraph>
                  </div>

                  <div className='w-full flex flex-col space-y-4 px-5 py-4'>
                    {Object.entries(membershipMap).map(([key, value]) => (
                      nameMap[key] && value !== null && (
                        <div className="flex justify-between items-start space-y-2">
                          <Paragraph size="b4" color={COLORS.GREY[300]} weight="semiBold" className="text-left">
                            {nameMap[key]}
                          </Paragraph>
                          <div className="flex flex-col space-y-1 items-end max-w-[60%]">
                            <Paragraph size="b4" color={COLORS.GREY[900]} weight="semiBold" className="text-right">
                              {value}
                            </Paragraph>
                            {key === 'servicesIncluded' ?
                              <div className="py-2">
                                {servicesShowAll ?
                                  <span
                                    className='p-0 text-green-300 underline cursor-pointer font-bold text-b6'
                                    onClick={() => setServicesShowAll(false)}
                                    role='button'
                                  >
                                    Click to view less
                                  </span> : <span
                                    className='p-0 text-green-300 underline cursor-pointer font-bold text-b6'
                                    onClick={() => setServicesShowAll(true)}
                                    role='button'
                                  >
                                    Click to view more
                                  </span>}
                              </div> : null}
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                </div>
              </div>


              <div className='w-full py-4 px-6 bg-grey-50 border-grey-150/45 border-t border-b'>
                <Heading size='b4' weight='semiBold' color={COLORS.GREY[900]} variant='h2'>
                  Transaction history
                </Heading>
              </div>
              <div className='flex items-center px-4 space-x-3 w-1/2'>
                <SearchTerm
                  setDebouncedSearchQuery={setSearchWalletTransactionPreference}
                  placeholder="Search..."
                  size="md"
                />
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                      <SvgFilter width="20px" height="20px" />
                      <span className='hidden xl:inline'>Filter</span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                      <div className="flex flex-col p-4 space-y-4">
                        {Array.isArray(walletTransactionsFilter) && walletTransactionsFilter.length && walletTransactionsFilter.map((heading, index) => (
                          <Label className='flex space-x-2 items-center cursor-pointer' key={index} htmlFor={heading.value} onClick={() => handleKeyWalletTransactionCheckboxChange(heading.value)}>
                            <Checkbox
                              isChecked={heading.show}
                              borderType='Black'
                              size='Sm'
                            />
                            <span>{heading.label}</span>
                          </Label>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <Table
                headers={WALLET_HISTORY_HEADERS}
                mobileHeaders={MOBILE_WALLET_HISTORY_HEADERS}
                rows={walletTransactions}
                onClick={openWalletTransaction}
                tableOptions={{
                  view: true,
                  edit: false,
                  delete: false,
                  duplicate: false
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <ViewTransactionModal
        isVisible={isViewWalletTransactionModalVisible}
        closeModal={closeWalletTransactionModal}
        walletTransaction={currentWalletTransaction}
      />
    </>
  )
}

export default ClientMembershipDetails