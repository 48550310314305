import { COLORS } from 'constants/colors';
import React from 'react'
import { Button, Heading, Modal, Paragraph, SvgBankIcon } from 'ui';
import AddPaymentInfo from './AddPayoutInfo';
import { useModal } from 'hooks';
import AddOrEditPosDevice from './AddOrEditPosDevice';
import { PERMISSION_CONSTANTS } from 'constants/permission';
import { canPerformAction } from 'components/utils/permission';
import AddOrEditTransferAccount from './AddOrEditTransfer';

const PaymentInformation = ({
  checklist,
  isVisible,
  closeModal,
  refetch
}: {
  checklist: {
    aboutOrCancellationPolicy: boolean;
    bankTransfers: boolean;
    bookingSiteLink: boolean;
    client: boolean;
    completionPercentage: number;
    hours: boolean;
    payoutAccountDetails: boolean;
    pointOfSalesTerminals: boolean;
    salon: boolean;
    service: boolean;
    staff: boolean;
  },
  isVisible: boolean,
  closeModal: () => void
  refetch: () => void
}) => {
  const {
    isVisible: isAddPaymentModalVisible,
    openModal: openAddPaymentModal,
    closeModal: closeAddPaymentModal,
  } = useModal();

  const {
    isVisible: isAddPosModalVisible,
    openModal: openAddPosModal,
    closeModal: closeAddPosModal,
  } = useModal();

  const {
    isVisible: isAddTransferModalVisible,
    openModal: openAddTransferModal,
    closeModal: closeAddTransferModal,
  } = useModal();
  return (
    <>
      {isVisible ?
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-20">
          <div className="table-row">
            <div className="table-cell align-middle">
              <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:min-w-[500px]">
                <div className="flex flex-col items-center space-y-6">
                  <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Add Payment Information</Heading>
                  <div className='w-full flex flex-col space-y-3'>
                    {/* <div onClick={openAddPaymentModal} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                      <Paragraph size='b5' color={COLORS.GREY[300]}>Add Payout Account</Paragraph>
                    </div> */}

                    {canPerformAction(PERMISSION_CONSTANTS?.settings?.addBankTransfers) && !checklist?.bankTransfers ?
                      <div onClick={openAddTransferModal} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add Bank Transfers</Paragraph>
                      </div> : null}

                    {/* {canPerformAction(PERMISSION_CONSTANTS?.settings?.addPos) && !checklist?.pointOfSalesTerminals ?
                      <div onClick={openAddPosModal} className='w-full cursor-pointer border border-grey-100/70 hover:border-green-300 rounded-md flex flex-col py-4 justify-center items-center'>
                        <Paragraph size='b5' color={COLORS.GREY[300]}>Add POS Terminals</Paragraph>
                      </div> : null} */}
                  </div>
                  <Button
                    variant="primary"
                    size='sm'
                    rounded='md'
                    fontSize='b5'
                    className='mx-0'
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> : null}
      <AddPaymentInfo
        isVisible={isAddPaymentModalVisible}
        closeModal={() => {
          closeAddPaymentModal()
          refetch()
        }}
      />
      <AddOrEditPosDevice
        isVisible={isAddPosModalVisible}
        closeModal={() => {
          closeAddPosModal()
          refetch()
        }}
      />
      <AddOrEditTransferAccount
        isVisible={isAddTransferModalVisible}
        closeModal={() => {
          closeAddTransferModal()
          refetch()
        }}
      />
    </>
  )
}

export default PaymentInformation