import { useToast } from 'hooks/useToast'
import React, { useEffect, useMemo, useState } from 'react'
import { Layout, Paginator, Paragraph, SearchTerm, SvgInfo, SvgMemberlove, SvgWallet, Table, Tabs, ToastProps, ToastWrapper } from 'ui'
import { WALLET_PAGE_TAB_NAME, WALLET_TABS, WALLET_PAGE_TABS, MOBILE_CLIENTS_HEADINGS, WALLET_CLIENTS_HEADING, MEMBERSHIP_CLIENTS_HEADING, MEMBERSHIP_MOBILE_CLIENTS_HEADINGS } from '../constant'
import { COLORS } from 'constants/colors'
import { useModal, useSalonCache } from 'hooks'
import { useGetClients } from 'api/useClient'
import { Client } from 'core/generated'
import { formatInToPrice, getDayMonthAndNumberAndTimeFromDate, limitString } from 'components/utils/misc'
import ClientWalletDetails from '../modals/ClientWalletDetails'
import FullTable from 'ui/organism/table/Table'
import { canPerformAction } from 'components/utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import ClientMembershipDetails from '../modals/ClientMembershipDetails'
import { useWalletBalances } from 'api/useLoyaltyAndPromos'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'

const Wallet = () => {
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const [activeTab, setActiveTab] = useState(WALLET_PAGE_TAB_NAME.WALLET)
  const [activeWalletTab, setActiveWalletTab] = useState(WALLET_PAGE_TAB_NAME.CLIENT_WALLET)
  const { toast, addToast } = useToast()
  const [clientDebouncedSearchQuery, setClientDebouncedSearchQuery] = useState<string>('');
  const [client, setClient] = useState<Client>()
  const [before, setBefore] = useState(null)
  const [after, setAfter] = useState(null)
  const {
    openModal: openClientWalletModal,
    closeModal: closeClientWalletModal,
    isVisible: isClientWalletModalVisible
  } = useModal()
  const {
    openModal: openClientMembershipModal,
    closeModal: closeClientMembershipModal,
    isVisible: isClientMembershipModalVisible
  } = useModal()
  const {
    data: clientsData,
    refetch: refetchClients,
    loading: clientLoading,
  } = useGetClients({
    salonId,
    q: clientDebouncedSearchQuery,
    after,
    before,
    first: 50,
    sortBy: activeWalletTab === WALLET_PAGE_TAB_NAME.CLIENT_WALLET ? 'client_wallet' : 'membership_wallet',
  })
  const clients = useMemo(() => clientsData?.salonClients, [clientsData])

  const {
    data: walletBalanceData,
    refetch: refetchWalletBalance,
  } = useWalletBalances()
  const totalClientWalletBalance = walletBalanceData?.totalClientWalletBalance || 0;
  const totalMembershipWalletBalance = walletBalanceData?.totalMembershipWalletBalance || 0;

  useEffect(() => {
    refetchClients()
    refetchWalletBalance()
  }, [salonId, refetchWalletBalance, refetchClients, activeWalletTab])

  const actions = {
    seeInfo: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ),
    viewClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
  }
  const goToNext = () => {
    if (clients?.pageInfo?.hasNextPage) {
      const after = clients?.pageInfo?.endCursor;
      setAfter(after)
      refetchClients()
    }
  }

  const goToPrev = () => {
    if (clients?.pageInfo?.hasPreviousPage) {
      const before = clients?.pageInfo?.startCursor;
      setBefore(before)
      refetchClients()
    }
  }

  const tableClientsData = clients?.nodes?.map((client) => ({
    name: client?.firstName + " " + client?.lastName,
    walletBalance: formatInToPrice(client?.walletBalance),
    email: limitString(client?.email, 20),
    date: client?.lastVisit
      ? getDayMonthAndNumberAndTimeFromDate(client?.lastVisit)
      : null,
  }))

  const tableMembershipClientsData = clients?.nodes?.map((client) => ({
    name: client?.firstName + " " + client?.lastName,
    email: limitString(client?.email, 20),
    membershipName: limitString(client?.clientMembership?.membership?.name, 20),
    membershipWalletBalance: formatInToPrice(client?.membershipWalletBalance),
    date: client?.clientMembership
      ? getDayMonthAndNumberAndTimeFromDate(client?.clientMembership?.updatedAt)
      : null,
  }))

  useEffect(() => {
    refetchClients
  }, [clientDebouncedSearchQuery, refetchClients])

  const openWalletModal = (index: number) => {
    // get client by index
    const client = clients?.nodes[index]
    if (!client) {
      return;
    }
    setClient(client)
    openClientWalletModal()
  }

  const openMembershipModal = (index: number) => {
    // get client by index
    const client = clients?.nodes[index]
    if (!client) {
      return;
    }
    setClient(client)
    openClientMembershipModal()
  }
  return (
    <Layout
      pageTitle='Promos'
      openModal={null}
      pageLevel={2}
    >
      {clientLoading ? <ContentLoader /> : null}
      <ToastWrapper toast={toast as ToastProps} />
      <Tabs
        tabs={WALLET_PAGE_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full p-6 border-b border-grey-100'>
        <div className='w-full grid grid-cols-1 xl:grid-cols-3 gap-2 md:gap-4 border border-grey-50 md:border-0 rounded-md'>
          <div className="w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1736846380/zna8xjkzuthqtoyeb6cm.png')] bg-cover bg-no-repeat">
            <div className='w-fit flex justify-between items-start text-white rounded-full p-1.5 bg-[#FFFFFF14]'>
              <SvgWallet width="20px" height="20px" />
            </div>

            <div className='w-full flex flex-col'>
              <div className="w-full flex space-x-4 items-center">
                <Paragraph size='b4' color={COLORS.GREY[100]} weight='semiBold'>
                  Total Wallet balance
                </Paragraph>
                <div className="group flex relative text-white">
                  <SvgInfo width="15px" height="15px" />
                  <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30">
                    This balance is the sum of all client and membership wallet balances.
                  </span>
                </div>
              </div>
              <Paragraph size='b2' color={COLORS.WHITE} weight='semiBold'>
                {formatInToPrice(totalMembershipWalletBalance + totalClientWalletBalance)}
              </Paragraph>
            </div>
          </div>
          <div className='w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 border border-green-300/60 bg-[#16796F08]'>
            <div className='w-fit flex justify-between items-start text-green-300 rounded-full p-1.5 bg-[#16796F0D]'>
              <SvgWallet width="20px" height="20px" />
            </div>

            <div className='w-full flex flex-col'>
              <div className="w-full flex space-x-4 items-center">
                <Paragraph size='b4' color={COLORS.GREEN[300]} weight='semiBold'>
                  Client Wallet balance
                </Paragraph>
                <div className="group flex relative text-green-300">
                  <SvgInfo width="15px" height="15px" />
                  <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30">
                    This balance is the sum of all client wallet balances.
                  </span>
                </div>
              </div>
              <Paragraph size='b2' color={COLORS.GREEN[900]} weight='semiBold'>
                {formatInToPrice(totalClientWalletBalance)}
              </Paragraph>
            </div>
          </div>
          <div className='w-full max-w-[400px] h-[132px] flex flex-col justify-between rounded-md py-3 px-4 border border-green-300/60 bg-[#16796F08]'>
            <div className='w-fit flex justify-between items-start text-green-300 rounded-full p-1.5 bg-[#16796F0D]'>
              <SvgMemberlove width="24px" height="24px" />
            </div>

            <div className='w-full flex flex-col'>
              <div className="w-full flex space-x-4 items-center">
                <Paragraph size='b4' color={COLORS.GREEN[300]} weight='semiBold'>
                  Membership wallet balance
                </Paragraph>
                <div className="group flex relative text-green-300">
                  <SvgInfo width="15px" height="15px" />
                  <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute right-2 -translate-x-2 opacity-0 m-4 mx-auto z-30">
                    This balance is the sum of all membership wallet balances.
                  </span>
                </div>
              </div>
              <Paragraph size='b2' color={COLORS.GREEN[900]} weight='semiBold'>
                {formatInToPrice(totalMembershipWalletBalance)}
              </Paragraph>
            </div>
          </div>

        </div>
      </div>
      <Tabs
        tabs={WALLET_TABS}
        activeTab={activeWalletTab}
        setActiveTab={setActiveWalletTab}
        variant='background'
      />

      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <div className='w-full max-w-[300px]'>
          <SearchTerm setDebouncedSearchQuery={setClientDebouncedSearchQuery} size="sm" />
        </div>
        <div className='flex space-x-2 items-center'>
          <Paginator pageInfo={clients?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      {activeWalletTab === WALLET_PAGE_TAB_NAME.CLIENT_WALLET ?
        <FullTable
          headers={WALLET_CLIENTS_HEADING}
          mobileHeaders={MOBILE_CLIENTS_HEADINGS}
          rows={tableClientsData}
          onClick={openWalletModal}
          tableOptions={{
            view: actions?.viewClient,
            edit: false,
            delete: false,
            duplicate: false
          }}
        /> :
        <FullTable
          headers={MEMBERSHIP_CLIENTS_HEADING}
          mobileHeaders={MEMBERSHIP_MOBILE_CLIENTS_HEADINGS}
          rows={tableMembershipClientsData}
          onClick={openMembershipModal}
          tableOptions={{
            view: actions?.viewClient,
            edit: false,
            delete: false,
            duplicate: false
          }}
        />}
      <ClientWalletDetails
        isOpen={isClientWalletModalVisible}
        closeModal={() => {
          closeClientWalletModal()
          setClient(null)
        }}
        client={client}
      />
      <ClientMembershipDetails
        isOpen={isClientMembershipModalVisible}
        closeModal={() => {
          closeClientMembershipModal()
          setClient(null)
        }}
        client={client}
      />
    </Layout>
  )
}

export default Wallet