import { useEffect, useMemo, useState } from 'react'
import {
  CalendarPicker,
  Heading,
  HeadlessDropDown,
  Layout,
  Paginator,
  Paragraph,
  Rating,
  ReportTable,
  TablePropsDataType,
  Tabs
} from 'ui'
import { COLORS } from 'constants/colors'
import { createFileNameFromDates, formatDateToOriginalDate, formatInToPrice, formatSnakeCaseText, formatTime, getDefaultQueryDates, getNumberMonthAndYearFromDate, handleDownload, handleExcelDownload } from '../components/utils/misc'
import { PerformingStaff, StaffAppointmentSummary, StaffRoster, StaffSchedule } from 'core/generated'
import { useSalonCache } from 'hooks/useSalonCache'
import { SvgCalendar, SvgDataTrends, SvgDownGrowthIndicator, SvgGrowthIndicator, SvgInfo, SvgIonTimeOutline, SvgLocationPin, SvgUser, SvgUsers } from 'ui'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import { activeStaffRosterGraphData, businessPerformanceAppointmentTrendGraphData } from '../components/uicomponents/reportGraphUtils'
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx'
import { useStaffAppointmentSummary, useStaffAppointmentSummaryReports, useStaffRoster, useStaffRosterSummary } from 'api/useReports'
import { useGetStaffList, useStaffPerformanceSummary } from 'api/useStaff'
import { IHeadlessOption } from 'ui/organism/headlessDropDown/types'
import FullTable from 'ui/organism/table/Table'
import { useModal } from 'hooks'
import StaffAppointmentSummaryModal from 'components/uicomponents/StaffAppointmentSummaryModal'
Chart.register(...registerables);

const STAFF_REPORTS_TAB_NAME = {
  STAFF_ROASTER: 'Staff Roster',
  STAFF_APPOINTMENT_SUMMARY: 'Staff appointment summary'
}

const StaffReports = () => {
  const STAFF_REPORTS_TABS = [
    {
      key: STAFF_REPORTS_TAB_NAME.STAFF_ROASTER,
      title: STAFF_REPORTS_TAB_NAME.STAFF_ROASTER,
      show: true
    },
    {
      key: STAFF_REPORTS_TAB_NAME.STAFF_APPOINTMENT_SUMMARY,
      title: STAFF_REPORTS_TAB_NAME.STAFF_APPOINTMENT_SUMMARY,
      show: true
    }
  ]
  const [activeTab, setActiveTab] = useState<string>(
    STAFF_REPORTS_TABS?.filter((tab) => tab.show)[0].key
  )

  const getTabView = () => {
    switch (activeTab) {
      case STAFF_REPORTS_TAB_NAME.STAFF_ROASTER:
        return <StaffRoaster />
      default:
        return <StaffSummary />
    }
  }
  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={STAFF_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const StaffRoaster = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [staffDropDownSelectedValue, setStaffDropDownSelectedValue] = useState('')
  const Headers = [
    { name: 'Day' },
    { name: 'Is Working' },
    { name: 'Start' },
    { name: 'End' },
    { name: 'Duration' }
  ]

  const {
    data: staffRosterSummaryData,
    loading: staffRosterSummaryLoading,
    refetch: refetchStaffRosterSummary
  } = useStaffRosterSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const staffRosterSummary = useMemo(() => staffRosterSummaryData?.staffRosterSummaryReport, [staffRosterSummaryData])

  const {
    data: staffRosterData,
    loading: staffRosterLoading,
    refetch: refetchStaffRoster
  } = useStaffRoster({
    salonId,
    staffId: staffDropDownSelectedValue,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"),
  })
  const staffRoster = useMemo(() => staffRosterData?.staffRosterReport, [staffRosterData])


  useEffect(() => {
    refetchStaffRoster()
    refetchStaffRosterSummary()
  }, [refetchStaffRoster, refetchStaffRosterSummary, selectedDates])

  const {
    data: staffListData,
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staffMembers = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  useEffect(() => {
    if (Array?.isArray(staffMembers) && staffMembers?.length) {
      const firstId = staffMembers[0]?.id
      setStaffDropDownSelectedValue(firstId)
    }
  }, [staffMembers])

  const staffList =
    staffMembers?.length ? staffMembers?.map((staff) => {
      return {
        value: staff?.id,
        label: staff?.user?.firstName + ' ' + staff?.user?.lastName
      }
    }) as IHeadlessOption[] : []

  const generateStaffRosterTableData = (staff: StaffRoster[]) => {
    return staff?.map?.((eachStaff) => ({
      staff: eachStaff?.staff,
      workingHours: generateScheduleTableData(
        eachStaff?.schedule
      ) as unknown as TablePropsDataType[],
    }));
  };

  const generateScheduleTableData = (schedule: StaffSchedule[]) => {
    return schedule?.map?.((eachSchedule) => ({
      day: getNumberMonthAndYearFromDate(eachSchedule?.date),
      isWorking: eachSchedule?.isWorking ? "Yes" : "No",
      start: eachSchedule?.openTime && formatStaffRosterTime(eachSchedule?.openTime),
      end: eachSchedule?.closeTime && formatStaffRosterTime(eachSchedule?.closeTime),
      duration: eachSchedule?.duration,
    }));
  };

  const generateScheduleForCSV = (schedule: StaffSchedule[]) => {
    return schedule?.map?.((eachSchedule) => ({
      "Day": getNumberMonthAndYearFromDate(eachSchedule?.date),
      "Is Working": eachSchedule?.isWorking ? "Yes" : "No",
      "Start": eachSchedule?.openTime && formatStaffRosterTime(eachSchedule?.openTime),
      "End": eachSchedule?.closeTime && formatStaffRosterTime(eachSchedule?.closeTime),
      "Duration": eachSchedule?.duration,
    }));
  };

  const formatStaffRosterCsvDownloadData = (staff: StaffRoster[]) => {
    const data = [];
    staff?.forEach((eachStaff) => {
      // Push the staff information
      data.push({
        "Day": eachStaff?.staff,
        "Is Working": "",
        "Start": "",
        "End": "",
        "Duration": "",
      });

      // Push the schedule data
      const scheduleData = generateScheduleForCSV(eachStaff?.schedule);
      data.push(...scheduleData);
    });
    return data;
  };

  useEffect(() => {
    if (staffDropDownSelectedValue && staffDropDownSelectedValue !== '') {
      refetchStaffRoster()
    }
  }, [staffDropDownSelectedValue, refetchStaffRoster])

  const formatStaffRosterTime = (dateString: string) => {
    if (!dateString) return '';
    const timeString = dateString?.split('T')[1];
    const timeOffset = timeString?.split('+')[0];
    return formatTime(timeOffset);
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_staff_appointment_overview_report`;
  const _handleDownload = () => {
    if (staffRoster?.nodes && staffRoster?.nodes?.length) {
      handleDownload(formatStaffRosterCsvDownloadData(staffRoster?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (staffRoster?.nodes && staffRoster?.nodes?.length) {
      handleExcelDownload(formatStaffRosterCsvDownloadData(staffRoster?.nodes), filename, 'Staff Roster');
    }
  }

  const rows = generateStaffRosterTableData(staffRoster?.nodes || [])

  const trendData = activeStaffRosterGraphData(staffRosterSummary?.activeStaffGraphData || [], staffRosterSummary?.totalStaffGraphData || []);

  return (
    <>
      {staffRosterSummaryLoading || staffRosterLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Staff Roster
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-2 gap-4 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col space-y-4'>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgUsers width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Total staff
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {staffRosterSummary?.totalStaff}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgUsers width="24px" height="24px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Active staff
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {staffRosterSummary?.activeStaff}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgIonTimeOutline width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Total hours worked
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {staffRosterSummary?.totalHoursWorked}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='w-full p-4 flex flex-col space-y-3 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Trends and Highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your trends and highlights across staff appointment reports here
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex justify-between'>
            <div className='flex flex-col space-y-2'>
              <Paragraph size='b6' color={COLORS.GREY[300]} className='uppercase'>
                active staff
              </Paragraph>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                {staffRosterSummary?.activeStaff}/{staffRosterSummary?.totalStaff}
              </Paragraph>
            </div>

            <div className='flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center'>
                <span>{staffRosterSummary?.staffTrend}%</span>
                {staffRosterSummary?.staffTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
              </Paragraph>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                VS LAST MONTH
              </Paragraph>
            </div>
          </div>
          <div className='w-full h-[235px]'>
            <Bar options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  position: "bottom",
                  text: 'Staff Overview: Total vs Active',
                },
                tooltip: {
                  enabled: true,
                  mode: 'index',
                  intersect: false,
                }
              },
              scales: {
                x: {
                  type: 'category',
                },
                y: {
                  beginAtZero: true,
                },
              },
            }} data={trendData} />
          </div>
          <div className='w-full flex gap-3 justify-center items-center'>
            <Paragraph size='b5' className='flex items-center capitalize'>
              <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
              Total staff
            </Paragraph>
            <Paragraph size='b5' className='flex items-center capitalize'>
              <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
              Active staff
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>

        <div className='w-[170px]'>
          <HeadlessDropDown
            options={staffList}
            selectedValue={staffDropDownSelectedValue}
            setDropDownSelectedValue={setStaffDropDownSelectedValue}
          />
        </div>
      </div>
      <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
        {Array.isArray(rows) &&
          rows?.length &&
          rows?.map((row) => {
            return (
              <div className='w-full flex flex-col h-fit border-t border-grey-100'>
                <Paragraph
                  size='b4'
                  weight='bold'
                  className='pl-10 py-4'
                >{row?.staff}</Paragraph>
                <ReportTable rows={row?.workingHours} headers={Headers} />
              </div>
            )
          })}
      </div>
    </>
  )
}

const StaffSummary = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [activeTab, setActiveTab] = useState('overview')
  const [staffAppointment, setStaffAppointment] = useState<StaffAppointmentSummary | null>(null)
  const {
    isVisible: staffAppointmentModalIsVisible,
    openModal: openStaffAppointmentModal,
    closeModal: closeStaffAppointmentModal
  } = useModal()
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const Headers = [
    { label: 'Staff Member', value: 'staffName' },
    { label: 'Service', value: 'service' },
    { label: 'Date', value: 'date' },
    { label: 'Status', value: 'status' },
    { label: 'Price', value: 'price' },
    { label: 'Paid', value: 'paid' }
  ]
  const PerformanceHeaders = [
    { label: 'Staff Member', value: 'staffName' },
    { label: 'Service', value: 'service' },
    { label: 'Appointments completed', value: 'appointmentCompleted' },
    { label: 'Value', value: 'value' },
    { label: 'Ratings', value: 'ratings' },
  ]
  const {
    data: staffListData,
  } = useGetStaffList({
    salonId,
  })
  const staffMembers = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  const {
    data: staffAppointmentSummary,
    loading: staffAppointmentSummaryIsLoading,
    refetch: getStaffAppointmetSummaryListAsync
  } = useStaffAppointmentSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const staffAppointmentListSummary = useMemo(() => {
    return staffAppointmentSummary?.staffAppointmentSummary
  }, [staffAppointmentSummary])

  const appointmentSummaryData = businessPerformanceAppointmentTrendGraphData(staffAppointmentListSummary?.averagePerformanceGraphData || []);

  const {
    data: staffPerformanceSummary,
    loading: staffPerformanceSummaryIsLoading,
    refetch: getStaffPerformanceSummaryListAsync
  } = useStaffPerformanceSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end")
  })
  const staffPerformanceListSummary = useMemo(() => {
    return staffPerformanceSummary?.staffPerformanceSummaryReport
  }, [staffPerformanceSummary])
  const {
    data: staffPerformingSummary,
    loading: staffPerformingSummaryIsLoading,
    refetch: refetchStaffPerformingSummaryListAsync
  } = useStaffAppointmentSummaryReports({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedDates[1], "end"), before, after
  })
  const staffAppointmentList = useMemo(() => {
    return staffPerformingSummary?.staffAppointmentSummaryReport
  }, [staffPerformingSummary])

  useEffect(() => {
    getStaffAppointmetSummaryListAsync()
    refetchStaffPerformingSummaryListAsync()
    getStaffPerformanceSummaryListAsync()
  }, [selectedDates])

  const PERFORMING_STAFF_TABLE = [
    { name: '' },
    { name: 'Name' },
    { name: 'Services' },
    { name: 'Value' }
  ]

  const generateStaffAppointmentsSummaryTableData = (appointments: StaffAppointmentSummary[]) => {
    return appointments?.map?.((appointment) => ({
      staffName: appointment.staffMember,
      service: appointment?.service,
      scheduledDate: getNumberMonthAndYearFromDate(appointment?.date),
      status: formatSnakeCaseText(appointment?.status),
      price: formatInToPrice(appointment?.price),
      paid: formatInToPrice(appointment?.paid),
    }))
  }

  const generateTopPerformingStaffTableData = (staff: PerformingStaff[]) => {
    return staff?.length ? staff?.map?.((staffItem, index) => ({
      id: index + 1,
      name: staffItem?.name ? staffItem?.name.split(" ")[0] : staffItem?.name,
      services: staffItem?.services,
      value: staffItem?.soldValue
    })) : []
  }

  const formatStaffAppointmentsSummaryCsvDownloadData = (appointments: StaffAppointmentSummary[]) => {
    return appointments?.map?.((appointment) => ({
      "Staff name": appointment.staffMember,
      "Service": appointment?.service,
      "Scheduled date": getNumberMonthAndYearFromDate(appointment?.date),
      "Status": formatSnakeCaseText(appointment?.status),
      "Price": formatInToPrice(appointment?.price),
      "Paid": formatInToPrice(appointment?.paid),
    }))
  }

  const formatStaffPerformanceTableData = (data) => {
    return data?.map((item) => ({
      staffName: item?.staff,
      service: item?.totalServicesBooked,
      appointmentCompleted: item?.totalAppointments,
      value: formatInToPrice(item?.totalSales),
      ratings: () => {
        const rate = item?.reviews || 0
        return <div className='flex space-x-2 items-center'>
          <span className='text-b5 text-grey-900'>{rate}</span>
          <Rating initialRating={parseInt(rate)} />
        </div>
      },
    }))
  }

  const rows = generateStaffAppointmentsSummaryTableData(staffAppointmentList?.nodes)
  const performanceRows = formatStaffPerformanceTableData(staffPerformanceListSummary)

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_staff_appointments_summary_report`;
  const _handleDownload = () => {
    if (staffAppointmentList) {
      handleDownload(formatStaffAppointmentsSummaryCsvDownloadData(staffAppointmentList?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (staffAppointmentList) {
      handleExcelDownload(formatStaffAppointmentsSummaryCsvDownloadData(staffAppointmentList?.nodes), filename, 'Staff Appointment Summary');
    }
  }

  const goToNext = () => {
    if (staffAppointmentList?.pageInfo?.hasNextPage) {
      setBefore(null)
      setAfter(staffAppointmentList?.pageInfo?.endCursor)
      refetchStaffPerformingSummaryListAsync()
    }
  }

  const goToPrev = () => {
    if (staffAppointmentList?.pageInfo?.hasPreviousPage) {
      setBefore(staffAppointmentList?.pageInfo?.startCursor)
      setAfter(null)
      refetchStaffPerformingSummaryListAsync()
    }
  }

  const openStaffAppointment = (id: number) => {
    if (!staffAppointmentList) return;
    const list = staffAppointmentList?.nodes[id];
    if (!list) return;

    setStaffAppointment(list)
    openStaffAppointmentModal()
  }

  const openStaffPerformance = (id: number) => {
    if (!staffPerformanceListSummary) return;
    const list = staffPerformanceListSummary[id];
    if (!list) return;

    setStaffAppointment({
      staffMember: list?.staff,
      service: list?.totalServicesBooked,
      date: "-",
      price: 0,
      status: '-'
    })
    openStaffAppointmentModal()
  }

  const initiateCloseModal = () => {
    setStaffAppointment(null)
    closeStaffAppointmentModal()
  }

  const TABS = [
    { key: 'overview', title: 'Overview' },
    { key: 'performance', title: 'Performance' },
  ]

  return (
    <>
      {staffAppointmentSummaryIsLoading || staffPerformingSummaryIsLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Staff Appointment Summary
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 lg:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUsers width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total staff
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {staffAppointmentListSummary?.totalStaff}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCalendar width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total appointments
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {staffAppointmentListSummary?.totalAppointments}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className='flex space-x-2 items-center'>
              <span>MVP Staff</span>
              <div className='group flex relative text-grey-300'>
                <SvgInfo width='15px' height='15px' />
                <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-50 p-2 text-b6 text-grey-300 rounded-md absolute right-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                  This is the staff with the highest number of sales.
                </span>
              </div>
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {staffAppointmentListSummary?.mvpStaff}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col gap-3 xl:flex-row justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <div className='w-full xl:max-w-[41.27%] p-4 flex flex-col space-y-3 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Trends and Highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your trends and highlights across staff appointment reports here
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col space-y-3'>
            <div className='w-full flex justify-between'>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b6' color={COLORS.GREY[300]} className='uppercase'>
                  AVERAGE PERFORMANCE
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                  {staffAppointmentListSummary?.ratingTrend}%
                </Paragraph>
              </div>

              <div className='flex flex-col space-y-2'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center justify-end'>
                  <span>{staffAppointmentListSummary?.appointmentTrend}%</span>
                  {staffAppointmentListSummary?.appointmentTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
                </Paragraph>
                <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                  VS LAST MONTH
                </Paragraph>
              </div>
            </div>
            <div className='h-[280px]'>
              <Bar options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    position: "bottom",
                    text: 'Staff Appointment Performance Summary',
                  },
                },
                scales: {
                  x: {
                    type: 'category',
                  },
                  y: {
                    beginAtZero: true,
                  },
                },
              }} data={appointmentSummaryData} />
            </div>
          </div>
        </div>
        <div className='w-full xl:max-w-[58.73%] p-4 flex flex-col space-y-5 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Staff highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your top 5 and least 5 performing members here along with the total number of services completed and the total sold value
              </Paragraph>
            </div>

            <div className='w-[170px] h-[80px]'>
              <SvgDataTrends width="100%" height="100%" />
            </div>
          </div>
          <div className='w-full flex flex-col lg:flex-row gap-3'>
            <div className='w-full xl:w-1/2 flex flex-col space-y-3'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='semiBold' className='uppercase'>
                Top performing staff
              </Paragraph>

              <ReportTable rows={generateTopPerformingStaffTableData(staffAppointmentListSummary?.topPerformingStaff)} headers={PERFORMING_STAFF_TABLE} />
            </div>

            <div className='w-full xl:w-1/2 flex flex-col space-y-3'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='semiBold' className='uppercase'>
                Bottom performing staff
              </Paragraph>

              <ReportTable rows={generateTopPerformingStaffTableData(staffAppointmentListSummary?.bottomPerformingStaff)} headers={PERFORMING_STAFF_TABLE} />
            </div>
          </div>
        </div>
      </div>

      <div className='w-full mt-[30px] border-t border-grey-100'>
        <Tabs
          tabs={TABS}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          variant='underline'
        />
        <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
          <Paragraph size='b4' weight='semiBold' className=''>
            Overview
          </Paragraph>
          <div className='flex space-x-2'>
            <Paginator pageInfo={staffAppointmentList?.pageInfo} {...{ goToNext, goToPrev }} />
          </div>
        </div>
        <div className='w-full hidden md:flex flex-col space-y-12 overflow-x-scroll'>
          {activeTab === 'overview' ?
            <div className='w-full flex flex-col h-fit border-t border-grey-100'>
              <FullTable rows={rows} headers={Headers} onClick={openStaffAppointment} />
            </div>
            :
            <div className='w-full flex flex-col h-fit border-t border-grey-100'>
              <FullTable rows={performanceRows} headers={PerformanceHeaders} onClick={openStaffPerformance} />
            </div>
          }
        </div>
      </div>
      <StaffAppointmentSummaryModal
        staffAppointment={staffAppointment}
        isVisible={staffAppointmentModalIsVisible}
        closeModal={initiateCloseModal}
        staffMembers={staffMembers}
      />
    </>
  )
}

export default StaffReports
