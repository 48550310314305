import React from 'react';
import { useUserCache } from 'hooks/useUserCache';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import Button from 'ui/atoms/button/Button';
import { Modal } from 'ui';
import BankForm from 'components/uicomponents/BankForm';

const AddPaymentInfo = ({ isVisible, closeModal }: {
  isVisible: boolean,
  closeModal: () => void,
}) => {
  const { getBusinessData } = useUserCache();
  const _business = getBusinessData();
  return (
    <Modal
      title='Payout Account information'
      subTitle="This is the bank account you will receive your payouts for payments you receive online"
      show={isVisible}
      closeModal={closeModal}
    >
      {_business?.payoutAccount ? (
        <>
          <div className='w-[70%] text-center mx-auto my-6'>
            <Paragraph size='b4' color={COLORS.GREY[300]} >Please contact customer support at <a className='text-green-600 font-semibold' href="mailto:info@withsplice.com">info@withsplice.com</a> to change your account number</Paragraph>
          </div>
          <div className='w-full flex space-x-2'>
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
              onClick={closeModal}
              type='button'
            >
              Close
            </Button>
          </div>
        </>
      ) : (
        <BankForm onNextPage={closeModal} buttonText="Save" />
      )}
    </Modal>
  )
}

export default AddPaymentInfo;