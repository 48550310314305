import SvgForbidden from "assets/icons/Forbidden"
import SvgPaymentMethodBank from "assets/icons/PaymentMethodBank"
import SvgPaymentMethodCash from "assets/icons/PaymentMethodCash"
import SvgPaymentMethodPos from "assets/icons/PaymentMethodPos"
import SvgPaymentMethodSplit from "assets/icons/PaymentMethodSplit"
import SvgPaymentMethodVoucher from "assets/icons/PaymentMethodVoucher"
import SvgPaymentMethodWallet from "assets/icons/PaymentMethodWallet"

export const SERVICE_PRICE_TYPES = [
  { label: "Fixed", value: "fixed" },
  { label: "From", value: "starting_at" },
]

export const SALES_PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: 'cash'
  },
  {
    label: 'POS',
    value: 'pos'
  },
  {
    label: 'Bank transfer',
    value: 'bank_transfer'
  },
  {
    label: 'Gift Card',
    value: 'gift_card'
  }
]

export const APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: 'cash',
    icon: SvgPaymentMethodCash
  }, {
    label: 'Transfer',
    value: 'bank_transfer',
    icon: SvgPaymentMethodBank
  }, {
    label: 'POS',
    value: 'pos',
    icon: SvgPaymentMethodPos
  }, {
    label: 'Voucher',
    value: 'voucher',
    icon: SvgPaymentMethodVoucher
  }, {
    label: 'Customer wallet',
    value: 'client_wallet',
    icon: SvgPaymentMethodWallet
  }, {
    label: 'Split payment',
    value: 'split',
    icon: SvgPaymentMethodSplit
  }, {
    label: 'Not Paid',
    value: 'unpaid',
    icon: SvgForbidden
  }
]

export const MEMBERSHIP_DISABLED_PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: 'cash',
    icon: SvgPaymentMethodCash
  }, {
    label: 'Transfer',
    value: 'bank_transfer',
    icon: SvgPaymentMethodBank
  }, {
    label: 'POS',
    value: 'pos',
    icon: SvgPaymentMethodPos
  }, {
    label: 'Voucher',
    value: 'voucher',
    icon: SvgPaymentMethodVoucher
  }, {
    label: 'Customer wallet',
    value: 'client_wallet',
    icon: SvgPaymentMethodWallet
  }
]

export const MEMBERSHIP_APPOINTMENT_SPLIT_PAYMENT_METHODS = [
  {
    label: 'Membership wallet',
    value: 'membership_wallet',
    icon: SvgPaymentMethodWallet
  }, {
    label: 'Split payment',
    value: 'split',
    icon: SvgPaymentMethodSplit
  }
]

export const MEMBERSHIP_APPOINTMENT_PAYMENT_METHODS_ONLY = [
  {
    label: 'Membership wallet',
    value: 'membership_wallet',
    icon: SvgPaymentMethodWallet
  }
]