export const WALLET_PAGE_TAB_NAME = {
  WALLET: 'Wallet',
  CLIENT_WALLET: 'Client Wallet',
  MEMBERSHIP_WALLET: 'Membership Wallet',
}

export const WALLET_PAGE_TABS = [
  {
    key: WALLET_PAGE_TAB_NAME.WALLET,
    title: WALLET_PAGE_TAB_NAME.WALLET,
    show: true
  }
]

export const WALLET_TABS = [
  {
    key: WALLET_PAGE_TAB_NAME.CLIENT_WALLET,
    title: WALLET_PAGE_TAB_NAME.CLIENT_WALLET,
    show: true
  },
  {
    key: WALLET_PAGE_TAB_NAME.MEMBERSHIP_WALLET,
    title: WALLET_PAGE_TAB_NAME.MEMBERSHIP_WALLET,
    show: true
  }
]

export const WALLET_CLIENTS_HEADING = [
  {
    label: 'Name',
    value: 'name',
    show: true
  },
  {
    label: 'Wallet Balance',
    value: 'walletBalance',
    show: true
  },
  {
    label: 'Email',
    value: 'email',
    show: true
  },
  {
    label: 'Date last used',
    value: 'date',
    show: true
  }
]

export const MEMBERSHIP_CLIENTS_HEADING = [
  {
    label: 'Name',
    value: 'name',
    show: true
  },
  {
    label: 'Email',
    value: 'email',
    show: true
  },
  {
    label: 'Membership Name',
    value: 'membershipName',
    show: true
  },
  {
    label: 'Membership Balance',
    value: 'membershipWalletBalance',
    show: true
  },
  {
    label: 'Date last used',
    value: 'date',
    show: true
  }
]

export const MOBILE_CLIENTS_HEADINGS = [
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Wallet Balance',
    key: 'walletBalance'
  },
  {
    title: 'Date',
    key: 'date'
  }
]



export const MEMBERSHIP_MOBILE_CLIENTS_HEADINGS = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Membership Name',
    key: 'membershipName',
  },
  {
    title: 'Membership Balance',
    key: 'membershipWalletBalance',
  },
  {
    title: 'Date last used',
    key: 'date',
  }
]