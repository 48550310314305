import { useCreatePackage, useGetServices } from "api/useCatalogue"
import { SelectedService } from "components/modals/types"
import { formatNumber, formatInToPrice } from "components/utils/misc"
import { formatServicesToSelectField } from "components/utils/utils"
import { COLORS } from "constants/colors"
import { DEFAULT_CURRENCY } from "constants/currency"
import { ERRORS } from "constants/errors"
import { VALID_FOR, PACKAGE_STATUS, YES_OR_NO_SETTINGS, VOUCHER_SALE_ON } from "constants/information"
import { REGEX_PATTERNS } from "constants/pattern"
import { Service, PackageService } from "core/generated"
import { useToast, useSalonCache, useUserCache } from "hooks"
import { useState, useMemo, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { Button, Checkbox, FormatNumber, FormHelperText, FormLabel, Heading, Input, Modal, MultiSelect, MultiSelectOption, Paragraph, SelectInput, SvgArrowBack, SvgChevronLeft, SvgGreyMinus, SvgGreyPlus, SvgTrash } from "ui"
import { AddPackageModalProps, ICreatePackageInput } from "../types"
import { useBusinessServices } from "api/useLoyaltyAndPromos"

const AddPackageModal = ({
  isVisible,
  closeModal,
  package_,
  setPackage,
  actions
}: AddPackageModalProps<'editPackage' | 'addPackage'>) => {
  const { getUserData } = useUserCache()
  const { addToast, toast } = useToast()
  const { getSalonData } = useSalonCache()
  const user = getUserData()
  const salon = getSalonData()
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm<ICreatePackageInput>()
  const { loading: saving, createPackage } = useCreatePackage()
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>([])
  const [totalPrice, setTotalPrice] = useState<null | number>(null)
  const {
    data: servicesData,
    refetch: refetchBusinessServices
  } = useBusinessServices({
    businessId: user?.business?.id
  });
  const services = useMemo(() => servicesData?.businessServices, [servicesData])
  const servicesOptions = useMemo(() => Array?.isArray(services) ? formatServicesToSelectField(services) : [], [services])

  useEffect(() => {
    refetchBusinessServices()
  }, [salon?.id])

  const { getSalonFieldValue } = useSalonCache()

  const addSalonPackage = async (input: ICreatePackageInput) => {
    const payload = {
      ...input,
      isPurchasableAsVoucher: true,
      isDiscountApplicable: input.isDiscountApplicable ? input.isDiscountApplicable : false,
      id: package_ ? package_.id : null,
      validityMonths: Number(input.validityMonths),
      status: input.status ? input.status : 'active',
      serviceIdsAndQuantity: convertSelectedServicesIntoServiceIdsAndQuantity(),
      usesCustomPrice: input.customPrice ? true : false,
      customPrice: input.customPrice
        ? Number(input.customPrice.toString().replace(/,/g, ''))
        : null,
      salonId: package_ ? package_?.id : getSalonFieldValue('id'),
      activateTax: false
    }

    delete payload?.services
    delete payload.price
    createPackage({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { createPackage } = data;
      if (createPackage?.status === 200) {
        addToast({
          message: 'Voucher created successfully',
          variant: 'success'
        })
        resetPackageFormInput()
        closeModal()
      }
    })
  }

  const convertSelectedServicesIntoServiceIdsAndQuantity = () => {
    return Array?.isArray(selectedServices) && selectedServices?.length && selectedServices?.map((service) => ({
      serviceId: service?.id,
      quantity: service.quantity || 1
    }))
  }

  const resetPackageFormInput = () => {
    setValue('name', '');
    setValue('description', '');
    setValue('validityMonths', 0);
    setValue('status', null);
    setValue('isDiscountApplicable', false);
    setValue('isPurchasableAsVoucher', false);
    setValue('usesCustomPrice', false);
    setValue('customPrice', 0);
    setValue('services', []);
    setValue('allowSaleOn', null)
    setSelectedServices([]);
    setPackage && setPackage(null)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const convertSalonServicesNamesToString = (services?: Service[]) => {
    const title = services?.map(({ name }) => name).join(', ') || ''
    return title
  }

  const transformServicesArrayInToMultiSelectOptions = (
    inputArray: PackageService[]
  ): [] => {
    if (!inputArray) return []
    return inputArray?.map((service) => ({
      label: service.name,
      value: service.serviceId
    })) as []
  }

  // useEffect(() => {
  //   // get total price from selected services
  //   const totalPrice = selectedServices.reduce((prev, curr) => {
  //     return prev + curr.price
  //   }, 0)
  //   setValue('price', totalPrice)
  //   setTotalPrice(totalPrice)
  // }, [selectedServices])

  useEffect(() => {
    if (watch('services')) {
      const serviceSelectedFromOptions = services?.filter((service) => {
        const serviceId = service?.id;
        const existsInServicesField = watch('services')?.some((serviceSelected: MultiSelectOption) => serviceSelected?.value === serviceId);
        const existsInSelectedServices = selectedServices?.some((selectedService) => selectedService?.id === serviceId);

        if (!existsInServicesField && existsInSelectedServices) {
          // Return null to indicate that this service should be removed
          setSelectedServices((prevSelectedServices) => prevSelectedServices?.filter((s) => s.id !== serviceId));
          return false;
        }
        return existsInServicesField && !existsInSelectedServices;
      }).filter((service) => service !== null); // Remove the null values
      if (serviceSelectedFromOptions && !package_) {
        setSelectedServices((prevSelectedServices) => [
          ...prevSelectedServices,
          ...serviceSelectedFromOptions
        ]);
      }

      if (package_) {
        setSelectedServices(serviceSelectedFromOptions);
      }
    }
  }, [watch('services')]);

  useEffect(() => {
    if (package_) {
      setValue('name', package_.name)
      setValue('description', package_.description)
      setValue('validityMonths', package_.validityMonths)
      setValue(
        'customPrice',
        package_.usesCustomPrice ? package_.customPrice : 0
      )
      // @ts-expect-error enum status value
      setValue('status', package_.status)
      // @ts-expect-error enum status value
      setValue('allowSaleOn', package_.allowSaleOn)
      setValue('usesCustomPrice', package_.usesCustomPrice)
      setValue('isDiscountApplicable', package_.isDiscountApplicable)
      setValue('isPurchasableAsVoucher', package_.isPurchasableAsVoucher)
      setValue(
        'services',
        transformServicesArrayInToMultiSelectOptions(package_?.packageServices)
      )
    } else {
      resetPackageFormInput()
    }
  }, [package_])

  useEffect(() => {
    // get total price from selected services
    const totalPrice = [...selectedServices].reduce((prev, curr) => {
      const quantity = curr.quantity || 1;
      return prev + curr.price * quantity;
    }, 0);
    setValue('price', totalPrice);
    setTotalPrice(totalPrice);
  }, [selectedServices])

  const _closeModal = () => {
    closeModal()
    resetPackageFormInput()
  }

  const modifyCart = (id: string, type: "add" | "remove", inventory: "service") => {
    // modify cart for services
    if (inventory === "service") {
      let remove = false;
      const serviceSelectedFromOptions = selectedServices?.map(service => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1;
          if (type === "add") {
            return {
              ...service,
              quantity: quantity + 1
            };
          }
          if (type === "remove") {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1
              };
            } else {
              remove = true
            }
          }
        }
        return service;
      });
      if (remove) {
        // get serive index from watch("services") via id
        const serviceIndex = watch('services')?.findIndex((serviceSelected: MultiSelectOption) => serviceSelected?.value === id);
        removeService(serviceIndex);
        return;
      }
      setSelectedServices(serviceSelectedFromOptions);
    }
  }

  const removeService = (index?: number) => {
    const selectedServicesWatched = watch('services');
    if (!Array.isArray(selectedServicesWatched) || typeof index !== 'number') return;
    if (selectedServicesWatched?.length && selectedServicesWatched?.[index]) {
      selectedServicesWatched.splice(index, 1);
    }
    setValue('services', selectedServicesWatched);

    if (selectedServices?.length && selectedServices?.[index]) {
      selectedServices.splice(index, 1);
    }
    setSelectedServices(selectedServices);
  }

  return (
    <>
      <Modal show={isVisible} closeModal={_closeModal} variant='right'>
        <form
          onSubmit={handleSubmit(addSalonPackage)}
          className='w-full relative my-[80px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={_closeModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={() => closeModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Voucher
              </Paragraph>
            </div>
          </div>

          <div className='w-full flex flex-col px-6 py-4 space-y-6'>
            <Heading variant='h1' size='h9' weight='semiBold'>
              {!package_ ? 'Create voucher' : 'Edit voucher'}
            </Heading>
            <Paragraph size='b4'>
              {!package_
                ? 'Create a new voucher for a clients'
                : 'Edit an existing voucher'}
            </Paragraph>
          </div>

          <div className='w-full p-6 space-y-6'>
            <Input
              name='name'
              label='Voucher name'
              id='package-name'
              type='text'
              placeholder='Enter voucher name here'
              control={control}
              rules={{
                required: ERRORS.PACKAGE_NAME_REQUIRED,
                pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
              }}
              error={errors.name}
              disabled={package_ ? true : false}
            />
            <Input
              name='description'
              label='Description of voucher'
              id='description'
              type='text'
              placeholder='A brief description of this voucher'
              control={control}
              error={errors.description}
            />
            <div className='w-full flex gap-x-4'>
              <SelectInput
                name='validityMonths'
                id='validityMonths'
                label='Valid for'
                control={control}
                rules={{
                  required: ERRORS.VALID_FOR_REQUIRED
                }}
                error={errors.validityMonths}
                options={VALID_FOR}
                value={watch('validityMonths')}
                placeholder='Select validity period'
              />
              {package_ ? (
                <SelectInput
                  name='status'
                  id='status'
                  label='Status'
                  control={control}
                  error={errors.status}
                  options={PACKAGE_STATUS}
                  value={watch('status')}
                  placeholder='Select Status'
                />
              ) : null}
            </div>
            <div className='w-full flex gap-x-4'>
              <div className='w-1/2 flex flex-col space-y-4'>
                <Controller
                  control={control}
                  name='services'
                  render={({
                    field: { onChange, value },
                    formState: { errors }
                  }) => {
                    const errorMessage: string = errors?.services?.message
                    return (
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='service'>Add Service(s)</FormLabel>
                        <MultiSelect
                          selected={value || []}
                          options={servicesOptions}
                          setSelected={onChange}
                          disabled={package_ ? true : false}
                        />
                        {errorMessage && (
                          <FormHelperText variant='error'>
                            {errorMessage}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                  rules={{
                    required: 'Add a service'
                  }}
                />
                <Controller
                  control={control}
                  name='usesCustomPrice'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className='w-full flex flex-col space-y-4'>
                        <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                          <div
                            className='flex'
                            onClick={() => onChange(!value)}
                          >
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <Checkbox isChecked={value} />
                              <span className='text-grey-900 whitespace-nowrap'>
                                Use custom price
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
              <div className='w-1/2'>
                <Controller
                  control={control}
                  name='price'
                  // rules={{ required: "Please enter a price" }}
                  render={({ field, formState: { errors } }) => (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='price'>Price</FormLabel>
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value as number)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='Price'
                        disabled={true}
                      />
                      {errors?.price?.message && (
                        <FormHelperText variant='error'>
                          {errors.price.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className='w-1/2'>
              {watch('usesCustomPrice') && (
                <Controller
                  control={control}
                  name='customPrice'
                  rules={{ required: 'Please enter a price' }}
                  render={({ field, formState: { errors } }) => (
                    <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='customPrice'>Custom Price</FormLabel>
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value as number)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='Enter price'
                      // disabled={package_ ? true : false}
                      />
                      {errors?.customPrice?.message && (
                        <FormHelperText variant='error'>
                          {errors.customPrice.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                />
              )}
            </div>
            {selectedServices && Array.isArray(selectedServices) && selectedServices?.length > 0 ? (
              <div className="w-full flex flex-col py-5 px-4 gap-4 border border-grey-20 rounded-md">
                <div className="w-full flex flex-col space-y-4">
                  <div className="w-full flex space-x-2">
                    <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[50%]'>SELECTED SERVICES</Heading>
                    <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[20%]'>SELECT QUANTITY</Heading>
                    <Heading variant='h3' size='b6' color={COLORS.GREY[300]} weight="bold" className='w-[20%] flex justify-end'>PRICE</Heading>
                    <div className='w-[10%] flex justify-end'></div>
                  </div>
                  <>
                    {selectedServices?.map((service, index) => {
                      let quantity = service?.quantity || 1;
                      if (package_) {
                        quantity = package_?.packageServices?.find(s => s.serviceId === service?.id)?.quantity || 1;
                      }
                      return (
                        <div className='w-full flex items-start space-x-2' key={service?.id}>
                          <div className='flex flex-col w-[50%]'>
                            <Paragraph size='b4' color={COLORS.GREY[300]} className="">{service?.name}</Paragraph>
                          </div>
                          <div className="w-[20%] flex space-x-2">
                            <div className="w-full flex pt-2">
                              <Button
                                variant='icon'
                                size="none"
                                type="button"
                                className="border-0"
                                onClick={() => modifyCart(service?.id, 'add', 'service')}
                                disabled={package_ ? true : false}
                              >
                                <SvgGreyPlus width="22px" height="22px" />
                              </Button>
                              <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                                <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{quantity}</Paragraph>
                              </div>
                              <Button
                                variant='icon'
                                size="none"
                                type="button"
                                className="border-0"
                                onClick={() => modifyCart(service?.id, 'remove', 'service')}
                                disabled={package_ ? true : false}
                              >
                                <SvgGreyMinus width="22px" height="22px" />
                              </Button>
                            </div>
                          </div>
                          <div className='flex justify-end w-[20%]'>
                            <Paragraph size='b4' color={COLORS.GREY[300]} className="">
                              {DEFAULT_CURRENCY}<FormatNumber value={service?.price * quantity} />
                              {service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full ml-2'>from</span>) : null}
                            </Paragraph>
                          </div>
                          {!package_ && (
                            <div className='w-[10%] flex justify-end items-center'>
                              <span className="text-red-600 text-b5 cursor-pointer" onClick={() => {
                                removeService(index)
                              }}><SvgTrash width="14px" height="14px" /></span>
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </>
                </div>
                <div className='w-full flex justify-between'>
                  <Paragraph size='b4' color={COLORS.GREY[400]}>Total amount</Paragraph>
                  <div className='flex flex-col xl:flex-row gap-2'>
                    <Paragraph size='b4' className={watch('usesCustomPrice') ? 'line-through' : ''}>{formatInToPrice(totalPrice || 0)}</Paragraph>
                    {watch('usesCustomPrice') ? (
                      <Paragraph size='b4'>{formatInToPrice(watch('customPrice') || 0)}</Paragraph>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <Controller
              control={control}
              name='isDiscountApplicable'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <FormLabel htmlFor='isPromoApplicable'>
                      Promo/discounts applicable
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                        ? YES_OR_NO_SETTINGS.map(
                          (isPromoApplicable: {
                            label: string
                            value: boolean
                          }) => {
                            return (
                              <div
                                className='flex'
                                onClick={() =>
                                  onChange(isPromoApplicable?.value)
                                }
                                key={isPromoApplicable?.label}
                              >
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                  <Checkbox
                                    isChecked={
                                      value === isPromoApplicable?.value
                                    }
                                  />
                                  <span className='text-grey-900 whitespace-nowrap'>
                                    {isPromoApplicable?.label}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )
                        : null}
                    </div>
                  </div>
                )
              }}
            />
            <Controller
              control={control}
              name='allowSaleOn'
              render={({ field: { onChange, value } }) => {
                const errorMessage: string =
                  errors?.allowSaleOn?.message
                return (
                  <div className='w-full flex flex-col space-y-4'>
                    <FormLabel htmlFor='isPurchasableAsVoucher'>
                      Allow voucher as sale on
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      {Array.isArray(VOUCHER_SALE_ON) &&
                        VOUCHER_SALE_ON.length
                        ? VOUCHER_SALE_ON.map(
                          (allowSaleOn: {
                            label: string
                            value: string
                          }) => {
                            return (
                              <div
                                className='flex'
                                onClick={() =>
                                  onChange(allowSaleOn?.value)
                                }
                                key={allowSaleOn?.label}
                              >
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                  <Checkbox
                                    isChecked={
                                      value === allowSaleOn?.value
                                    }
                                  />
                                  <span className='text-grey-900 whitespace-nowrap'>
                                    {allowSaleOn?.label}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )
                        : null}
                    </div>
                    {errorMessage && (
                      <FormHelperText variant='error'>
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </div>
                )
              }}
              rules={{ required: "Choose an option" }}
            />
          </div>

          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            <div className='w-full flex justify-between py-4 px-8'>
              <div className='bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  TOTAL: {DEFAULT_CURRENCY}
                  <FormatNumber
                    value={
                      !watch('usesCustomPrice')
                        ? totalPrice
                        : watch('customPrice')
                    }
                  />
                </Paragraph>
              </div>
              <div className='flex space-x-2'>
                <Button
                  variant='text'
                  className='w-fit text-red-500'
                  size='sm'
                  fontWeight='semiBold'
                  type='button'
                  onClick={_closeModal}
                >
                  Cancel
                </Button>
                {actions?.editPackage || actions?.addPackage ?
                  <Button
                    variant='primary'
                    className=''
                    size='md'
                    rounded='lg'
                    disabled={false}
                    loading={saving}
                  >
                    {package_ ? 'Save' : 'Create voucher'}
                  </Button> : null}
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AddPackageModal