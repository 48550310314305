import { useGetStaffAppointments } from 'api/useStaff';
import { cleanUpText, formatDateToOriginalDate, formatInToPrice, getDefaultQueryDates, getNumberMonthAndYearFromDate } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { Appointment, AppointmentService, SalonStaff, Service, StaffAppointmentSummary } from 'core/generated';
import React, { useEffect, useMemo, useState } from 'react'
import { Button, CalendarPicker, Heading, HeadlessDropDown, Modal, Paragraph, Pill, Rating, SvgArrowBack, SvgChevronLeft } from 'ui';
import FullTable from 'ui/organism/table/Table';

type StaffAppointmentSummaryProps = {
  isVisible: boolean;
  closeModal: () => void;
  staffAppointment?: StaffAppointmentSummary
  staffMembers: SalonStaff[]
}
const HISTORY = [
  {
    label: 'Service',
    value: 'service',
    show: true
  },
  {
    label: 'Date',
    value: 'date',
    show: true
  },
  {
    label: 'Status',
    value: 'status',
    show: true
  },
  {
    label: 'Price',
    value: 'price',
    show: true
  },
  {
    label: 'Ratings',
    value: 'ratings',
    show: true
  }
]

const HISTORY_MOBILE = [
  {
    title: 'Service',
    key: 'service',
  },
  {
    title: 'Date',
    key: 'date',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Price',
    key: 'price',
  }
]
const StaffAppointmentSummaryModal = ({ isVisible, closeModal, staffAppointment, staffMembers }: StaffAppointmentSummaryProps) => {
  const [staffDropDownSelectedValue, setStaffDropDownSelectedValue] = useState('')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const startDate = formatDateToOriginalDate(selectedDates[0], "start")
  const endDate = formatDateToOriginalDate(selectedDates[1], "end")
  const {
    data,
    refetch
  } = useGetStaffAppointments({
    salonStaffId: staffDropDownSelectedValue,
    startDate,
    endDate
  })
  const appointments = useMemo(() => {
    if (data?.appointmentsByStaffReport) {
      return data?.appointmentsByStaffReport
    }
    return []
  }, [data])
  const getVoucherPillsForServices = (services: string[]) => {
    if (Array.isArray(services) && services.length > 0) {
      return (<div className='flex flex-wrap gap-3'>
        {services.map(voucherService => {
          return <Pill variant='light' rounded='lg' className='py-2 px-2' key={voucherService}>{voucherService}</Pill>
        })}
      </div>)
    }

    return "-"
  }

  const staffList = useMemo(() => {
    if (!staffMembers?.length) return [];
    const staff = staffAppointment?.staffMember?.split(', ');
    // clean up staff array, so it is dintinct
    const staffSet = new Set(staff);
    const staffArray = Array.from(staffSet);
    const list = staffArray?.map((staff) => {
      const getStaffDetails = staffMembers.find((staffMember) => `${staffMember?.user?.firstName} ${staffMember?.user?.lastName}` === staff)
      return {
        label: staff,
        value: getStaffDetails?.id
      }
    })
    if (list?.length) {
      setStaffDropDownSelectedValue(list[0]?.value)
    }
    return list || []
  }, [staffAppointment, staffMembers?.length])

  const completedAppointmentsValue = () => {
    if (appointments?.length) {
      return formatInToPrice(appointments?.filter((appointment) => appointment?.status === 'completed')?.reduce((acc, appointment) => acc + appointment?.price, 0))
    }
    return formatInToPrice(0)
  }

  // get distinct services check via name
  const getDistinctServices = (appointments) => {
    const services = new Set();
    
    appointments.forEach(appointment => {
        if (appointment.service) {
            const serviceList = appointment.service.split(',').map(service => service.trim());
            serviceList.forEach(service => services.add(service));
        }
    });
  
    return Array.from(services);
  }
  const services = getDistinctServices(appointments || []) as string[]

  const nameMap = {
    "name": "Name",
    "appointmentCompleted": "Total appointments completed",
    "amount": "Total appointments completed value",
    "servicesOffered": "Total services offered",
    "services": "Services offered"
  };
  const dataMap = {
    "name": staffList?.find((staff) => staff?.value === staffDropDownSelectedValue)?.label || "-",
    "appointmentCompleted": appointments?.filter((appointment) => appointment?.status === 'completed')?.length || 0,
    "amount": completedAppointmentsValue(),
    "servicesOffered": services?.length,
    "services": getVoucherPillsForServices(services),
  };

  const generateAppointmentList = (list: any) => {
    if (Array.isArray(list) && list?.length) {
      return list?.map((appointment) => {
        return {
          service: appointment?.service,
          date: getNumberMonthAndYearFromDate(appointment?.scheduledDate),
          status: cleanUpText(appointment?.status),
          price: formatInToPrice(appointment?.price),
          ratings: () => {
            const rate = appointment?.reviews || 0
            return <div className='flex space-x-2 items-center'>
              <span className='text-b5 text-grey-900'>{rate}</span>
              <Rating initialRating={parseInt(rate)} />
            </div>
          },
        }
      })
    }
    return []
  }

  const rows = generateAppointmentList(appointments || [])

  useEffect(() => {
    if (staffDropDownSelectedValue) {
      refetch()
    }
  }, [staffDropDownSelectedValue])
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Voucher Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 py-4'>
          <div className='w-full flex flex-col space-y-6 px-6'>
            <div className='w-full flex items-center justify-between'>
              <div className="flex flex-col space-y-6">
                <Heading variant='h1' size='h9' weight='semiBold'>Staff appointment details</Heading>
                <Paragraph size="b4">Find information about staff appointment </Paragraph>
              </div>

              <div className='w-[170px]'>
                <HeadlessDropDown
                  options={staffList}
                  selectedValue={staffDropDownSelectedValue}
                  setDropDownSelectedValue={setStaffDropDownSelectedValue}
                />
              </div>
            </div>
            <div className='flex flex-col gap-6 p-3 rounded-md border border-grey-125/45'>
              {Object.entries(dataMap).map(([key, value]) => (
                nameMap[key] && value !== null && (
                  <div key={key} className="px-2 flex justify-between items-start space-x-4 bg-grey-10">
                    <div className="w-[40%] flex flex-col space-y-1 p-2">
                      <Paragraph size="b4" color="grey-300" className="text-left">
                        {nameMap[key]}
                      </Paragraph>
                    </div>
                    <div className="w-[60%]  flex flex-col space-y-1">
                      <Paragraph size="b4" color={COLORS.GREY[900]} weight="semiBold" className="text-right">
                        {value}
                      </Paragraph>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>

          <div className="pt-10">
            <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
              <Paragraph size='b4' weight='semiBold' className=''>
                Overview
              </Paragraph>
              <CalendarPicker {...{ selectedDates, setSelectedDates }} />
            </div>
            <FullTable
              headers={HISTORY}
              mobileHeaders={HISTORY_MOBILE}
              rows={rows}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StaffAppointmentSummaryModal