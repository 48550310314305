import { useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios';
import { Appointment, CreateReviewDocument } from 'core/generated';
import { ReviewAppointmentInput } from 'components/uicomponents/types';
import { SvgReviewDash } from 'ui';
import AddRating from 'ui/atoms/rating/AddRating';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import Heading from 'ui/atoms/heading/Heading';
import { convertAppointmentServicesNamesToString, convertAppointmentServicesToStaffString } from 'components/uicomponents/bookingSales/utils';
import { getNumberMonthAndYearFromDate } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { ToastProps } from 'ui/atoms/toast/types';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { API_ERRORS } from 'constants/errors';
import { useToast } from 'hooks/useToast';
import TextInput from 'ui/molecules/input/TextInput';
import Button from 'ui/atoms/button/Button';
import SuccessFeedBackModal from 'components/uicomponents/SuccessFeedBackModal';
import { Logo } from 'ui/atoms/logo';
import { APPOINTMENT_STATUS } from 'components/uicomponents/bookingSales/constants';
import { useParams } from 'react-router-dom';
import { useAppointmentSummary, useCreateReview } from 'api/useAppointments';

const Review = () => {
  const { toast, addToast } = useToast();
  const [rating, setRating] = useState<number>(0);
  const params = useParams();
  const bookingId = params.bookingId;
  const [appointmentHasBeenReviewed, setAppointmentHasBeenReviewed] = useState(false);

  const {
    data,
    loading,
    refetch
  } = useAppointmentSummary(bookingId)
  const appointment = useMemo(() => {
    if (Array.isArray(data?.appointmentSummary?.appointment?.reviews) && data?.appointmentSummary?.appointment?.reviews?.length) {
      setAppointmentHasBeenReviewed(true);
      const stars = data?.appointmentSummary?.appointment?.reviews[0]?.stars
      setRating(+stars || 0)
    }
    return data?.appointmentSummary?.appointment
  }, [data])

  const {
    createReview,
    loading: saveReviewIsLoading,
  } = useCreateReview()

  const {
    control,
    handleSubmit,
  } = useForm<ReviewAppointmentInput>({});

  const onSubmit = async (input: ReviewAppointmentInput) => {
    try {
      createReview({
        variables: {
          input: {
            message: input?.comment || null,
            stars: rating,
            appointmentId: appointment?.id,
            salonId: appointment?.salon?.id,
            clientId: appointment?.clientId,
          }
        }
      }).then(({ data }) => {
        if (data?.createReview?.status === 201 || data?.createReview?.status === 200) {
          setAppointmentHasBeenReviewed(true);
          addToast && addToast({
            message: 'Review created successfully',
            variant: 'success',
          })
        }

        if (data?.createReview.errors?.length) {
          addToast && addToast({
            message: data?.createReview.errors[0].message || API_ERRORS.APPOINTMENT_REVIEW_CREATE_FAILED,
            variant: 'error',
          })
        }
      })
    } catch (createReviewError) {
      if (axios.isAxiosError(createReviewError)) {
        const message = createReviewError?.response?.data?.message || API_ERRORS.APPOINTMENT_REVIEW_CREATE_FAILED;
        addToast && addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };
  return (
    <>
      <main className='w-full flex bg-grey-100 h-screen p-0 xl:py-[49px] xl:px-0 justify-center'>
        <ToastWrapper toast={toast as ToastProps} />
        {appointment && appointment?.appointmentStatus === APPOINTMENT_STATUS.completed ? (
          <div className='w-full max-w-[490px] p-[48px] bg-white h-full flex flex-col items-center space-y-5 rounded-md overflow-y-auto'>
            {appointment?.salon?.business?.logoUrl ? (
              <img
                src={appointment?.salon?.business?.logoUrl}
                alt="business logo"
                className="w-full max-w-[140px]"
                loading="lazy"
              />
            ) : (
              <Logo />
            )}
            <div className='space-y-2 flex flex-col items-center'>
              <Heading variant='h1' size='h8' weight='bold'>{appointment?.salon?.business?.name}</Heading>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{appointment?.address}</Paragraph>
            </div>
            <div className='w-full border-t border-grey-100'></div>
            <Heading variant='h2' size='h8' weight='bold'>Leave a review</Heading>
            <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Thank you for your recent visit. </Paragraph>
            <div className='w-full flex flex-col space-y-4'>
              <div className='w-full flex space-x-8 justify-between items-start'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Service</Paragraph>
                <Paragraph size='b5' weight='normal' className='my-0 text-right'>{appointment?.appointmentServices ? convertAppointmentServicesNamesToString(appointment?.appointmentServices) : ''}</Paragraph>
              </div>
              <div className='w-full flex space-x-8 justify-between items-start'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Staff</Paragraph>
                <Paragraph size='b5' weight='normal' className='my-0'>{appointment?.appointmentServices ? convertAppointmentServicesToStaffString(appointment?.appointmentServices) : ''}</Paragraph>
              </div>
              <div className='w-full flex space-x-8 justify-between items-start'>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Date</Paragraph>
                <Paragraph size='b5' weight='normal' className='my-0'>{getNumberMonthAndYearFromDate(appointment?.startAt)}</Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col space-y-4 items-center justify-center'>
              {rating > 0 ? (
                <Heading variant='h3' size='h3' weight='bold' className='text-center'>{rating}</Heading>
              ) : (
                <SvgReviewDash width="30px" height="30px" />
              )}
              <AddRating initialRating={rating} handleRatingChange={handleRatingChange} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-4" autoComplete='off'>
              <TextInput name="comment" label="Comment" id="comment" type="text" placeholder='Drop a comment' control={control} />
              <Button
                variant='primary'
                className=''
                disabled={rating === 0 || saveReviewIsLoading}
                loading={saveReviewIsLoading}
                size='lg'
                rounded='lg'
              >
                Submit
              </Button>
            </form>
            <div className='flex justify-center items-end space-x-2'>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Powered by</Paragraph> <Logo className='max-w-[60px]' />
            </div>
          </div>
        ) : null}
      </main>
      {appointmentHasBeenReviewed && (<SuccessFeedBackModal />)}
    </>
  )
}

export default Review;