/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { MOBILE_TRANSACTION_SUMMARY_HEADINGS, MOBILE_PAYMENT_COLLECTED_HEADINGS, PAYMENT_COLLECTED_HEADINGS, TRANSACTION_SUMMARY_HEADINGS } from './constants';
import TableSkeleton from 'ui/organism/tableSkeleton/TableSkeleton';
import SummaryTableCard from 'ui/organism/summaryTableCard/SummaryTableCard';
import Table from 'ui/organism/table/Table';
import { formatDateToOriginalDate, formatTableHeadersFilterArray, getTodayQueryDates } from '../../utils/misc';
import CalendarPicker from 'ui/molecules/calendarPicker/CalendarPicker';
import { SalesPageProps } from './types';
import { generateOutstandingPaymentsSummaryTableData, generatePaymentCollectedTableData, generateTransactionSummaryTableData } from './utils';
import { useOutstandingPayments, usePaymentCollected, useTransactionSummary } from 'api/useSales';
import { canShowPage } from 'components/utils/permission';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';
import DailySalesPaymentCollected from './components/DailySalesPaymentCollected';

const DailySales = (props: SalesPageProps) => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [transactionSummaryHeadings, setTransactionSummaryHeadings] = useState(TRANSACTION_SUMMARY_HEADINGS);
  const [paymentCollectedHeadings, setPaymentCollectedHeadings] = useState(PAYMENT_COLLECTED_HEADINGS);

  const [start, end] = selectedDates || getTodayQueryDates();

  const {
    loading: transactionSummaryIsLoading,
    data: transactionSummaryData,
    refetch: fetchTransactionSummary,
  } = useTransactionSummary(formatDateToOriginalDate(start, "start"), formatDateToOriginalDate(end, "end"), props.salonId)
  const transactionSummary = useMemo(() => transactionSummaryData?.transactionSummary, [transactionSummaryData]);

  const {
    loading: paymentSummaryIsLoading,
    data: paymentCollectedData,
    refetch: fetchPaymentCollected,
  } = usePaymentCollected(formatDateToOriginalDate(start, "start"), formatDateToOriginalDate(end, "end"), props.salonId)
  const paymentCollected = useMemo(() => paymentCollectedData?.paymentCollected, [paymentCollectedData]);

  const {
    data: outstandingPaymentsSummaryData,
    refetch: fetchOutstandingPaymentsSummary,
  } = useOutstandingPayments(formatDateToOriginalDate(start, "start"), formatDateToOriginalDate(end, "end"), props.salonId)
  const outstandingPaymentsSummary = useMemo(() => outstandingPaymentsSummaryData?.outstandingPayments, [outstandingPaymentsSummaryData]);


  const showInitialLoadingShimmer = paymentSummaryIsLoading && transactionSummaryIsLoading && !transactionSummary && !paymentCollected;
  useEffect(() => {
    if (props?.salonId) {
      fetchTransactionSummary()
      fetchPaymentCollected()
      fetchOutstandingPaymentsSummary()
    }
  }, [selectedDates])

  useEffect(() => {
    if (props.salonBranchName) {
      fetchTransactionSummary()
      fetchPaymentCollected()
      fetchOutstandingPaymentsSummary()
    }
  }, [props.salonBranchName])

  useEffect(() => {
    if (props.refreshData) {
      fetchTransactionSummary()
      fetchPaymentCollected()
      fetchOutstandingPaymentsSummary()
      props?.setRefreshData(false)
    }
  }, [props.refreshData])

  const getShimmer = () => {
    if (showInitialLoadingShimmer) {
      return (
        <div className='w-full flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }
  }
  const getTransactionSummaryContent = () => {
    if (transactionSummary) {
      const tableTransactionData = generateTransactionSummaryTableData(transactionSummary, transactionSummaryHeadings)
      return (
        <SummaryTableCard title="Transaction summary">
          <Table
            headers={formatTableHeadersFilterArray(transactionSummaryHeadings)}
            mobileHeaders={MOBILE_TRANSACTION_SUMMARY_HEADINGS}
            rows={tableTransactionData}
          />
        </SummaryTableCard>
      )
    }
  }
  const getOutstandingPaymentSummaryContent = () => {
    if (outstandingPaymentsSummary) {
      const tablePaymentData = generateOutstandingPaymentsSummaryTableData(outstandingPaymentsSummary, transactionSummaryHeadings)
      return (
        <SummaryTableCard title='Outstanding payments'>
          <Table
            headers={formatTableHeadersFilterArray(transactionSummaryHeadings)}
            mobileHeaders={MOBILE_PAYMENT_COLLECTED_HEADINGS}
            rows={tablePaymentData}
          />
        </SummaryTableCard>
      )
    }
  }
  const getPaymentCollectedContent = () => {
    if (paymentCollected) {
      const tablePaymentData = generatePaymentCollectedTableData(paymentCollected || [], paymentCollectedHeadings)
      return (
        <SummaryTableCard title='Payment collected'>
          {/* <Table
            headers={formatTableHeadersFilterArray(paymentCollectedHeadings)}
            mobileHeaders={MOBILE_PAYMENT_COLLECTED_HEADINGS}
            rows={tablePaymentData}
          /> */}
          <DailySalesPaymentCollected headers={paymentCollectedHeadings} data={tablePaymentData} />
        </SummaryTableCard>
      )
    }
  }
  return (
    <>
      {showInitialLoadingShimmer ? <ContentLoader /> : null}
      {canShowPage('Reports::') ?
        <div className='flex justify-end items-center py-4 px-8 space-x-4'>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
        </div> : null}
      <div className='w-full flex flex-col xl:flex-row py-4 px-8 gap-4'>
        {getTransactionSummaryContent()}
        {getOutstandingPaymentSummaryContent()}
        {getPaymentCollectedContent()}
      </div>
    </>
  )
}

export default DailySales