import * as React from 'react';
import { useUserCache } from 'hooks/useUserCache';
import { useSalonCache } from 'hooks/useSalonCache';
import { useAccountSetupNav } from 'hooks/useNav';
import {
  Business,
} from 'core/generated';
import { ONBOARDING_STATUS } from '../components/uicomponents/accountSetupComponents/constants';

const AccountSetup = () => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();

  const { getBusinessData } = useUserCache();
  // const user = getUserData();
  const business: Business = getBusinessData();

  const {
    currentSubPageComp,
    getNextSubPageOrPage,
    moveToNextPage,
    getPrevSubPageOrPage,
    moveToSpecificSubPage,
    moveToSpecificPage,
  } = useAccountSetupNav();

  const onNextPage = () => {
    getNextSubPageOrPage();
  };

  const onPrevPage = () => {
    getPrevSubPageOrPage();
  };

  React.useEffect(() => {
    if (!salon) return;

    if (business) {
      if (business?.isAppointmentLinkSetup) {
        moveToSpecificPage(1);
        moveToSpecificSubPage(3);
        return;
      }
      const onBoardingStatus =
        business && Array?.isArray(business?.salons) && business?.salons?.length
          ? business?.salons[0]?.onboardingStatus
          : null;
      if (onBoardingStatus) {
        if (onBoardingStatus === ONBOARDING_STATUS?.HOURS) {
          moveToSpecificSubPage(2);
          return;
        }

        if (onBoardingStatus === ONBOARDING_STATUS?.SERVICES) {
          moveToSpecificSubPage(4);
          return;
        }

        if (onBoardingStatus === ONBOARDING_STATUS?.STAFF) {
          moveToSpecificSubPage(3);
          return;
        }

        if (onBoardingStatus === ONBOARDING_STATUS?.CLIENTS) {
          moveToSpecificPage(1);
          moveToSpecificSubPage(0);
          return;
        }

        if (!business?.about) {
          moveToSpecificPage(1);
          moveToSpecificSubPage(0 as number);
          return;
        }

        if (!business?.payoutAccount) {
          moveToSpecificPage(1);
          moveToSpecificSubPage(3);

          return;
        }

        moveToSpecificPage(1);
        moveToSpecificSubPage(4 as number);
      }
    }
  }, []);

  // return sub page
  const Node = currentSubPageComp?.component;
  return (
    <Node
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      moveToNextPage={moveToNextPage}
      business={business}
    />
  );
};

export default AccountSetup;
