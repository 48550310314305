import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { AddVoucherSaleProps, BookingPackages, MembershipSaleInput } from './types';
import { formatBankAccountsToSelectField, formatClientToSelectFieldAppointmentCreation, formatPosTerminalsToSelectField, formatSalonPackagesToSelectField } from '../utils/utils';
import { formatBookingPackages, sanitizePhoneNumber, sortOptions, validatePhoneNumber } from '../utils/misc';
import { convertPackagesToCommaSeparatedString } from './utils';
import { Client, User } from 'core/generated';
import { useSalonCache } from 'hooks/useSalonCache';
import { SALES_PAYMENT_METHODS } from './constants';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import Button from 'ui/atoms/button/Button';
import { FormTextarea, MultiSelectOption, SvgArrowBack, SvgChevronLeft } from 'ui';
import { DEFAULT_CURRENCY } from 'constants/currency';
import { FormatNumber } from 'ui/atoms/formatNumber/FormatNumber';
import { Modal } from 'ui/templates/modal/Modal';
import Heading from 'ui/atoms/heading/Heading';
import CollapseRow from 'ui/organism/collapseRow/CollapseRow';
import { FormLabel } from 'ui/atoms/formLabel';
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect';
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText';
import Input from 'ui/molecules/input/Input';
import { API_ERRORS, ERRORS } from 'constants/errors';
import { REGEX_PATTERNS } from 'constants/pattern';
import FormPhone from 'ui/molecules/input/FormPhone';
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from '../utils/form';
import MultiSelect from 'ui/molecules/multiselect/multiselect';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import SelectInput from 'ui/molecules/input/SelectInput';
import { logger } from 'core/logger';
import { useAddClient, useGetClientsForSalesAndAppointments } from 'api/useClient';
import { useGetPosDevices, useGetTransferAccounts } from 'api/useMoney';
import { useSalonPackages } from 'api/useCatalogue';
import { useCreateVoucherSale } from 'api/useSales';
import { useAssignMembership, useGetMemberships } from 'api/useLoyaltyAndPromos';
import { useUserCache } from 'hooks';
import { MAXIMUM_NOTE_LENGTH } from 'constants/form';
// import { logger } from 'core/logger';

const AddMembershipSale = (props: AddVoucherSaleProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const [newClient, setNewClient] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [callingCode, setCallingCode] = useState("");
  const {
    loading: assigningMembership,
    assignMembership
  } = useAssignMembership()
  const { getUserData } = useUserCache()
  const user: User = getUserData();
  let searchTimeout: ReturnType<typeof setTimeout>;
  const {
    data: clientsData,
    refetch: refetchClients
  } = useGetClientsForSalesAndAppointments({
    salonId,
    first: 10
  })
  const clients = useMemo(() => clientsData?.salonClients?.nodes, [clientsData])
  const clientsOptions = useMemo(() => formatClientToSelectFieldAppointmentCreation(clients), [clients])
  const {
    data,
    loading,
    refetch: refetchMemberships
  } = useGetMemberships(user?.business?.bookingUrlIdentifier)
  const memberships = useMemo(() => {
    if (data) {
      return data?.business?.memberships
    }
  }, [data])
  const {
    data: bankTransfersData,
    refetch: refetchBankTransfers
  } = useGetTransferAccounts(salonId)
  const bankTransfers = useMemo(() => bankTransfersData?.transferAccounts, [bankTransfersData])
  const transfersOptions = useMemo(() => formatBankAccountsToSelectField(bankTransfers), [bankTransfers])

  const {
    data: posTerminalsData,
    refetch: refetchPosTerminals
  } = useGetPosDevices(salonId)
  const posTerminals = useMemo(() => posTerminalsData?.posDevices, [posTerminalsData])
  const posTerminalsOptions = useMemo(() => formatPosTerminalsToSelectField(posTerminals), [posTerminals])

  const membershipOptions = useMemo(() => {
    if (Array?.isArray(memberships) && memberships?.length) {
      return memberships?.map((membership) => ({
        label: membership?.name,
        value: membership?.id
      }))
    }
  }, [memberships])

  useEffect(() => {
    if (salonId) {
      refetchBankTransfers()
      refetchPosTerminals()
      refetchMemberships()
    }
  }, [refetchBankTransfers, refetchPosTerminals, refetchMemberships, salonId])

  const getClients = async (q: string) => {
    if (q) {
      refetchClients({
        q,
        salonId,
        first: 10
      })
    }
  }
  const {
    loading: creatingClient,
    createClient
  } = useAddClient()

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<MembershipSaleInput>({
    defaultValues: {
      paymentMethod: SALES_PAYMENT_METHODS[0].value,
      newClient: false,
    }
  });

  useEffect(() => {
    if (watch('clientId')) {
      const clientIdField = watch('clientId') as unknown as {
        label: string;
        value: string;
      };
      if (clientIdField?.value === 'new-client') {
        setNewClient(true);
        setValue('clientName', '');
        setValue('clientEmail', '');
        setValue('clientPhone', '');
        setValue('newClient', true);
      } else {
        setNewClient(false);
        setValue('newClient', false);
        const client = clients.find((client) => client?.id === clientIdField?.value) as Client;
        if (client) {
          setValue('clientName', `${client?.firstName} ${client?.lastName}`);
          setValue('clientEmail', client?.email || '');
          setValue('clientPhone', client?.phone);
          setCallingCode(client?.callingCode || '');
          setCountryCode(client?.countryCode || '');
        }
      }
    }
  }, [watch('clientId')])

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode);
    setValue('clientPhone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('clientPhone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('clientPhone');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getClientDetails = (input: MembershipSaleInput) => {
    const clientId = input?.clientId as unknown as {
      label: string;
      value: string;
    };

    if (clientId?.value === 'new-client') {
      return {
        firstName: input?.clientName?.split(' ')[0],
        lastName: input?.clientName?.split(' ')[1],
        email: input?.clientEmail,
        phone: input?.clientPhone,
        callingCode,
        countryCode,
      }
    }
    // check client details from the list of clients
    const client = clients.find((client) => client?.id === clientId?.value) as Client;
    return {
      firstName: client?.firstName,
      lastName: client?.lastName,
      email: client?.email,
      phone: client?.phone,
      callingCode: client?.callingCode,
      countryCode: client?.countryCode,
    }
  }

  const closeAddVoucherModal = () => {
    props.closeModal('addedVoucherSale');
    resetFormInput()
  }

  const getTotalPrice = () => {
    let totalPrice = 0;
    // get membership
    const membershipId = watch('membershipId');
    const membership = memberships?.find((membership) => membership?.id === membershipId);

    if (membership) {
      totalPrice = membership?.fee || 0;
    }

    return totalPrice
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchClient = (e: any) => {
    // Clear any previously set timeout to avoid multiple calls
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to delay the execution of getClients
    searchTimeout = setTimeout(() => {
      getClients(e);
    }, 500);
  };

  const resetFormInput = () => {
    setValue('clientId', []);
    setValue('clientPhone', '');
    setValue('clientEmail', '');
    setValue('clientName', '');
    setValue('note', '');
    setValue('membershipId', null);
    setValue('paymentMethod', SALES_PAYMENT_METHODS[0].value)
    setNewClient(false);
  }

  const sellVoucher = async (input: MembershipSaleInput) => {
    const client = input?.clientId as unknown as MultiSelectOption;
    let clientId = client?.value;
    if (clientId === 'new-client') {
      try {
        const fullName = input?.clientName?.split(' ')
        const clientData = {
          firstName: fullName[0],
          lastName: fullName[1] || " ",
          email: input?.clientEmail || null,
          phone: input.clientPhone,
          salonId: props?.salonId,
          countryCode,
          callingCode,
        };
        const { data } = await createClient({
          variables: {
            input: {
              ...clientData
            }
          }
        });

        if (data?.createClient?.status === 201 || data?.createClient?.client) {
          clientId = data?.createClient?.client?.id;
        } else {
          throw new Error('Failed to create client');
        }
      } catch (error) {
        props?.addToast({
          message: error?.message,
          variant: 'error'
        });
        return; // Exit the function if category creation fails
      }
    }
    const inputData = {
      clientId,
      membershipId: input?.membershipId,
      paymentMethod: input?.paymentMethod,
      // paymentMethodId: input?.paymentMethodId,
      note: input?.note,
    }

    try {
      // logger.info(`Creating voucher sale: ${JSON.stringify(inputData)}`);
      assignMembership({
        variables: {
          input: { ...inputData, salonId: props?.salonId }
        }
      }).then(({ data }) => {
        if (data?.assignMembership?.clientMembership) {
          props?.addToast && props.addToast({
            variant: "success",
            message: "Membership sale created successfully",
          });
          props.closeModal('addedVoucherSale');
          resetFormInput();
        }

        if (data?.assignMembership?.errors?.length) {
          const message = data?.assignMembership?.errors[0]?.message || API_ERRORS.VOUCHER_SALE_FAILED;
          props?.addToast && props.addToast({
            variant: 'error',
            message,
          })
        }
      });
    } catch (appointmentError) {
      const message = appointmentError?.message || 'Failed to record a membership sale';
      props?.addToast && props.addToast({
        variant: "error",
        message,
      });
      logger.error(`Failed to sell membership`, inputData)
      logger.exception(appointmentError as Error);
    }
  }

  return (
    <Modal
      show={props.isVisible}
      closeModal={closeAddVoucherModal}
      variant='right'
    >
      <form onSubmit={handleSubmit(sellVoucher)} className="w-full relative space-y-6 my-[80px]" autoComplete='off'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeAddVoucherModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeAddVoucherModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>Sell membership</Paragraph>
          </div>
        </div>
        <div className="w-full flex flex-col px-6 py-4 space-y-6">
          <Heading variant='h1' size='h9' weight='semiBold'>Sell membership</Heading>
          <Paragraph size="b4">Create a new membership sale for a client</Paragraph>
        </div>
        <div className="w-full px-6 pt-2 pb-4 space-y-6">
          <CollapseRow title="Client">
            <div className='w-full flex flex-col space-y-2'>
              <Controller
                control={control}
                name="clientId"
                render={({
                  field: { onChange, value },
                  formState: { errors },
                }) => {
                  const errorMessage = errors?.clientId?.message;
                  return (
                    <>
                      <FormLabel htmlFor="service">
                        Select a client
                      </FormLabel>
                      <SingleSelect
                        selected={value || []}
                        options={clientsOptions}
                        setSelected={onChange}
                        onInputChange={searchClient}
                        placeholder='Type client name'
                      />
                      {errorMessage && (
                        <FormHelperText variant="error">
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </>
                  );
                }}
                rules={{
                  required: "Select a client",
                }}
              />
            </div>
            {newClient && (
              <>
                <Input name="clientName" label="Client" id="clientName" type="text" placeholder='Enter client name' control={control} rules={{
                  required: newClient && ERRORS.NAME_REQUIRED,
                  pattern: REGEX_PATTERNS.ADDRESS,
                }} error={errors.clientName} />
                <div className='w-full flex gap-x-4'>
                  <div className='w-full xl:w-1/2'>
                    <FormPhone
                      country={{
                        disabled: false,
                        onSelect: (code, country: string) => {
                          setCallingCode(code);
                          setCountryCode(country);
                        },
                        value: countryCode,
                      }}
                      phone={{
                        name: "clientPhone",
                        type: "text",
                        placeholder: "9151930463",
                        rules: {
                          required: ERRORS.PHONE_REQUIRED,
                          pattern: REGEX_PATTERNS.NUMBER,
                          onChange: (e) => {
                            _sanitizePhoneNumber(e.target.value);
                          },
                          disabled: false,
                        },
                        register,
                        id: "phone-number",
                        label: "Phone Number",
                      }}
                      helperText={getHelperTextForReactHookFormErrors(
                        errors?.clientPhone?.message as string
                      )}
                    />
                  </div>
                  <div className='w-full xl:w-1/2'>
                    <Input name="clientEmail" label="Client email" id="clientEmail" type="email" placeholder='email@acme.com' control={control} rules={{
                      pattern: REGEX_PATTERNS.EMAIL,
                    }} error={errors.clientEmail} />
                  </div>
                </div>
              </>
            )}
          </CollapseRow>
          <CollapseRow title="Memberships">
            <div className='w-full flex space-x-4'>
              <SelectInput
                name='membershipId'
                id='membershipId'
                label='Add a membership'
                control={control}
                rules={{
                  required: 'Select a membership'
                }}
                error={errors.membershipId}
                options={sortOptions(membershipOptions)}
                placeholder='Select membership'
              />
            </div>
          </CollapseRow>
          <Controller
            control={control}
            name="note"
            render={({
              field: { onChange, onBlur, name, ref, value },
              formState: { errors },
            }) => {
              const maxLength = MAXIMUM_NOTE_LENGTH;
              const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                errors?.note?.message as string
              );
              const helperTexts = [];

              helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
              if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

              return (
                <FormTextarea
                  type="text"
                  id="about"
                  label="Note"
                  placeholder="Add a note"
                  {...{
                    ref,
                    name,
                    value,
                    onChange,
                    onBlur,
                    maxLength,
                  }}
                  helperText={helperTexts}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="paymentMethod"
            render={({ field: { onChange, value } }) => {
              return (
                <div className="w-full flex flex-col space-y-4">
                  <label className="text-b6 xl:text-b4 text-grey-900 font-normal w-full block subpixel-antialiased">
                    Select payment method
                  </label>
                  <div className="w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4">
                    {Array.isArray(SALES_PAYMENT_METHODS) && SALES_PAYMENT_METHODS.length
                      ? SALES_PAYMENT_METHODS
                        .map((paymentMethod: {
                          label: string;
                          value: string;
                        }) => {
                          return (
                            <div
                              className="flex"
                              onClick={() => onChange(paymentMethod?.value)}
                              key={paymentMethod?.value}
                            >
                              <div className="flex items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                                <Checkbox
                                  isChecked={value === paymentMethod?.value}
                                />
                                <span className="text-grey-900 whitespace-nowrap">
                                  {paymentMethod?.label}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            }}
            rules={{
              required: "Select payment method",
            }}
          />
          {watch("paymentMethod") === "pos" && posTerminals && posTerminals.length ? (
            <SelectInput name="paymentMethodId" id="transfers" label="Point-of-Sale Terminal (POS)" control={control} error={errors.paymentMethodId} options={posTerminalsOptions} placeholder="Select Point-of-Sale Terminal" />
          ) : null}
          {watch("paymentMethod") === "bank_transfer" && bankTransfers && bankTransfers.length ? (
            <SelectInput name="paymentMethodId" id="pos" label="Bank" control={control} error={errors.paymentMethodId} options={transfersOptions} placeholder="Select Bank" />
          ) : null}
        </div>
        <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
          <div className='w-full flex justify-between py-4 px-8'>
            <div className='bg-grey-50 py-2 px-4 rounded-md border border-grey-20 hidden xl:flex justify-center items-center'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>TOTAL: {DEFAULT_CURRENCY}<FormatNumber value={getTotalPrice()} /></Paragraph>
            </div>
            <div className='flex space-x-2'>
              <Button
                variant="text"
                size="sm"
                type="button"
                rounded="lg"
                fontWeight="semiBold"
                disabled={assigningMembership}
                onClick={() => {
                  props?.closeModal('close');
                }}
                className="w-fit text-red-500"
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                className=''
                disabled={assigningMembership}
                loading={assigningMembership}
                size='md'
                rounded='lg'
              >
                Add membership sale
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddMembershipSale